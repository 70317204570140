export enum ActionTypes {
    SET = '@media/set',
}

export enum MediaTypes {
    PHONE = 'phone',
    TABLET = 'tablet',
    DESKTOP_SMALL = 'desktopSmall',
    DESKTOP_MEDIUM = 'desktopMedium',
}

export interface MediaState {
    isMobile: boolean;
    isPhone: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isDesktopSmall: boolean;
    isDesktopMedium: boolean;
}

export interface MatchMediaAction {
    type: typeof ActionTypes.SET;
    media: MediaTypes;
}

import { defineMessages } from 'react-intl';
import { i18n } from 'src/utils/i18n';
import cloudLogo from 'img/icons/cloud.svg';
import oneDriveLogo from 'img/icons/onedrive.svg';
import boxLogo from 'img/icons/box.svg';
import davLogo from 'img/icons/dav.png';
import s3Logo from 'img/icons/s3.svg';
import awsLogo from 'img/icons/aws.svg';
import hotboxLogo from 'img/icons/hotbox.svg';
import vkworkdiskLogo from 'img/icons/vkworkdisk.svg';
import dropboxLogo from 'img/icons/dropbox.svg';
import driveLogo from 'img/icons/drive.svg';
import privateLogo from 'img/icons/privatecloud.png';
import huaweiLogo from 'img/icons/huawei.png';
import { AvailableLanguages } from 'src/modules/language/language.types';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';

const servicesMessages = defineMessages({
    cloud: 'Облако Mail.ru',
    yaDisk: 'Яндекс.Диск',
    dropbox: 'Dropbox',
    drive: 'Google Drive',
    webdav: 'WebDAV',
    box: 'Box',
    oneDrive: 'OneDrive',
    aws: 'Amazon S3',
    s3: 'S3',
    hotbox: 'MCS S3',
    vkworkdisk: 'VK WorkDisk',
    privateCloud: 'pCloud',
    huawei: 'Huawei',
});

export interface Service {
    name: string;
    logo: string;
    id: string;
    url?: string;
}

const services: Record<string, Service> = {
    cloud: {
        name: i18n.formatMessage(servicesMessages.cloud),
        logo: cloudLogo,
        id: 'cloud',
        url: 'https://cloud.mail.ru/',
    },
    oneDrive: {
        name: i18n.formatMessage(servicesMessages.oneDrive),
        logo: oneDriveLogo,
        id: 'oneDrive',
        url: '/service/one-drive/',
    },
    dropbox: {
        name: i18n.formatMessage(servicesMessages.dropbox),
        logo: dropboxLogo,
        id: 'dropbox',
        url: '/service/dropbox/',
    },
    drive: {
        name: i18n.formatMessage(servicesMessages.drive),
        logo: driveLogo,
        id: 'drive',
        url: '/service/google-drive/',
    },
    box: {
        name: i18n.formatMessage(servicesMessages.box),
        logo: boxLogo,
        id: 'box',
    },
    vkworkdisk: {
        name: i18n.formatMessage(servicesMessages.vkworkdisk),
        logo: vkworkdiskLogo,
        id: 'vkworkdisk',
        url: 'https://biz.mail.ru/teambox/',
    },
    s3: {
        name: i18n.formatMessage(servicesMessages.s3),
        logo: s3Logo,
        id: 's3',
    },
    hotbox: {
        name: i18n.formatMessage(servicesMessages.hotbox),
        logo: hotboxLogo,
        id: 'hotbox',
        url: 'https://mcs.mail.ru/storage/',
    },
    aws: {
        name: i18n.formatMessage(servicesMessages.aws),
        logo: awsLogo,
        id: 'aws',
    },
    private: {
        name: i18n.formatMessage(servicesMessages.privateCloud),
        logo: privateLogo,
        id: 'private',
    },
    webdav: {
        name: i18n.formatMessage(servicesMessages.webdav),
        logo: davLogo,
        id: 'webdav',
    },
    huawei: {
        name: i18n.formatMessage(servicesMessages.huawei),
        logo: huaweiLogo,
        id: 'huawei',
    },
};

export interface ServiceListItem extends Omit<Service, 'url'> {
    href?: string;
}

const getServicesList = (): Record<string, ServiceListItem> => {
    return Object.keys(services).reduce((result: Record<string, ServiceListItem>, serviceName) => {
        const service = services[serviceName];

        const href = service.url?.startsWith('https') ? service.url : `/${getCurrentLanguage()}${service.url}`;

        result[serviceName] = {
            name: service.name,
            logo: service.logo,
            id: service.id,
            href: service.url ? href : undefined,
        };

        return result;
    }, {});
};

export const list: Service[] = [services.oneDrive, services.dropbox, services.drive];

export const getServices = (): ServiceListItem[] => {
    const serviceItem = getServicesList();

    switch (getCurrentLanguage()) {
        case AvailableLanguages.ru:
            return [
                serviceItem.cloud,
                serviceItem.drive,
                serviceItem.dropbox,
                serviceItem.oneDrive,
                serviceItem.vkworkdisk,
                serviceItem.huawei,
                serviceItem.box,
                serviceItem.webdav,
                serviceItem.hotbox,
                serviceItem.aws,
                serviceItem.s3,
            ];
        case AvailableLanguages.zh:
            return [
                serviceItem.huawei,
                serviceItem.drive,
                serviceItem.dropbox,
                serviceItem.oneDrive,
                serviceItem.box,
                serviceItem.webdav,
                serviceItem.aws,
                serviceItem.hotbox,
                serviceItem.s3,
                serviceItem.cloud,
                serviceItem.vkworkdisk,
            ];
        default:
            return [
                serviceItem.drive,
                serviceItem.dropbox,
                serviceItem.oneDrive,
                serviceItem.box,
                serviceItem.huawei,
                serviceItem.webdav,
                serviceItem.aws,
                serviceItem.hotbox,
                serviceItem.s3,
                serviceItem.cloud,
                serviceItem.vkworkdisk,
            ];
    }
};

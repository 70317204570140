import {
    TariffLoadActionTypes,
    TariffLoadFailureAction,
    TariffLoadRequestAction,
    TariffLoadSuccessAction,
} from 'src/modules/tariffs/tariffs.types';
import { ThunkAction } from 'redux-thunk';
import { AppState, store } from 'src/store';
import { Action } from 'redux';
import { giveTariffs } from 'src/modules/api/giveTariffs';
import { isMobile } from 'src/modules/media/media.selectors';
import { getHash } from 'src/utils/location.helpers';
import { sendGa } from 'src/utils/ga';

const request = (): TariffLoadRequestAction => ({
    type: TariffLoadActionTypes.REQUEST,
});

const failure = (): TariffLoadFailureAction => ({
    type: TariffLoadActionTypes.FAILURE,
});

const success = (list: {}): TariffLoadSuccessAction => ({
    type: TariffLoadActionTypes.SUCCESS,
    list,
});

const loadTariffsAction: ThunkAction<void, AppState, null, Action<string>> = (
    dispatch
): Promise<TariffLoadSuccessAction | TariffLoadFailureAction> => {
    dispatch(request());

    return giveTariffs()
        .then((list) => dispatch(success(list)))
        .catch(() => dispatch(failure()));
};

export const loadTariffs = () => loadTariffsAction;

export const countInvisibleTariffs = (): void => {
    const isSmallSize = isMobile(store.getState());
    const isTariffsHash = getHash() === 'tariffs';

    if (isSmallSize && isTariffsHash) {
        sendGa({
            category: 'count_tariffs',
            action: 'invisible',
        });
    }
};

import Helmet from 'react-helmet';
import React, { ReactElement } from 'react';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';
import appleIcon from 'img/icons/apple.svg';
import windowsIcon from 'img/icons/windows.svg';
import arrowDownload from 'img/icons/arrowDownload.svg';
import { Button, ButtonColors, ButtonSize } from 'src/ui/Button/Button';
import { defineMessages, FormattedMessage } from 'react-intl';
import { i18n } from 'src/utils/i18n';
import { Logo } from 'src/ui/Header/Logo/Logo';
import { Link } from 'src/ui/Link/Link';

import styles from './DocsPage.css';
import { useSelector } from 'react-redux';
import { getDownloadUrlGetter } from 'src/modules/links/links.selectors';

const messages = defineMessages({
    title: 'Документация, содержащая описание функциональных характеристик программы',
    description:
        'Диск-О - программа, которая умеет объединять разные облака в одном пространстве и быстро переносить все файлы с любого диска в Облако Mail.ru.',
    documentation: 'Документация приложения',
    features: 'Функциональные характеристики',
    download: 'Скачать для {osName}',
    logo: 'Логотип',
});

export const DocsPage = (): ReactElement => {
    const getDownloadUrl = useSelector(getDownloadUrlGetter);

    return (
        <div className={styles.root}>
            <Helmet>
                <title>{i18n.formatMessage(messages.title)}</title>
                <meta name="description" content={i18n.formatMessage(messages.description)} />
            </Helmet>

            <div className={styles.header}>
                <Link to={'/'}>
                    <Logo alt={i18n.formatMessage(messages.logo)} />
                </Link>
            </div>

            <div className={styles.title}>
                <h1>
                    <Text
                        size={TextSize['40px']}
                        tabletSize={TextSize['40px']}
                        desktopSize={TextSize['48px']}
                        lineHeight={LineHeight['52px']}
                    >
                        <FormattedMessage {...messages.title} />
                    </Text>
                </h1>
            </div>

            <div className={styles.text}>
                <Text size={TextSize['20px']} weight={Weight.w400}>
                    <FormattedMessage {...messages.description} />
                </Text>
            </div>

            <div className={styles.docs}>
                <Text size={TextSize['20px']} weight={Weight.w500} lineHeight={LineHeight['35px']}>
                    <FormattedMessage {...messages.documentation} />
                </Text>
                <br />
                <a href="https://cloud.mail.ru/public/pbzG/FjDt7RD7G" target="_blank" className={styles.link}>
                    <img src={arrowDownload} alt="icon" />
                    <Text
                        size={TextSize['16px']}
                        tabletSize={TextSize['20px']}
                        desktopSize={TextSize['20px']}
                        weight={Weight.w500}
                        lineHeight={LineHeight['35px']}
                    >
                        <FormattedMessage {...messages.features} />
                    </Text>
                </a>
            </div>

            <div className={styles.download}>
                <Button color={ButtonColors.BLACK} href={getDownloadUrl({ isMac: true })} size={ButtonSize.M} target="_blank" noPrevent>
                    <img className={styles.downloadIcon} src={appleIcon} alt="macOs" />
                    <Text size={TextSize['16px']} weight={Weight.w500}>
                        <FormattedMessage {...messages.download} values={{ osName: 'macOS' }} />
                    </Text>
                </Button>
                <Button color={ButtonColors.BLACK} href={getDownloadUrl({ isMac: false })} size={ButtonSize.M} target="_blank" noPrevent>
                    <img className={styles.downloadIcon} src={windowsIcon} alt="macOs" />
                    <Text size={TextSize['16px']} weight={Weight.w500}>
                        <FormattedMessage {...messages.download} values={{ osName: 'Windows' }} />
                    </Text>
                </Button>
            </div>
        </div>
    );
};

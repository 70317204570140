import React, { PureComponent, ReactElement } from 'react';
import { Services } from 'src/ui/Services/Services';
import { Tariffs } from 'src/ui/Tariffs/Tariffs';
import { ServicesHero } from 'src/ui/ServiceHero/ServiceHero';
import Logo from 'img/icons/onedrive.svg';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ServiceFeatures } from 'src/ui/ServiceFeatures/ServiceFeatures';
import { ServiceAbout } from 'src/ui/ServiceAbout/ServiceAbout';
import { ServiceWorkWithFiles } from 'src/ui/ServiceWorkWithFiles/ServiceWorkWithFiles';
import createPhotoImg from 'img/service/createPhoto.png';
import docsImg from 'img/service/docs.png';
import oneDriveImg from 'img/service/oneDrive.png';
import oneDriveImgEn from 'img/service/oneDriveEn.png';
import { ServiceDownload } from 'src/ui/ServiceDownload/ServiceDownload';
import { Nowrap } from 'src/ui/Nowrap/Nowrap';
import { HowItWorks } from 'src/ui/HotItWorks/HowItWorks';
import { isMobile } from 'src/modules/media/media.selectors';
import { AppState } from 'src/store';
import { connect } from 'react-redux';
import { GetLinkViaEmail } from 'src/components/GetLinkViaEmail/GetLinkViaEmail';

import styles from './ServicePage.css';
import { getCurrentLanguage, isRussianLanguage } from 'src/modules/language/language.selectors';
import { AvailableLanguages } from 'src/modules/language/language.types';
import { sendGa } from 'src/utils/ga';
import { NBSP } from 'src/constants/typography';
import Helmet from 'react-helmet';
import { i18n } from 'src/utils/i18n';

const messages = defineMessages({
    diskOServices: 'С помощью Диск-О: вы можете использовать несколько аккаунтов как на OneDrive, так и в других облачных хранилищах.',
    diskOPaidFeature:
        'Диск-О: можно подключить к Облаку Mail.ru бесплатно (только 1 диск), возможность подключения Microsoft OneDrive и других сторонних облачных хранилищ является частью подписки',
    metaTitle: 'Войти в One Диск и начать обмениваться файлами - Диск-О',
    metaDescription: 'Входите и синхронизируйте ваши фотографии, видео и любые другие файлы с OneDrive с помощью <nowrap>Диск-О</nowrap>.',
    heroTitle: 'Подключите <span>OneDrive</span> <nowrap>к Диск-О:</nowrap> и{nbsp}начните обмениваться файлами',
    heroText: '<span>Войдите</span> в свой аккаунт <span>OneDrive</span>, чтобы начать работать с файлами из хранилища.',
    syncText: 'Ваши файлы из OneDrive на компьютере будут моментально синхронизироваться с файлами в браузере',
    fileActionsText: 'С Диск-О: все действия с Microsoft OneDrive будут происходить в 2 раза быстрее, чем в браузере',
    offlineText: 'Вы сможете работать с файлами без подключения к интернету, а при появлении Сети они будут обновляться автоматически',
    installText: 'Вам не понадобится отдельно скачивать приложение OneDrive – достаточно установить Диск-О: для MacOS или Windows',
    aboutText:
        '<span>OneDrive</span> – облачное хранилище, созданное компанией Microsoft. Облако поддерживает Office Online, что позволяет пользователям загружать, создавать и редактировать документы Microsoft Office, а также обмениваться ими в браузере с помощью настройки общего доступа. Также в облако можно загружать фото и видео. Сервис позволяет хранить до 5 ГБ информации бесплатно.',
    createPhoto: 'Сохраняйте фото и видео и делитесь ими',
    createPhotoText:
        'После подключения вашего аккаунта к Диск-О: коллекция фото и видео из облака OneDrive будет храниться на устройстве, не занимая его память. Кроме того, вы сможете:',
    createPhotoList1: 'Просматривать медиафайлы из облака на компьютере;',
    createPhotoList2: 'Редактировать свои снимки в Photoshop и других фоторедакторах;',
    createPhotoList3: 'Сохранять скриншоты на свое облако OneDrive;',
    createPhotoList4: 'Отправлять оригиналы изображений и видео другим пользователям в мессенджерах, соцсетях или по электронной почте;',
    createPhotoList5: 'Делать из ваших фотографий PDF;',
    createPhotoList6: 'Архивировать свои фото и видео;',
    createPhotoList7: 'Создавать новые папки и объединять в них медиафайлы.',
    docs: 'Работайте с файлами в{nbsp}Word, Excel, PowerPoint',
    docsText: 'Создавать и редактировать файлы в Office Online не всегда удобно Диск-О: упростит вашу работу с документами, и вы сможете:',
    docsList1: 'Редактировать тексты в Word, создавать таблицы в Excel или презентации в PowerPoint, не скачивая файлы из облака;',
    docsList2:
        'Отправлять ссылки на документы вашим коллегам для совместной работы – файлы будут доступны только тем, кому вы отправите ссылку;',
    docsList3:
        'Создавать документы на компьютере, а затем просматривать их в OneDrive через браузер – синхронизация будет происходить моментально;',
    docsList4: 'Хранить все файлы из облака на вашем устройстве – они не будут занимать его память.',
    downloadTitle: 'Приложение <nowrap>Диск-О:</nowrap>{br}доступно для MacOS <nowrap>и Windows</nowrap>',
    downloadText:
        'Оно позволяет бесплатно подключать все доступные облачные сервисы. При этом из Облака Mail.ru загружать файлы можно безлимитно, а из других облачных хранилищ (в том числе из <span>OneDrive</span>) не более 10 раз в сутки.',
    downloadText2: 'Вы также можете попробовать бесплатную версию Диск-О: на 7 дней без ограничений по количеству скачиваний.',
    howItWorksTitle: 'Как добавить <span>OneDrive</span> на <nowrap>Диск-О:</nowrap>',
    howItWorks1: 'В приложении <nowrap>Диск-О:</nowrap> нажмите кнопку «Добавить диск»',
    howItWorks2: 'Выберите <nowrap><span>облако OneDrive</span></nowrap> в списке сервисов',
    howItWorks3: 'Введите свой логин и пароль для <nowrap><span>OneDrive</span></nowrap>, чтобы <span>войти</span> в аккаунт',
    howItWorks4:
        'После завершения синхронизации на компьютере появится новый диск – в нем будут файлы из вашего облака. Все готово к работе! ',
});

interface MapState {
    isMobile: boolean;
}

const mapStateToProps = (state: AppState): MapState => ({
    isMobile: isMobile(state),
});

export class OneDriveComponent extends PureComponent<MapState> {
    componentDidMount(): void {
        sendGa({
            category: 'oneDrive-page',
            action: 'show',
        });
    }

    render(): ReactElement {
        const { isMobile } = this.props;
        return (
            <div>
                <Helmet>
                    <title>{i18n.formatMessage(messages.metaTitle)}</title>
                    <meta name="description" content={i18n.formatMessage(messages.metaDescription)} />
                </Helmet>
                <div className={styles.hero}>
                    <ServicesHero
                        title={
                            <FormattedMessage
                                {...messages.heroTitle}
                                values={{
                                    span: (msg: string): ReactElement => <span className={styles.highlight}>{msg}</span>,
                                    nowrap: Nowrap,
                                    nbsp: NBSP,
                                }}
                            />
                        }
                        text={
                            <FormattedMessage
                                {...messages.heroText}
                                values={{
                                    span: (msg: string): ReactElement => <span>{msg}</span>,
                                }}
                            />
                        }
                        logo={<img src={Logo} className={styles.heroLogo} alt="" />}
                    />
                </div>
                <div className={styles.features}>
                    <ServiceFeatures
                        installText={<FormattedMessage {...messages.installText} />}
                        syncText={<FormattedMessage {...messages.syncText} />}
                        fileActionsText={<FormattedMessage {...messages.fileActionsText} />}
                        offlineText={<FormattedMessage {...messages.offlineText} />}
                    />
                </div>
                <ServiceAbout
                    description={
                        <FormattedMessage
                            {...messages.aboutText}
                            values={{
                                span: (msg: string): ReactElement => <span>{msg}</span>,
                                br: <br />,
                            }}
                        />
                    }
                    logo={<img src={Logo} width={78} height={46} alt="" />}
                />
                <div className={styles.work}>
                    <ServiceWorkWithFiles
                        list={[
                            {
                                title: <FormattedMessage {...messages.createPhoto} />,
                                description: <FormattedMessage {...messages.createPhotoText} />,
                                id: 'createPhoto',
                                image: createPhotoImg,
                                list: [
                                    <FormattedMessage key="createPhotoList1" {...messages.createPhotoList1} />,
                                    <FormattedMessage key="createPhotoList2" {...messages.createPhotoList2} />,
                                    <FormattedMessage key="createPhotoList3" {...messages.createPhotoList3} />,
                                    <FormattedMessage key="createPhotoList4" {...messages.createPhotoList4} />,
                                    <FormattedMessage key="createPhotoList5" {...messages.createPhotoList5} />,
                                    <FormattedMessage key="createPhotoList6" {...messages.createPhotoList6} />,
                                    <FormattedMessage key="createPhotoList7" {...messages.createPhotoList7} />,
                                ],
                            },
                            {
                                title: <FormattedMessage {...messages.docs} values={{ nbsp: NBSP }} />,
                                description: <FormattedMessage {...messages.docsText} />,
                                id: 'docs',
                                image: docsImg,
                                list: [
                                    <FormattedMessage key="docsList1" {...messages.docsList1} />,
                                    <FormattedMessage key="docsList2" {...messages.docsList2} />,
                                    <FormattedMessage key="docsList3" {...messages.docsList3} />,
                                    <FormattedMessage key="docsList4" {...messages.docsList4} />,
                                ],
                            },
                        ]}
                    />
                </div>
                <div className={styles.how}>
                    <HowItWorks
                        poster={isRussianLanguage() ? oneDriveImg : oneDriveImgEn}
                        videoUrl={`https://www.youtube.com/embed/${
                            getCurrentLanguage() === AvailableLanguages.ru ? 'rL5AHnOGuRA' : 'OcGI5IbZGNA'
                        }?controls=0`}
                        title={
                            <FormattedMessage
                                {...messages.howItWorksTitle}
                                values={{
                                    nowrap: Nowrap,
                                    span: (msg: string): ReactElement => <span className={styles.highlight}>{msg}</span>,
                                }}
                            />
                        }
                        steps={[
                            {
                                step: 1,
                                text: (
                                    <FormattedMessage
                                        {...messages.howItWorks1}
                                        values={{
                                            nowrap: Nowrap,
                                            span: (msg: string): ReactElement => <span className={styles.highlight}>{msg}</span>,
                                        }}
                                    />
                                ),
                            },
                            {
                                step: 2,
                                text: (
                                    <FormattedMessage
                                        {...messages.howItWorks2}
                                        values={{
                                            nowrap: Nowrap,
                                            span: (msg: string): ReactElement => <span className={styles.highlight}>{msg}</span>,
                                        }}
                                    />
                                ),
                            },
                            {
                                step: 3,
                                text: (
                                    <FormattedMessage
                                        {...messages.howItWorks3}
                                        values={{
                                            nowrap: Nowrap,
                                            span: (msg: string): ReactElement => <span className={styles.highlight}>{msg}</span>,
                                        }}
                                    />
                                ),
                            },
                            {
                                step: 4,
                                text: <FormattedMessage {...messages.howItWorks4} />,
                            },
                        ]}
                        wideVideoPreview
                        newPosterMod
                    />
                </div>
                <div className={styles.services}>
                    <Services description={<FormattedMessage {...messages.diskOServices} />} />
                </div>
                {!isMobile && (
                    <div className={styles.download}>
                        <ServiceDownload
                            title={
                                <FormattedMessage
                                    {...messages.downloadTitle}
                                    values={{
                                        nowrap: Nowrap,
                                        br: <br />,
                                    }}
                                />
                            }
                            description={
                                <FormattedMessage
                                    {...messages.downloadText}
                                    values={{
                                        span: (msg: string) => <span>{msg}</span>,
                                    }}
                                />
                            }
                            descriptionMore={
                                <FormattedMessage
                                    {...messages.downloadText2}
                                    values={{
                                        span: (msg: string) => <span>{msg}</span>,
                                    }}
                                />
                            }
                        />
                    </div>
                )}
                <div className={styles.tariffs}>
                    <Tariffs />
                </div>
                {isMobile && (
                    <div className={styles.sms}>
                        <GetLinkViaEmail />
                    </div>
                )}
            </div>
        );
    }
}

export const OneDrive = connect(mapStateToProps)(OneDriveComponent);

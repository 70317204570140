import React, { PureComponent, ReactElement } from 'react';

import styles from './Accordion.css';
import { AccordionItem, AccordionItemProps } from 'src/ui/Accordion/AccordionItem';

interface AccordionState {
    openItemIndex?: number;
}

interface AccordionProps {
    items: AccordionItemProps[];
    darkBlock?: boolean;
}

export class Accordion extends PureComponent<AccordionProps, AccordionState> {
    state = {
        openItemIndex: 0,
    };

    private handleOnClick = (index: number): void => this.setState({ openItemIndex: index });

    render(): ReactElement | null {
        const { darkBlock, items } = this.props;

        if (!items) {
            return null;
        }
        return (
            <div className={styles.items}>
                {items.map(({ title, content }, index) => (
                    <AccordionItem
                        title={title}
                        content={content}
                        key={index}
                        index={index}
                        isCollapsed={index !== this.state.openItemIndex}
                        onToggle={this.handleOnClick}
                        darkBlock={darkBlock}
                    />
                ))}
            </div>
        );
    }
}

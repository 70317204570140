import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { MessageDescriptor, defineMessages, FormattedMessage } from 'react-intl';
import { slice } from 'lodash/fp';
import styles from './Faq.css';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { i18n } from 'src/utils/i18n';
import { Accordion } from 'src/ui/Accordion/Accordion';
import { Link } from 'src/ui/Link/Link';
import { NBSP } from 'src/constants/typography';
import { Container } from 'src/ui/Container/Container';
import { SubmitRequest } from 'src/ui/SubmitRequest/SubmitRequest';
import { isRussianLanguage } from 'src/modules/language/language.selectors';

const messages = defineMessages({
    title: 'Вопрос-ответ',
    description:
        'Мы стремимся сделать максимально простым и понятным для использования. Если у вас есть вопрос, вы можете найти ответ на него в данном разделе, либо задайте его нашей <a>технической поддержке</a>',
    more: 'Посмотреть другие вопросы',
    collapse: 'Свернуть',
    question1: 'С какими файлами работает Диск-О:?',
    answer1:
        'Диск-О: поддерживает работу со\u00A0всеми типами файлов, для которых установлено программное обеспечение на\u00A0вашем устройстве. Вы\u00A0можете создавать, открывать и\u00A0редактировать файлы на\u00A0компьютере, а\u00A0благодаря синхронизации все изменения будут сохранены в\u00A0облаках.\n\nИсключение составляют только файлы, содержащие в\u00A0названиях спецсимволы,\u00A0\u2014 их\u00A0открыть через Диск-О: будет невозможно. Имейте это в\u00A0виду, придумывая названия своим файлам.',
    question2: 'Как синхронизировать файлы?',
    answer2:
        'Диск-О: синхронизирует файлы автоматически. Вы\u00A0можете создавать и\u00A0редактировать их\u00A0на\u00A0устройстве даже без подключения к\u00A0интернету\u00A0\u2014 и\u00A0все изменения появятся в\u00A0ваших облаках, как только появится соединение.',
    question3: 'Как я получу доступ к файлам после подключения к облаку?',
    answer3:
        'В\u00A0разделе «Этот компьютер» на\u00A0вашем устройстве появится новый диск. Файлы и\u00A0папки на\u00A0нём будут соответствовать вашим файлам и\u00A0папкам в\u00A0облаке. Этот диск называется виртуальным облачным диском.',
    question4: 'В каких приложениях я смогу работать с файлами из облаков?',
    answer4:
        'В\u00A0любых, поскольку для приложений нет разницы между файлами с\u00A0виртуального облачного диска и\u00A0файлами с\u00A0ПК.',
    question5: 'Как быстро после подключения виртуального диска станут доступны мои файлы?',
    answer5: 'Файлы будут доступны сразу после подключения виртуального диска, поскольку они не\u00A0загружаются на\u00A0ПК.',
    question6: 'Как переносить файлы между облаками?',
    answer6:
        'Все хранилища, которые вы\u00A0подключите к\u00A0Диск-О:, будут отображаться как папки на\u00A0компьютере, и\u00A0вы\u00A0сможете переносить файлы между ними в\u00A0один клик. Если\u00A0же вам нужно перенести большой объем данных, воспользуйтесь новым функционалом Диск-О: \u2014\u00A0переносом файлов из\u00A0любого подключенного хранилища в\u00A0Облако Mail.ru. Все, что нужно\u00A0\u2014 зайти в\u00A0Диск-О: и\u00A0выбрать облако, из\u00A0которого вы\u00A0хотите перенести данные, а\u00A0затем авторизоваться под нужным аккаунтом Облака Mail.ru. Эта функция особенно удобна при переносе файлов из\u00A0Google Drive\u00A0\u2014 документы, таблицы и\u00A0презентации будут автоматически конвертированы из\u00A0нативного формата Google в\u00A0стандартные форматы Microsoft Office.',
    question7: 'Почему файлы из облаков не занимают место на моём устройстве?',
    answer7:
        'При работе приложений с\u00A0файлами на\u00A0виртуальном диске все обращения на\u00A0чтение и\u00A0запись перенаправляются к\u00A0облаку без промежуточного сохранения файлов на\u00A0ПК.',
    question8: 'Доступны ли файлы с виртуального диска без подключения к интернету?',
    answer8:
        'Вы\u00A0можете выбрать файлы (папки), которые будут доступны без подключения к\u00A0интернету. При этом они будут занимать место на\u00A0устройстве.',
    question9: 'Что будет при нестабильном подключении к интернету?',
    answer9:
        'Файлы будут записываться на\u00A0виртуальный диск так\u00A0же, как и\u00A0при стабильном подключении к\u00A0Сети. При восстановлении подключения они будут загружены в\u00A0облако в\u00A0фоновом режиме.',
    question10: 'Можно{nbsp}ли ускорить работу с{nbsp}часто используемыми файлами?',
    answer10:
        'Приложение Диск-О: автоматически сохраняет копии часто используемых файлов на\u00A0ПК, не\u00A0превышая установленного вами объёма дискового пространства. Для работы с\u00A0этими файлами не\u00A0требуется загружать их\u00A0из\u00A0интернета, что значительно ускоряет работу с\u00A0ними.',
    question11: 'Чем Диск-О: отличается от классического приложения для синхронизации?',
    answer11:
        'В\u00A0отличие от\u00A0большинства сервисов для синхронизации облаков в\u00A0приложении Диск-О: файлы становятся доступны для работы моментально, не\u00A0занимая при этом место на\u00A0устройстве.',
    question12: 'Могу ли я подключить два аккаунта Google Drive?',
    answer12:
        'Вы\u00A0можете подключить несколько аккаунтов разных облачных сервисов, а\u00A0также несколько аккаунтов одного облачного сервиса (в\u00A0том числе Google Drive). Все виртуальные диски будут доступны одновременно. Также можно перемещать файлы и\u00A0папки с\u00A0виртуального диска одного аккаунта на\u00A0другой.',
    question13: 'Можно ли использовать приложение Диск-О: для совместной работы с файлами?',
    answer13:
        'Да, Диск-О: поддерживает актуальные версии файлов на\u00A0всех устройствах, подключённых к\u00A0одному аккаунту облачного сервиса.',
}) as { [key: string]: MessageDescriptor };

const isRu = isRussianLanguage();

const numQuestion = isRu ? 13 : 12;

const questionsAll: { title: string; content: ReactNode }[] = Array.from({ length: numQuestion }, (_, index) => ({
    title: i18n.formatMessage(messages[`question${1 + index}`], { nbsp: NBSP }),
    content: i18n.formatMessage(messages[`answer${1 + index}`]),
}));

const NUM_OF_OPEN_FAQ = isRu ? 6 : 5;

const questionsPartial = slice(0, NUM_OF_OPEN_FAQ, questionsAll);

interface State {
    collapsedList: boolean;
    showRequestForm: boolean;
}

export class Faq extends PureComponent<Record<string, unknown>, State> {
    state = {
        collapsedList: true,
        showRequestForm: false,
    };

    private handleMoreClick = (): void =>
        this.setState((prevState) => ({
            collapsedList: !prevState.collapsedList,
        }));

    private handleSubmitRequestClick = (): void => this.setState({ showRequestForm: true });
    private handleSubmitRequestClose = (): void => this.setState({ showRequestForm: false });

    render(): ReactElement {
        return (
            <Container>
                <div id="faq" className={styles.root}>
                    <div className={styles.title}>
                        <Text size={TextSize['44px']} weight={Weight.w700}>
                            <FormattedMessage {...messages.title} />
                        </Text>
                    </div>
                    {this.state.showRequestForm && <SubmitRequest onClose={this.handleSubmitRequestClose} />}
                    <div className={styles.right}>
                        <div className={styles.description}>
                            <Text size={TextSize['20px']} weight={Weight.w500}>
                                <FormattedMessage
                                    {...messages.description}
                                    values={{
                                        a: (msg: string): ReactElement => <Link onClick={this.handleSubmitRequestClick}>{msg}</Link>,
                                    }}
                                />
                            </Text>
                        </div>
                        <Accordion items={this.state.collapsedList ? questionsPartial : questionsAll} />
                        <div className={styles.more}>
                            <Link onClick={this.handleMoreClick}>
                                <Text size={TextSize['20px']}>
                                    {this.state.collapsedList ? (
                                        <FormattedMessage {...messages.more} />
                                    ) : (
                                        <FormattedMessage {...messages.collapse} />
                                    )}
                                </Text>
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

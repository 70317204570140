import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { defineMessages } from 'react-intl';
import { i18n } from 'src/utils/i18n';

import { AppState } from 'src/store';
import { createBaseAction } from 'src/utils/action.helpers';
import { ActionTypes, ResetActionTypes, SendEmailActionTypes } from 'src/modules/sendEmail/sendEmail.types';
import { validateEmail } from 'src/utils/email.helpers';
import { getLinkViaEmailCallApi } from 'src/modules/api/getLinkViaEmail';

const messages = defineMessages({
    invalidEmail: 'Введите корректный email',
    generalApiError: 'Произошла ошибка. Попробуйте позже',
    badEmailApiError: 'Почтовый адрес неверен',
});

const invalidEmail = i18n.formatMessage(messages.invalidEmail);
const generalApiError = i18n.formatMessage(messages.generalApiError);
const badEmailApiError = i18n.formatMessage(messages.badEmailApiError);

export const request = (email?: string): SendEmailActionTypes => ({
    type: ActionTypes.REQUEST,
    email,
});
export const failure = (message: string): SendEmailActionTypes => ({
    type: ActionTypes.FAIL,
    message,
});
const success = createBaseAction(ActionTypes.SUCCESS);

export const requestEmailLink = (email?: string): ThunkAction<void, AppState, null, Action<string>> => (dispatch): Promise<any> => {
    dispatch(request(email));

    if (!validateEmail(email)) {
        dispatch(failure(invalidEmail));
        return Promise.reject();
    }

    return getLinkViaEmailCallApi(email as string)
        .then(() => dispatch(success()))
        .catch((error) => {
            const { data } = error;
            if (data && data.response && data.response.status === 400) {
                dispatch(failure(badEmailApiError));
                return Promise.reject();
            }
            dispatch(failure(generalApiError));
            return Promise.reject();
        });
};

export const resetEmailLinkState = createBaseAction(ResetActionTypes.RESET);

import React from 'react';
import { defineMessages } from 'react-intl';
import { TabTitle } from 'src/ui/HelpPage/Tabs/TabTitle';
import styles from './Tabs.css';
import { TabName } from 'src/ui/HelpPage/Tabs/TabName';
import { FormattedMessage } from 'src/utils/i18n';
import { TabText } from 'src/ui/HelpPage/Tabs/TabText';
import silverIcon from 'img/help/silver.svg';
import grayIcon from 'img/help/gray.svg';
import yellowIcon from 'img/help/yellow.svg';
import redIcon from 'img/help/red.svg';
import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'src/ui/Link/Link';

const messages = defineMessages({
    text1:
        'Между облаком и{nbsp}<nowrap>Диск-О:</nowrap>{nbsp}{mdash} двусторонняя синхронизация. Если вы{nbsp}удаляете файл из{nbsp}облака в{nbsp}<nowrap>веб-версии</nowrap>, файл удаляется в{nbsp}папке на{nbsp}компьютере{nbsp}{mdash} и{nbsp}наоборот. То{nbsp}же самое с{nbsp}загрузкой, новыми папками и{nbsp}остальными действиями.',
    name1: 'Временные файлы',
    text2:
        'Когда вы{nbsp}копируете файлы на{nbsp}диск, созданный приложением <nowrap>Диск-О:</nowrap>, сначала они загружаются во{nbsp}временную папку на{nbsp}системном диске. Пока идёт синхронизация с{nbsp}облачным сервисом, файлы находятся во{nbsp}временной папке, а{nbsp}после загрузки временные файлы удаляются.',
    text3: 'Как изменить место хранения данных, смотрите в{nbsp}разделе {laquo}<a>Расположение данных приложения</a>{raquo}.',
    name2: 'Скорость синхронизации',
    text4:
        'Скорость синхронизации с{nbsp}разными облачными сервисами{nbsp}{mdash} разная. Это ограничение самих сервисов. Поэтому время загрузки одного и{nbsp}того{nbsp}же файла в{nbsp}разные облачные хранилища различается.',
    name3: 'Обозначение иконок',
    text5:
        'Когда крутится иконка приложения в{nbsp}панели задач, это значит, что файлы синхронизируются. Когда иконка становится белой и{nbsp}перестает крутиться{nbsp}{mdash} файлы синхронизировались. Не{nbsp}выключайте интернет и{nbsp}компьютер, пока не{nbsp}закончится синхронизация, иначе некоторые файлы могут не{nbsp}загрузиться в{nbsp}облачный сервис.',
    silver: 'Файлы синхронизированы',
    gray: 'Синхронизация приостановлена',
    yellow:
        'Ошибка синхронизации{mdash}файлы не{nbsp}синхронизированы. Скорее всего у{nbsp}файлов есть спецсимволы в{nbsp}названии или их{nbsp}длина превышает ограничение.',
    red: 'Ошибка сети{nbsp}{mdash} нет интернета',
    name4: 'Доступ без интернета',
    text6:
        'Вы{nbsp}можете настроить работу с{nbsp}файлами и{nbsp}папками в{nbsp}<nowrap>офлайн-режиме</nowrap>. Эти файлы будут доступны в{nbsp}<nowrap>Диск-О:</nowrap>, даже когда нет интернета. Подробнее об{nbsp}<nowrap>офлайн-режиме</nowrap> смотрите в{nbsp}статье {laquo}<b>Настройки приложения</b>{raquo}. Как изменить место хранения данных, смотрите в{nbsp}разделе {laquo}<a>Расположение данных приложения</a>{raquo}.',
    name5: 'Ограничения',
    name6: 'Максимальная длина имени папки',
    text7: '255 символов <nowrap>UTF-8</nowrap>',
    name7: 'Максимальная длина имени файла',
    name8: 'Максимальная длина пути к{nbsp}файлу (включает в{nbsp}себя путь к{nbsp}папке + имя файла)',
    text8: '1024 байта',
    name9: 'Символы для названия папок и{nbsp}файлов',
    text9: 'Любые символы юникода в кодировке <nowrap>UTF-8</nowrap>, кроме "*/:<>?\\|\\0',
    text10: 'Также не{nbsp}синхронизируются файлы, у{nbsp}которых в{nbsp}конце названия стоит точка или пробел.',
});

export const Sync = ({ title }: { title: string }) => {
    const { pathname } = useLocation();
    const { section } = useParams<{ section: string }>();

    return (
        <div className={styles.tab}>
            <TabTitle>{title}</TabTitle>
            <TabText>
                <FormattedMessage {...messages.text1} />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name1} />
            </TabName>
            <TabText>
                <FormattedMessage {...messages.text2} />
            </TabText>
            <TabText>
                <FormattedMessage
                    {...messages.text3}
                    values={{
                        a: (msg: string) => <Link href={`${pathname.replace(section, 'settings')}#app-data`}>{msg}</Link>,
                    }}
                />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name2} />
            </TabName>
            <TabText>
                <FormattedMessage {...messages.text4} />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name4} />
            </TabName>
            <TabText>
                <FormattedMessage
                    {...messages.text6}
                    values={{
                        a: (msg: string) => <Link href={`${pathname.replace(section, 'settings')}#app-data`}>{msg}</Link>,
                        b: (msg: string) => <Link to={`${pathname.replace(section, 'settings')}`}>{msg}</Link>,
                    }}
                />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name3} />
            </TabName>
            <TabText>
                <FormattedMessage {...messages.text5} />
            </TabText>
            <TabText>
                <img key={silverIcon} src={silverIcon} className={styles.listIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.silver} />
                </span>
            </TabText>
            <TabText>
                <img key={grayIcon} src={grayIcon} className={styles.listIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.gray} />
                </span>
            </TabText>
            <TabText>
                <img key={yellowIcon} src={yellowIcon} className={styles.listIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.yellow} />
                </span>
            </TabText>
            <TabText>
                <img key={redIcon} src={redIcon} className={styles.listIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.red} />
                </span>
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name5} />
            </TabName>
            <TabName>
                <FormattedMessage {...messages.name6} />
            </TabName>
            <TabText>
                <FormattedMessage {...messages.text7} />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name7} />
            </TabName>
            <TabText>
                <FormattedMessage {...messages.text7} />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name8} />
            </TabName>
            <TabText>
                <FormattedMessage {...messages.text8} />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name9} />
            </TabName>
            <TabText>
                <FormattedMessage {...messages.text9} />
            </TabText>
            <TabText>
                <FormattedMessage {...messages.text10} />
            </TabText>
        </div>
    );
};

import { GiveTariffsResponse } from '../api/giveTariffs';

export enum TariffMods {
    /** Use as default tariffs */
    'none' = '',
    'trials' = 'trials',
    'trials3m' = 'trials3m',
    'trials1mYear' = 'trials1mYear',
    'bundle10' = 'bundle10',
    'bundle20' = 'bundle20',
    'cloud128' = 'cloud128',
    'cloud256' = 'cloud256',
    'costDecrease' = 'costDecrease',
    'action' = 'action',
}

export interface Tariff {
    duration: number;
    durationStr: string;
    price: number;
    oldPrice?: number;
    currency: Currency;
    id: string;
    enabled: boolean;
    tariffMod?: TariffMods;
    trialPeriod: number;
    cloudGift?: string;
}

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    RUB = 'RUB',
}

export const CURRENCY_SYMBOL = {
    [Currency.RUB]: '₽',
    [Currency.USD]: '$',
    [Currency.EUR]: '€',
};

export const CURRENCY_ROUND = {
    [Currency.RUB]: 0,
    [Currency.USD]: 2,
    [Currency.EUR]: 2,
};

export interface TariffsState {
    list: {
        [key: string]: Tariff;
    };
    isLoading: boolean;
    hasError: boolean;
}

export enum TariffLoadActionTypes {
    REQUEST = '@tariffs/load/request',
    SUCCESS = '@tariff/load/success',
    FAILURE = '@tariffs/load/failure',
}

export interface TariffLoadRequestAction {
    type: typeof TariffLoadActionTypes.REQUEST;
}

export interface TariffLoadSuccessAction {
    type: typeof TariffLoadActionTypes.SUCCESS;
    list: GiveTariffsResponse;
}

export interface TariffLoadFailureAction {
    type: typeof TariffLoadActionTypes.FAILURE;
}

export type TariffLoadTypes = TariffLoadSuccessAction | TariffLoadFailureAction | TariffLoadRequestAction;

import { LinksActionTypesUnion, LinksState, LinksActionTypes } from './links.types';

const INITIAL_STATE: LinksState = {
    downloadMacOs: {
        backupsPage: {
            otherLink: 'https://trk.mail.ru/c/zztca9?mt_idfa={mt_idfa}',
            ruLink: 'https://trk.mail.ru/c/yj8kr2?mt_idfa={mt_idfa}',
        },
        otherLink: 'https://trk.mail.ru/c/my8yb6',
        ruLink: 'https://trk.mail.ru/c/drrqn5?mt_idfa={mt_idfa}',
    },
};

export const linksReducer = (state = INITIAL_STATE, action: LinksActionTypesUnion): LinksState => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (action.type) {
        case LinksActionTypes.SET_MAC_OS_LINK:
            return {
                ...INITIAL_STATE,
                downloadMacOs: action.payload,
            };
        default:
            return state;
    }
};

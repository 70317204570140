interface PingApiOptions {
    // max number of request attempts
    maxCount: number;
    finishTime: number;
    // time between request attempts in ms
    interval: number;
}

export function pingApi<T>(
    request: () => Promise<T>,
    check: (results: T) => boolean,
    options: PingApiOptions = { maxCount: 40, finishTime: 0, interval: 500 }
): Promise<T> {
    if (!options.finishTime) {
        options.finishTime = +new Date() * 1000 * 60 * 20;
    }
    return new Promise((resolve, reject) => {
        let i = 1;
        let id = setTimeout(function tick() {
            request()
                .then(function (data: T): void {
                    if (data && check(data)) {
                        resolve(data);

                        clearTimeout(id);
                    } else if (i < options.maxCount && +new Date() < options.finishTime) {
                        i++;
                        id = setTimeout(tick, options.interval);
                    } else {
                        clearTimeout(id);
                        reject();
                    }
                })
                .catch(function (error: any) {
                    reject(error && error.toString());
                });
        }, options.interval);
    });
}

import React, { useEffect, useState, Fragment } from 'react';
import styles from './BackupsPage.css';
import { Services } from 'src/ui/Services/Services';
import { jigurda } from 'src/utils/jigurda';
import { BackupsHero } from 'src/ui/BackupsHero/BackupsHero';
import { defineMessages } from 'react-intl';
import { FormattedMessage } from 'src/utils/i18n';
import { BackupFeatures } from 'src/ui/BackupFeatures/BackupFeatures';
import { BackupsHowToEnable } from 'src/ui/BackupsHowToEnable/BackupsHowToEnable';
import { BackupsHowToRecover } from 'src/ui/BackupsHowToRecover/BackupsHowToRecover';
import { BackupsFAQ } from 'src/ui/BackupsFAQ/BackupsFAQ';
import { BackupsLearnMore } from 'src/ui/BackupsLearnMore/BackupsLearnMore';
import { useSelector } from 'react-redux';
import { isMobile as isMobileSelector } from 'src/modules/media/media.selectors';
import { GetLinkViaEmail } from 'src/components/GetLinkViaEmail/GetLinkViaEmail';
import { sendGa } from 'src/utils/ga';
import { Tariffs } from '../Tariffs/Tariffs';

export const messages = defineMessages({
    servicesText: 'Вы можете подключить к Диск-О: один из 11 облачных сервисов и хранить резервные копии в любом из них.',
    metaTitle: 'Резервное копирование файлов и других данных в Диск-О:',
    metaDescription: 'Создавайте копии папок с компьютера в любом из облаков, чтобы защитить свои файлы',
});

const heroMessages = defineMessages({
    titleA: 'Резервное копирование <nowrap>в Диск-О:</nowrap>',
    titleB: 'Защита файлов <nowrap>в Диск-О:</nowrap>',
    textA:
        'Создавайте копии папок с компьютера в любом из облаков, чтобы защитить свои файлы. Вы сможете легко восстановить данные, если с вашим устройством что-то случится.',
    textB:
        'Создавайте копии файлов с компьютера в любом из облаков, чтобы защитить их от потери. Вы сможете легко восстановить данные, если с вашим устройством что-то случится.',
});

const howToMessages = defineMessages({
    titleEnableA: 'Как создать резервную копию?',
    titleEnableB: 'Как включить защиту файлов?',
    titleRecoverA: 'Как восстановить данные из резервной копии?',
    titleRecoverB: 'Как восстановить файлы?',
});

const getMessage = (group: ReturnType<typeof defineMessages>, name: string, ab: null | boolean) => group[`${name}${ab ? 'A' : 'B'}`];

export const BackupsPage = () => {
    const [isBackupsAB, setBackupsAB] = useState<boolean | null>(null);
    const isMobile = useSelector(isMobileSelector);

    useEffect(() => {
        jigurda('backups-landing', 50).then((isEnabled) => {
            setBackupsAB(isEnabled);
            sendGa({
                category: 'backups-page',
                action: 'show',
                label: isEnabled ? 'A' : 'B',
            });
        });
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.hero}>
                <BackupsHero title={getMessage(heroMessages, 'title', isBackupsAB)} text={getMessage(heroMessages, 'text', isBackupsAB)} />
            </div>
            <div className={styles.services} id="about">
                <Services description={<FormattedMessage {...messages.servicesText} />} />
            </div>
            {isMobile && (
                <div className={styles.getLinkViaEmail}>
                    <GetLinkViaEmail />
                </div>
            )}
            <div className={styles.features}>
                <BackupFeatures />
            </div>
            {!isMobile && (
                <Fragment>
                    <div className={styles.howToEnable}>
                        <BackupsHowToEnable title={getMessage(howToMessages, 'titleEnable', isBackupsAB)} />
                    </div>
                    <div className={styles.howToRecover}>
                        <BackupsHowToRecover title={getMessage(howToMessages, 'titleRecover', isBackupsAB)} />
                    </div>
                </Fragment>
            )}
            <div className={styles.backupsTariffs}>
                <Tariffs />
            </div>
            {!isMobile && (
                <div className={styles.faq} id="faq">
                    <BackupsFAQ />
                </div>
            )}
            <div className={styles.learnMore}>
                <BackupsLearnMore />
            </div>
        </div>
    );
};

import { OmicronActionTypes, OmicronState, OmicronGetConfigActionTypes } from './omicron.types';

const INITIAL_STATE: OmicronState = {
    isLoading: false,
    hasError: false,
    data: null,
};

export const omicronReducer = (state = INITIAL_STATE, action: OmicronActionTypes): OmicronState => {
    switch (action.type) {
        case OmicronGetConfigActionTypes.REQUEST:
            return {
                ...INITIAL_STATE,
                isLoading: true,
            };
        case OmicronGetConfigActionTypes.FAILURE:
            return {
                ...INITIAL_STATE,
                hasError: true,
            };
        case OmicronGetConfigActionTypes.SUCCESS: {
            return {
                ...INITIAL_STATE,
                data: action.payload,
            };
        }
        default:
            return state;
    }
};

import React, { PureComponent, ReactElement } from 'react';
import classNames from 'clsx';
import styles from './List.css';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { ReactComponent as MarkIcon } from 'img/icons/mark.svg';

interface Props {
    list: (string | ReactElement)[];
    markColor?: 'blue' | 'black' | 'white';
}

export class List extends PureComponent<Props> {
    static defaultProps = {
        markColor: 'blue',
    };

    render(): ReactElement {
        return (
            <div className={styles.root}>
                {this.props.list.map((item, index) => (
                    <div className={styles.item} key={index}>
                        <div
                            className={classNames({
                                [styles.mark]: true,
                                [styles[`mark_color_${this.props.markColor}`]]: this.props.markColor,
                            })}
                        >
                            <MarkIcon />
                        </div>
                        <Text size={TextSize['17px']} tabletSize={TextSize['20px']} weight={Weight.w500}>
                            {item}
                        </Text>
                    </div>
                ))}
            </div>
        );
    }
}

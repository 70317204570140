import { ActionTypes, MatchMediaAction, MediaTypes } from 'src/modules/media/media.types';
import { store } from 'src/store';
import { isSSR } from 'src/utils/ua-parser';

export const matchMedia = (media: MediaTypes): MatchMediaAction => ({
    type: ActionTypes.SET,
    media,
});

const mediumDesktop = '(min-width: 1466px)';
const smallDesktop = '(min-width: 1024px)';
const tablet = '(min-width: 768px)';

const isMatch = (media: string): boolean => window.matchMedia(media).matches;

const match = (): void => {
    if (isMatch(mediumDesktop) || isSSR) {
        store.dispatch(matchMedia(MediaTypes.DESKTOP_MEDIUM));
    } else if (isMatch(smallDesktop)) {
        store.dispatch(matchMedia(MediaTypes.DESKTOP_SMALL));
    } else if (isMatch(tablet)) {
        store.dispatch(matchMedia(MediaTypes.TABLET));
    } else {
        store.dispatch(matchMedia(MediaTypes.PHONE));
    }
};

export const init = (): void => {
    match();
    window.addEventListener('resize', match);
};

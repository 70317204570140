import React, { FC, ImgHTMLAttributes, PureComponent, ReactElement } from 'react';
import classNames from 'clsx';
import styles from './Features.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Container } from 'src/ui/Container/Container';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';
import { Download } from 'src/ui/Download/Download';
import { i18n } from 'src/utils/i18n';
import diskoIcon from 'img/icons/disko-icon.svg';
import transferIcon from 'img/icons/transfer.svg';
import copyIcon from 'img/icons/copy.svg';
import uploadIcon from 'img/icons/upload.svg';
import backupIcon from 'img/icons/updates.svg';
import offlineIcon from 'img/icons/offline.svg';
import x2Icon from 'img/icons/x2.svg';
import foldersIcon from 'img/icons/folders.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AppState } from 'src/store';
import { isMobile, isTablet } from 'src/modules/media/media.selectors';
import { connect } from 'react-redux';
import { Carousel } from 'src/ui/Carousel/Carousel';
import { Player } from 'src/ui/Player/Player';
import posterTransfer from 'img/posterTransfer.png';

type Feature = {
    name: string;
    text: string;
    textFooter?: string;
    logo: string;
    size?: ImgHTMLAttributes<HTMLImageElement>;
    size2?: ImgHTMLAttributes<HTMLImageElement>;
};

const messages = defineMessages({
    titleRu: 'Диск-О: – всё в одной программе',
    title: 'Возможности Диск-О:',
    descriptionRu:
        'Забудьте про отдельные приложения — Диск-О: позволяет работать с облаками как с папками в удобном интерфейсе и быстрее, чем в браузере.  Возможности приложения постоянно расширяются. Следите за обновлениями!',
    description:
        'Работайте с облаками как с папками в удобном интерфейсе и быстрее, чем в браузере. Возможности приложения постоянно расширяются. Следите за обновлениями!',
    diskoTitle: 'Всё в одной программе',
    diskoText:
        'Диск-О: позволяет подключить все самые популярные облачные хранилища и работать с ними в едином интерфейсе.\n' +
        'Забудьте про отдельные приложения.',
    copyTitle: 'Копирование ссылок на файл',
    copyText:
        'Диск-О: позволяет получить прямую ссылку на файл в облаке. Документ будет доступен только тем, кому вы отправите сгенерированную ссылку.',
    spaceTitle: 'Файлы не занимают место на устройстве',
    spaceText:
        'С приложением Диск-О: вы можете загружать столько файлов в облака, сколько позволяет их объём, не занимая место в памяти компьютера.',
    offlineTitle: 'Работа офлайн',
    offlineText:
        'Выбирайте файлы, к которым вы хотите иметь доступ всегда, и работайте с ними  без подключения к интернету. Файлы обновятся автоматически, как появится Сеть.',
    timeTitle: 'Экономит ваше время',
    timeText: 'Вы работаете со всеми своими облачными хранилищами, не открывая браузер, и переключаетесь между ними в один клик.',
    folderTitle: 'Работайте с облаками как с папками',
    folderText: 'Работать с облачными хранилищами так же просто, как с папками на вашем компьютере, они появятся в списке устройств.',
    download: 'Скачать для {osName}',
});

const otherFeatures: Feature[] = [
    {
        name: i18n.formatMessage(messages.diskoTitle),
        text: i18n.formatMessage(messages.diskoText),
        logo: diskoIcon,
        size: {
            width: 44,
            height: 32,
        },
    },
    {
        name: i18n.formatMessage(messages.offlineTitle),
        text: i18n.formatMessage(messages.offlineText),
        logo: offlineIcon,
        size: {
            width: 36,
            height: 29,
        },
    },
    {
        name: i18n.formatMessage(messages.copyTitle),
        text: i18n.formatMessage(messages.copyText),
        logo: copyIcon,
        size: {
            width: 36,
            height: 36,
        },
    },
    {
        name: i18n.formatMessage(messages.timeTitle),
        text: i18n.formatMessage(messages.timeText),
        logo: x2Icon,
        size: {
            width: 42,
            height: 28,
        },
    },
    {
        name: i18n.formatMessage(messages.spaceTitle),
        text: i18n.formatMessage(messages.spaceText),
        logo: uploadIcon,
        size: {
            width: 38,
            height: 36,
        },
    },
    {
        name: i18n.formatMessage(messages.folderTitle),
        text: i18n.formatMessage(messages.folderText),
        logo: foldersIcon,
        size: {
            width: 36,
            height: 32,
        },
    },
];

const ruFeatures: Feature[] = [
    {
        name: 'Всё в одной программе',
        text:
            'Забудьте про отдельные приложения\u00A0\u2014 Диск-О: позволяет подключить все самые популярные облачные хранилища и\u00A0работать с\u00A0ними в\u00A0едином интерфейсе.',
        logo: diskoIcon,
        size: {
            width: 36,
            height: 29,
        },
        size2: {
            width: 55,
            height: 40,
        },
    },
    {
        name: 'Быстрый перенос файлов',
        text: 'Переносите файлы из\u00A0любых подключенных хранилищ в\u00A0Облако Mail.ru всего за\u00A0пару кликов.',
        textFooter: 'Бонус! Файлы из\u00A0Google Drive при переносе автоматически конвертируются в\u00A0удобный для работы формат.',
        logo: transferIcon,
        size: {
            width: 36,
            height: 29,
        },
        size2: {
            width: 44,
            height: 40,
        },
    },
    {
        name: 'Работа офлайн',
        text:
            'Выбирайте файлы, к\u00A0которым вы\u00A0всегда хотите иметь доступ, и\u00A0работайте с\u00A0ними без подключения к\u00A0интернету. Файлы обновятся автоматически, когда появится сеть.',
        logo: offlineIcon,
        size: {
            width: 36,
            height: 29,
        },
    },
    {
        name: 'Доступ по ссылке',
        text:
            'Делитесь файлами прямо из\u00A0Диск-О: \u2014\u00A0в\u00A0программе можно сгенерировать прямую ссылку на\u00A0файл в\u00A0любом подключенном облаке.',
        logo: copyIcon,
        size: {
            width: 36,
            height: 36,
        },
    },
    {
        name: 'Резервное копирование',
        text:
            'Создавайте копии папок с\u00A0компьютера в\u00A0любом из\u00A0облаков, чтобы защитить файлы и\u00A0быстро восстановить их\u00A0при необходимости.',
        logo: backupIcon,
        size: {
            width: 44,
            height: 32,
        },
    },
    {
        name: 'Экономия времени',
        text: 'Переключайтесь между облачными хранилищами в\u00A0один клик и\u00A0работайте с\u00A0ними, не\u00A0открывая браузер.',
        logo: x2Icon,
        size: {
            width: 42,
            height: 28,
        },
    },
    {
        name: 'Облако как папка',
        text:
            'Открывайте подключенные к\u00A0Диск-О: облака так\u00A0же легко, как обычные папки\u00A0\u2014 они появятся в\u00A0списке устройств.',
        logo: foldersIcon,
        size: {
            width: 36,
            height: 32,
        },
    },
    {
        name: 'Свободное место',
        text:
            'Загружайте в\u00A0облака столько файлов, сколько позволяет их\u00A0объём\u00A0\u2014 с\u00A0Диск-О: они не\u00A0займут место в\u00A0памяти компьютера.',
        logo: uploadIcon,
        size: {
            width: 38,
            height: 36,
        },
    },
];

const renderFeatureItem: FC<
    Feature & {
        hasBorder?: boolean;
        defaultFeature?: boolean;
    }
> = ({ logo, name, text, textFooter = '', size, size2, hasBorder = true, defaultFeature = true }) => (
    <div key={name} className={classNames({ [styles.item]: defaultFeature, [styles.presentationItem]: !defaultFeature })}>
        <div className={classNames(styles.itemIcon, { [styles.itemIconWithBorder]: hasBorder })}>
            <img src={logo} {...(hasBorder ? size : size2)} alt={name} />
        </div>
        <div className={styles.itemContent}>
            <div className={styles.itemName}>
                <Text size={TextSize['28px']} weight={Weight.w700}>
                    {name}
                </Text>
            </div>
            <div className={styles.itemText}>
                <Text size={TextSize['20px']} weight={Weight.w500}>
                    {text}
                </Text>
            </div>
            {!!textFooter && (
                <div className={styles.itemFooterText}>
                    <Text size={TextSize['18px']} weight={Weight.w500}>
                        {textFooter}
                    </Text>
                </div>
            )}
        </div>
    </div>
);

interface Props {
    mod?: 'backups';
    title?: ReactElement;
    description?: ReactElement;
    presentationCount?: number;
    features?: Feature[];
    isRu: boolean;
}

interface MapState {
    isMobile: boolean;
    isTablet: boolean;
}

const mapStateToProps = (state: AppState): MapState => ({
    isMobile: !!isMobile(state),
    isTablet: !!isTablet(state),
});

class FeaturesComponent extends PureComponent<Props & MapState> {
    public readonly state = {
        width: 320,
    };

    componentDidMount(): void {
        let width = 0;

        if (window.innerWidth > 320) {
            if (window.innerWidth < 500) {
                width = window.innerWidth - 47;
            }
            width = 320;
        } else {
            width = 280;
        }

        this.setState({
            width,
        });
    }

    renderPresentationFeature = ({
        presentationCount,
        features,
        isRu,
    }: {
        presentationCount?: number;
        features: Feature[];
        isRu: boolean;
    }) => {
        if (!presentationCount) {
            return null;
        }

        const presentationFeatureList = features.slice(0, presentationCount);

        if (isRu) {
            const item = presentationFeatureList[1];

            return (
                <div className={styles.presentationListContainer}>
                    <div className={classNames(styles.presentationList, styles.presentationListRu)}>
                        <div key={item.name} className={classNames(styles.presentationItem, styles.presentationItemRu)}>
                            <div className={styles.leftBlock}>
                                <div className={styles.itemIcon}>
                                    <img src={item.logo} {...item.size2} alt={item.name} />
                                </div>
                                <div className={styles.itemContent}>
                                    <div className={styles.itemName}>
                                        <Text size={TextSize['28px']} weight={Weight.w700}>
                                            {item.name}
                                        </Text>
                                    </div>
                                    <div className={styles.itemText}>
                                        <Text size={TextSize['20px']} weight={Weight.w500}>
                                            {item.text}
                                        </Text>
                                    </div>
                                    <div className={styles.itemFooterText}>
                                        <Text size={TextSize['18px']} weight={Weight.w500}>
                                            {item.textFooter}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.right}>
                                <div className={styles.player}>
                                    <Player
                                        poster={posterTransfer}
                                        videoUrl={`https://www.youtube.com/embed/lM3RgA3zxVc?controls=0`}
                                        noPosterBackground
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.presentationListContainer}>
                <div className={styles.presentationList}>
                    {presentationFeatureList.map((feature) => renderFeatureItem({ ...feature, hasBorder: false, defaultFeature: false }))}
                </div>
            </div>
        );
    };

    renderDesktopFeatures = (): ReactElement => {
        const { presentationCount, isRu, features = isRu ? ruFeatures : otherFeatures } = this.props;
        const mainFeatureList = features.slice(presentationCount);

        return (
            <>
                {this.renderPresentationFeature({ presentationCount, features, isRu })}
                <Container>
                    <div className={styles.list}>{mainFeatureList.map(renderFeatureItem)}</div>
                </Container>
            </>
        );
    };

    renderCarousel = (hideDots = false): ReactElement => {
        const { width } = this.state;
        const { features } = this.props;

        const settings = {
            dots: !hideDots,
            speed: 500,
            variableWidth: hideDots,
            infinite: false,
            arrows: false,
            slidesToScroll: 1,
            slidesToShow: hideDots ? 2 : 1,
        };

        return (
            <div className={styles.carousel}>
                <Carousel maxWidth={`${width}px`} {...settings}>
                    {features &&
                        features.map((feature) => (
                            <div className={styles.carouselItem} key={feature.name}>
                                <div className={styles.carouselHeader}>
                                    <img src={feature.logo} className={styles.carouselIcon} alt={feature.name} />
                                    <div className={styles.carouselName}>
                                        <Text size={TextSize['20px']} weight={Weight.w700}>
                                            {feature.name}
                                        </Text>
                                    </div>
                                </div>
                                <div className={styles.carouselBody}>
                                    <Text size={TextSize['20px']} weight={Weight.w500}>
                                        {feature.text}
                                    </Text>
                                </div>
                            </div>
                        ))}
                </Carousel>
            </div>
        );
    };

    render(): ReactElement {
        const {
            mod,
            isRu,
            description = <FormattedMessage {...(isRu ? messages.descriptionRu : messages.description)} />,
            title = <FormattedMessage {...(isRu ? messages.titleRu : messages.title)} />,
            isMobile,
            isTablet,
        } = this.props;

        return (
            <div
                id="features"
                className={classNames({
                    [styles[`root_mod_${mod}`]]: !!mod,
                })}
            >
                <Container>
                    <div className={styles.texts}>
                        <div className={styles.title}>
                            <Text
                                size={TextSize['32px']}
                                desktopSize={TextSize['44px']}
                                lineHeight={LineHeight.inherit}
                                weight={Weight.w700}
                            >
                                {title}
                            </Text>
                        </div>
                        {description && (
                            <div className={styles.description}>
                                <Text size={TextSize['20px']} lineHeight={LineHeight['30px']}>
                                    {description}
                                </Text>
                            </div>
                        )}
                    </div>
                </Container>
                <div className={styles.features}>{isMobile ? this.renderCarousel(isTablet) : this.renderDesktopFeatures()}</div>
                <div className={styles.download}>
                    <Download />
                </div>
            </div>
        );
    }
}

export const Features = connect(mapStateToProps)(FeaturesComponent);

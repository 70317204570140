import { API } from 'src/modules/api/api';

export interface GetBindRequest {
    alias: string;
    language: string;
}

export interface GetBindResponse {
    url: string;
}

export const getBind = (params: GetBindRequest): Promise<GetBindResponse> =>
    new API().makeRequest({
        method: 'post',
        url: 'bind/create',
        body: params,
    });

import { ANIMATION_DURATION, CIRCLE_SIZE, ARROW_SIZE, LINE_WIDTH, BEGIN_ATTITUDE, END_ATTITUDE } from './constants';

export type Direction = 'left' | 'right' | 'up' | 'down';

export interface Dot {
    x: number;
    y: number;
}

export type Coordinate = Dot & {
    vector: Direction;
};

interface Coordinates {
    begin: Coordinate;
    end: Coordinate;
}

interface Line {
    begin: Dot;
    end: Dot;
}

interface Props {
    canvas: HTMLCanvasElement;
    coordinates: Coordinates;
    color?: string;
}

export class Arrow {
    private wasRendered = false;
    private canvas: HTMLCanvasElement;
    private context: CanvasRenderingContext2D | null;

    // initialized in this.setCoordinates
    private begin!: Coordinate;
    private end!: Coordinate;
    private lastLineDot!: Dot;
    private beginBezierDot!: Dot;
    private endBezierDot!: Dot;

    private color = '#000000';
    private beginTime = 0;
    private frameId = 0;
    private duration = ANIMATION_DURATION;
    private lineWidth = LINE_WIDTH;
    private arrowSize = ARROW_SIZE;
    private circleSize = CIRCLE_SIZE;
    private beginAttitude = BEGIN_ATTITUDE;
    private endAttitude = END_ATTITUDE;

    constructor(props: Props) {
        this.canvas = props.canvas;
        this.context = this.canvas.getContext('2d');
        if (props.color) this.color = props.color;

        this.setCoordinates(props.coordinates);
        this.updateSize();
        this.clear();
    }

    private getBezierDot = (from: Coordinate, to: Dot, attitude: number): Dot => {
        let dot: Dot;
        if (['up', 'down'].includes(from.vector)) {
            const yDifferenceAbs = Math.max(Math.abs(to.y - from.y), 75);
            const yAdd = (from.vector === 'down' ? yDifferenceAbs : -yDifferenceAbs) * attitude;

            dot = {
                x: from.x,
                y: from.y + yAdd,
            };
        } else {
            const xDifferenceAbs = Math.max(Math.abs(to.x - from.x), 75);
            const xAdd = (from.vector === 'right' ? xDifferenceAbs : -xDifferenceAbs) * attitude;

            dot = {
                x: from.x + xAdd,
                y: from.y,
            };
        }

        return dot;
    };

    private updateBezierDots = () => {
        this.beginBezierDot = this.getBezierDot(this.begin, this.end, this.beginAttitude);
        this.endBezierDot = this.getBezierDot(this.end, this.begin, this.endAttitude);
    };

    private setCoordinates = (coordinates?: Coordinates) => {
        const { begin, end } = coordinates || {};
        if (begin) {
            this.begin = begin;
        }
        if (end) {
            this.end = end;
        }

        this.lastLineDot = this.begin;
        this.updateBezierDots();
    };

    private isArrowPossible = (): boolean => {
        const xDif = Math.abs(this.begin.x - this.end.x);
        const yDif = Math.abs(this.begin.y - this.end.y);
        const distance = Math.sqrt(xDif * xDif + yDif * yDif);
        if (
            (this.end.vector === 'down' && this.begin.y < this.end.y) ||
            (this.end.vector === 'up' && this.begin.y > this.end.y) ||
            (this.end.vector === 'right' && this.begin.x < this.end.x) ||
            (this.end.vector === 'left' && this.begin.x > this.end.x) ||
            distance < 100
        ) {
            return false;
        }
        return true;
    };

    private updateSize = () => {
        const info = this.canvas.getBoundingClientRect();
        this.canvas.width = info.width;
        this.canvas.height = info.height;
    };

    private clear = () => {
        if (!this.context) return;

        this.beginTime = 0;

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        window.cancelAnimationFrame(this.frameId);
    };

    private renderCircle = (dot: Dot) => {
        if (!this.context) return;

        this.context.beginPath();
        this.context.arc(dot.x, dot.y, this.circleSize, 0, Math.PI * 2);
        this.context.fillStyle = this.color;
        this.context.fill();
        this.context.closePath();
    };

    private renderLine = ({ begin, end }: Line) => {
        if (!this.context) return;

        this.context.beginPath();
        this.context.moveTo(begin.x, begin.y);
        this.context.lineWidth = this.lineWidth;
        this.context.lineTo(end.x, end.y);
        this.context.strokeStyle = this.color;
        this.context.stroke();
        this.context.closePath();
    };

    private renderArrow = (dot: Dot, direction: Direction) => {
        if (!this.context) return;

        this.renderLine({
            begin: dot,
            end: {
                x: dot.x - this.arrowSize,
                y: direction === 'down' ? dot.y + this.arrowSize : dot.y - this.arrowSize,
            },
        });
        this.renderLine({
            begin: dot,
            end: {
                x: dot.x + this.arrowSize,
                y: direction === 'down' ? dot.y + this.arrowSize : dot.y - this.arrowSize,
            },
        });
    };

    private renderBezier = () => {
        if (!this.context) return;

        this.context.beginPath();
        this.context.moveTo(this.begin.x, this.begin.y);
        this.context.lineWidth = this.lineWidth;
        this.context.bezierCurveTo(
            this.beginBezierDot.x,
            this.beginBezierDot.y,
            this.endBezierDot.x,
            this.endBezierDot.y,
            this.end.x,
            this.end.y
        );
        this.context.strokeStyle = this.color;
        this.context.stroke();
        this.context.closePath();
    };

    private onAnimationBegins = () => {
        this.renderCircle(this.begin);
    };

    private onAnimationEnds = () => {
        this.renderArrow(this.end, this.end.vector);
    };

    private getDotBetween = ({ begin, end }: Line, step: number): Dot => {
        return {
            x: begin.x + (end.x - begin.x) * step,
            y: begin.y + (end.y - begin.y) * step,
        };
    };

    private onAnimationTick = (step: number) => {
        if (step > 1) {
            step = 1;
        }

        const cp1 = this.getDotBetween({ begin: this.begin, end: this.beginBezierDot }, step);
        const cp2 = this.getDotBetween({ begin: this.beginBezierDot, end: this.endBezierDot }, step);
        const cp3 = this.getDotBetween({ begin: this.endBezierDot, end: this.end }, step);

        const cp12 = this.getDotBetween({ begin: cp1, end: cp2 }, step);
        const cp23 = this.getDotBetween({ begin: cp2, end: cp3 }, step);

        const lineEnd = this.getDotBetween({ begin: cp12, end: cp23 }, step);

        this.renderLine({ begin: this.lastLineDot, end: lineEnd });

        this.lastLineDot = lineEnd;
    };

    private tick = (time: number) => {
        if (!this.beginTime) {
            this.beginTime = time;
            this.onAnimationBegins();
        }

        const animTime = time - this.beginTime;

        if (animTime < this.duration) {
            this.frameId = requestAnimationFrame(this.tick);
        } else {
            this.beginTime = 0;
            this.onAnimationEnds();
        }

        this.onAnimationTick(animTime / this.duration);
    };

    startAnimation = (coordinates?: Coordinates) => {
        this.setCoordinates(coordinates);
        if (!this.isArrowPossible()) {
            this.clear();
            this.wasRendered = false;
            return;
        }
        this.wasRendered = true;
        this.updateSize();

        this.clear();
        this.frameId = requestAnimationFrame(this.tick);
    };

    forceRender = (coordinates?: Coordinates) => {
        this.setCoordinates(coordinates);
        if (!this.isArrowPossible()) {
            this.clear();
            this.wasRendered = false;
            return;
        }
        this.wasRendered = true;
        this.updateSize();

        this.clear();
        this.onAnimationBegins();
        this.renderBezier();
        this.onAnimationEnds();
    };

    rerender = (coordinates?: Coordinates) => {
        this.setCoordinates(coordinates);

        if (this.wasRendered) {
            this.forceRender();
        }
    };
}

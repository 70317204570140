import React, { ReactElement } from 'react';
import { defineMessages } from 'react-intl';
import styles from './Tabs.css';

import settings1 from 'img/help/settings1.png';
import settings2 from 'img/help/settings2.png';
import settings3 from 'img/help/settings3.png';
import settings4 from 'img/help/settings4.png';
import settings5 from 'img/help/settings5.png';
import settings6 from 'img/help/settings6.png';
import settings7 from 'img/help/settings7.png';
import settingsRu1 from 'img/help/settingsRu1.png';
import settingsRu2 from 'img/help/settingsRu2.png';
import settingsRu3 from 'img/help/settingsRu3.png';
import settingsRu4 from 'img/help/settingsRu4.png';
import settingsRu5 from 'img/help/settingsRu5.png';
import settingsRu6 from 'img/help/settingsRu6.png';
import settingsRu7 from 'img/help/settingsRu7.png';
import { TabTitle } from 'src/ui/HelpPage/Tabs/TabTitle';
import { TabText } from 'src/ui/HelpPage/Tabs/TabText';
import { FormattedMessage } from 'src/utils/i18n';
import { TabImage } from 'src/ui/HelpPage/Tabs/TabImage';
import { TabName } from 'src/ui/HelpPage/Tabs/TabName';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { AvailableLanguages } from 'src/modules/language/language.types';
import { Link } from 'src/ui/Link/Link';

const picture =
    getCurrentLanguage() === AvailableLanguages.ru
        ? {
              settings1: settingsRu1,
              settings2: settingsRu2,
              settings3: settingsRu3,
              settings4: settingsRu4,
              settings5: settingsRu5,
              settings6: settingsRu6,
              settings7: settingsRu7,
          }
        : {
              settings1,
              settings2,
              settings3,
              settings4,
              settings5,
              settings6,
              settings7,
          };

const messages = defineMessages({
    text1: 'Чтобы перейти к{nbsp}настройкам приложения, нажмите иконку {laquo}Настройки{raquo}.',
    name1: 'Автозапуск приложения',
    text2: 'Установите галочку, чтобы <nowrap>Диск-О:</nowrap> открывалось при включении компьютера.',
    text3:
        'При включенном автозапуске файлы будут синхронизироваться, пока вы{nbsp}занимаетесь другими делами на{nbsp}компьютере. Когда вам понадобится для работы облако, файлы будут уже синхронизированы.',
    name2: 'Запоминать файлы',
    text4:
        'Установите галочку, чтобы <nowrap>Диск-О:</nowrap> запоминало файлы, которыми вы{nbsp}пользуетесь чаще всего, и{nbsp}сохраняло их{nbsp}на{nbsp}компьютере. Благодаря этому важные файлы будут доступны, даже когда не{nbsp}работает интернет.',
    text5: 'Чтобы настроить файлы в{nbsp}быстром доступе, нажмите {laquo}Изменить{raquo}.',
    text6:
        'В{nbsp}разделе {laquo}Объем места для хранения файлов{raquo} потяните бегунок вправо или влево, чтобы увеличить или уменьшить место для хранения файлов.',
    text7: 'Как изменить место хранения данных, смотрите в{nbsp}следующем разделе {laquo}<a>Расположение данных приложения</a>{raquo}.',
    name3: 'Расположение данных приложения',
    text8:
        '<nowrap>Диск-О:</nowrap> по{nbsp}умолчанию хранит свои данные на{nbsp}системном диске. Чтобы изменить диск и{nbsp}папку для хранения данных приложения, в{nbsp}разделе {laquo}Файлы в{nbsp}быстром доступе{raquo} нажмите {laquo}Изменить{raquo}.',
    text9: 'Далее нажмите {laquo}Настроить путь хранения файлов на{nbsp}ПК{raquo} и{nbsp}выберите новую папку.',
    warningFolder:
        'В{nbsp}этой папке <nowrap>Диск-О:</nowrap> хранит служебные данные{nbsp}{mdash} не{nbsp}изменяйте и{nbsp}не{nbsp}удаляйте их, чтобы приложение работало без ошибок.',
    name4: 'Синхронизировать папки',
    text10:
        'Выберите файлы и{nbsp}папки на{nbsp}облаке, которые будут доступны без интернета. Благодаря этому увеличится скорость работы с{nbsp}файлами, потому что они будут сохранены на{nbsp}компьютере.',
    text11: 'Чтобы отметить папки и{nbsp}файлы для доступа без интернета, нажмите {laquo}Выбрать{raquo}.',
    text12:
        'Отметьте нужные папки и{nbsp}файлы галочками. Чтобы настроить синхронизацию на{nbsp}другом облаке, нажмите на{nbsp}название текущего и{nbsp}выберите нужное в{nbsp}списке. Чтобы сохранить изменения, нажмите {laquo}Закрыть окно{raquo}.',
});

export const Settings = ({ title }: { title: string }): ReactElement => (
    <div className={styles.tab}>
        <TabTitle>{title}</TabTitle>
        <TabText>
            <FormattedMessage {...messages.text1} />
        </TabText>
        <TabImage src={picture.settings1} />
        <TabName>
            <FormattedMessage {...messages.name1} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text2} />
        </TabText>
        <TabImage src={picture.settings2} />
        <TabText>
            <FormattedMessage {...messages.text3} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name2} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text4} />
        </TabText>
        <TabText>
            <FormattedMessage {...messages.text5} />
        </TabText>
        <TabImage src={picture.settings3} />
        <TabText>
            <FormattedMessage {...messages.text6} />
        </TabText>
        <TabText>
            <FormattedMessage
                {...messages.text7}
                values={{
                    a: (msg: string) => <Link to="#app-data">{msg}</Link>,
                }}
            />
        </TabText>
        <TabName id="app-data">
            <FormattedMessage {...messages.name3} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text8} />
        </TabText>
        <TabImage src={picture.settings4} />
        <TabText>
            <FormattedMessage {...messages.text9} />
        </TabText>
        <TabImage src={picture.settings5} />
        <TabText>
            <FormattedMessage {...messages.warningFolder} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name4} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text10} />
        </TabText>
        <TabText>
            <FormattedMessage {...messages.text11} />
        </TabText>
        <TabImage src={picture.settings6} />
        <TabText>
            <FormattedMessage {...messages.text12} />
        </TabText>
        <TabImage src={picture.settings7} />
    </div>
);

const REGEXP_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Проверяет, является ли строка допустимым емейлом
 *
 * @param {string} email - email
 * @return {bool} true, если строка валидный емейл
 */
export const validateEmail = (email?: string): boolean => {
    if (!email) {
        return false;
    }

    email = email.replace(/(^\s+|\s+$)/g, '');

    if (!email.length || email.includes(' ')) {
        return false;
    }

    return REGEXP_EMAIL.test(email);
};

import React, { PureComponent, ReactNode, ReactElement } from 'react';
import { Section } from 'src/ui/Section/Section';
import { Container } from 'src/ui/Container/Container';
import { Text, TextSize } from 'src/ui/Text/Text';

import styles from './ServiceAbout.css';

interface Props {
    description: string | ReactNode;
    logo: ReactNode;
}

export class ServiceAbout extends PureComponent<Props> {
    render(): ReactElement {
        const { description, logo } = this.props;
        return (
            <div className={styles.wrap}>
                <Section>
                    <div className={styles.root}>
                        <Container>
                            <div className={styles.content}>
                                <div className={styles.logo}>{logo}</div>
                                <div className={styles.description}>
                                    <Text size={TextSize['20px']}>{description}</Text>
                                </div>
                            </div>
                        </Container>
                    </div>
                </Section>
            </div>
        );
    }
}

import React, { cloneElement, createRef, Fragment, PureComponent, ReactElement, ReactNode } from 'react';
import classNames from 'clsx';
import { createPortal } from 'react-dom';
import { Text, TextSize } from 'src/ui/Text/Text';

import styles from './Dropdown.css';

const PADDING = 20;

export enum DropdownAlign {
    left = 'left',
    right = 'right',
    center = 'center',
}

interface DropdownItem {
    text: string;
    icon?: ReactNode;
    id: string;
}

interface Props {
    align?: DropdownAlign;
    children: ReactElement;
    list: DropdownItem[];
    additionMarginX?: number;
    additionMarginY?: number;
    isOpen?: boolean;
    onClick: (id: DropdownItem['id']) => void;
    onOpen?: Function;
    onClose?: Function;
}

interface State {
    isOpen: boolean;
    show: boolean;
}

export class Dropdown extends PureComponent<Props, State> {
    static defaultProps = {
        align: DropdownAlign.left,
        additionMarginX: 0,
        additionMarginY: 0,
        isOpen: false,
    };

    public readonly state = {
        isOpen: Boolean(this.props.isOpen),
        show: false,
    };

    el = document.querySelector('#portal-dropdown');

    targetRef = createRef<HTMLDivElement>();
    elRef = createRef<HTMLDivElement>();

    componentDidMount(): void {
        if (this.state.isOpen) {
            this.setPosition();
        }

        window.addEventListener('resize', this.setPosition);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (prevProps.align !== this.props.align || (!prevState.isOpen && this.state.isOpen)) {
            this.setPosition();
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.setPosition);
    }

    handleOnElementClick = (): void => {
        this.setState({
            isOpen: true,
        });

        this.props.onOpen && this.props.onOpen();
    };

    handleOnItemClick = (id: string) => (): void => {
        this.close();
        this.props.onClick(id);
    };

    handleOnOverlayClick = (): void => {
        this.close();
    };

    close = (): void => {
        this.setState({
            isOpen: false,
            show: false,
        });

        this.props.onClose && this.props.onClose();
    };

    setPosition = (): void => {
        if (this.targetRef.current && this.elRef.current && this.state.isOpen) {
            const targetRect = this.targetRef.current.getBoundingClientRect();
            const el = this.elRef.current;
            const elRect = el.getBoundingClientRect();
            const { additionMarginX, additionMarginY, align } = this.props;

            el.style.top = `${targetRect.bottom + window.pageYOffset + Number(additionMarginY)}px`;
            el.style.left = 'auto';

            if (align === DropdownAlign.left) {
                el.style.left = `${targetRect.left + Number(additionMarginX)}px`;
            } else if (align === DropdownAlign.center) {
                el.style.left = `${targetRect.left + targetRect.width / 2 - elRect.width / 2 + -Number(additionMarginX)}px`;
            } else {
                el.style.left = `${targetRect.left + targetRect.width - elRect.width + Number(additionMarginX)}px`;
            }

            if (parseInt(el.style.left) < PADDING) {
                el.style.left = `${targetRect.left}px`;
            } else if (parseInt(el.style.left) + elRect.width > window.innerWidth - PADDING) {
                el.style.left = `${targetRect.left + targetRect.width - elRect.width}px`;
            }

            this.setState({
                show: true,
            });
        }
    };

    renderItem = (item: DropdownItem): ReactElement => {
        return (
            <li key={item.id} className={styles.item} onClick={this.handleOnItemClick(item.id)}>
                {item.icon && <div className={styles.icon}>{item.icon}</div>}
                <div className={styles.text}>
                    <Text size={TextSize['15px']}>{item.text}</Text>
                </div>
            </li>
        );
    };

    renderDropdown = (): ReactElement => {
        const { align, list } = this.props;
        const { isOpen, show } = this.state;

        return (
            <Fragment>
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_state_open]: isOpen,
                        [styles[`root_align_${align}`]]: align,
                        [styles.root_show]: show,
                    })}
                    ref={this.elRef}
                >
                    <ul className={styles.list}>{list.map(this.renderItem)}</ul>
                </div>
                {show && <div className={styles.overlay} onClick={this.handleOnOverlayClick} />}
            </Fragment>
        );
    };

    render(): ReactElement {
        const { children } = this.props;
        return (
            <Fragment>
                {cloneElement(children, {
                    ref: this.targetRef,
                    onClick: this.handleOnElementClick,
                })}
                {this.el && createPortal(this.renderDropdown(), this.el)}
            </Fragment>
        );
    }
}

import { GetOmicronConfigResponse } from 'src/modules/api/getOmicronConfig';

export enum OmicronGetConfigActionTypes {
    REQUEST = '@omicron/getConfig/request',
    SUCCESS = '@omicron/getConfig/success',
    FAILURE = '@omicron/getConfig/failure',
    RESET = '@omicron/getConfig/reset',
}

export interface GetConfigRequestAction {
    type: typeof OmicronGetConfigActionTypes.REQUEST;
}

export interface GetConfigSuccessAction {
    type: typeof OmicronGetConfigActionTypes.SUCCESS;
    payload: GetOmicronConfigResponse;
}

export interface GetConfigFailureAction {
    type: typeof OmicronGetConfigActionTypes.FAILURE;
}

export interface OmicronState {
    isLoading: boolean;
    hasError: boolean;
    data: GetOmicronConfigResponse | null;
}

export type OmicronActionTypes = GetConfigRequestAction | GetConfigSuccessAction | GetConfigFailureAction;

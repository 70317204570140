import React, { PureComponent, ReactElement } from 'react';
import { Provider } from 'react-redux';
import { store } from 'src/store';
import { RouterContainer } from 'src/ui/RouterContainer/RouterContainer';

export class ReduxContainer extends PureComponent {
    render(): ReactElement {
        return (
            <Provider store={store}>
                <RouterContainer />
            </Provider>
        );
    }
}

import axios, { Method } from 'axios';
import { sendRadar } from 'src/utils/xray';

interface MakeRequestParams {
    params?: object;
    url: string;
    baseURL?: string;
    method: Method;
    postFormData?: boolean;
    formUrlEncodedData?: boolean;
    timeout?: number;
    headers?: Record<string, string>;
    body?: any;
    withCredentials?: boolean;
}

// TODO: add types & retry
export class API {
    method: Method = 'get';
    url = '';
    params = {};
    postFormData = false;

    makeRequest = <T>({
        params = {},
        url = '',
        baseURL = '/api',
        method = 'get',
        postFormData = false,
        formUrlEncodedData = false,
        timeout,
        headers: ownHeaders = {},
        body,
        withCredentials = false,
    }: MakeRequestParams): Promise<T> => {
        this.params = params;
        this.method = method;
        this.postFormData = postFormData;
        this.url = url;

        return new Promise((resolve, reject) => {
            const headers = Object.assign({ 'Content-Type': 'application/json' }, ownHeaders);

            if (postFormData) {
                headers['Content-Type'] = 'multipart/form-data';
            } else if (formUrlEncodedData) {
                headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
            }

            const apiMethod = this.url.replace(/(https|http):\/\/.*\/(api\/|)/, '').replace(/\//g, '');
            sendRadar('api', apiMethod, 'call');

            axios({
                url: this.url,
                method: this.method,
                params: this.params,
                data: body,
                baseURL,
                headers,
                timeout,
                withCredentials,
            })
                .then(({ data }) => {
                    sendRadar('api', apiMethod, 'success');
                    resolve(data);
                })
                .catch((error) => {
                    sendRadar('api', apiMethod, 'error', error.response && error.response.status);
                    reject(error.response && error.response.data);
                });
        });
    };
}

import React from 'react';
import classNames from 'clsx';
import { TextSize, Text, LineHeight, Weight } from 'src/ui/Text/Text';

import styles from './Steps.css';

export interface Step {
    step: number;
    text: string | JSX.Element;
}

export enum ELableColor {
    WHITE = 'white',
    GRAY = 'gray',
}

interface Props {
    steps: Step[];
    lableColor?: ELableColor;
}

export const Steps = (props: Props): JSX.Element => {
    const { steps, lableColor = ELableColor.WHITE } = props;

    return (
        <ul className={styles.root}>
            {steps.map((step) => (
                <li className={styles.step} key={step.step}>
                    <div
                        className={classNames({
                            [styles.count]: true,
                            [styles[`count_color_${lableColor}`]]: lableColor,
                        })}
                    >
                        {step.step}
                    </div>
                    <Text size={TextSize['20px']} lineHeight={LineHeight['30px']} weight={Weight.w400}>
                        {step.text}
                    </Text>
                </li>
            ))}
        </ul>
    );
};

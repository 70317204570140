import { API } from 'src/modules/api/api';

export interface GiveSerialResponse {
    token: string;
    url: string;
}

interface SubmitHelpRequestData {
    name: string;
    email: string;
    license?: string;
    description: string;
    attachment: File | null;
}

export const submitHelpRequest = (params: SubmitHelpRequestData): Promise<GiveSerialResponse> => {
    const formData = new FormData();
    formData.append('fb.user_name', params.name);
    formData.append('fb.communication_email', params.email);
    params.license && formData.append('fb.question.53232', params.license);
    formData.append('fb.message', params.description);
    params.attachment && formData.append('fb.attachement', params.attachment);

    return new API().makeRequest({
        method: 'post',
        url: 'https://help.mail.ru/cloud_web/disko/form',
        postFormData: true,
        body: formData,
    });
};

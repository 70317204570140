import React, { useEffect } from 'react';
import { Container } from 'src/ui/Container/Container';
import { Header } from 'src/ui/Header/Header';
import { FilesHero } from 'src/ui/FilesHero/FilesHero';
import { ShadowBlock } from 'src/ui/ShadowBlock/ShadowBlock';
import { CloudsToConnect } from 'src/ui/CloudsToConnect/CloudsToConnect';
import { HowDiskoWorks } from 'src/ui/HowDiskoWorks/HowDiskoWorks';

import styles from './FilesPage.css';
import Helmet from 'react-helmet';
import { sendGa } from 'src/utils/ga';

const messages = {
    title: (
        <>
            Что такое
            <br /> <span className={styles.accent}>Диск-О:</span>
        </>
    ),
    text:
        'Это облачный агрегатор, в котором можно объединить все используемые хранилища в одном пространстве и переносить данные между ними в один клик.',
    metaTitle: 'Перенос файлов с Яндекс.Диска и Google Drive в Облако Mail.ru',
    metaDescription:
        'Если вы пользуетесь Яндекс.Диском, Гугл Диском, Облаком Mail.ru или другими хранилищами - Диск-О: поможет перенести файлы между ними.',
};

export const FilesPage = (): JSX.Element => {
    useEffect(() => {
        sendGa({
            category: 'event',
            action: 'show',
            label: 'filespage',
        });
    });

    return (
        <div className={styles.root}>
            <Helmet>
                <title>{messages.metaTitle}</title>
                <meta name="description" content={messages.metaDescription} />
            </Helmet>

            <div className={styles.header}>
                <Header isShort whiteButton noLanguage />
            </div>

            <Container>
                <section className={styles.hero}>
                    <FilesHero />
                </section>

                <section className={styles.blocks}>
                    <ShadowBlock title={messages.title} text={messages.text} />
                </section>

                <section className={styles.howDiskoWorks}>
                    <HowDiskoWorks />
                </section>

                <section className={styles.clouds}>
                    <CloudsToConnect />
                </section>
            </Container>
        </div>
    );
};

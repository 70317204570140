import { UnbindActions, UnbindActionTypes, UnbindState } from 'src/modules/unbind/unbind.types';

const INITIAL_STATE: UnbindState = {
    id: '',
    error: '',
    isLoading: false,
    success: false,
};

export const unbindReducer = (state = INITIAL_STATE, action: UnbindActions): UnbindState => {
    switch (action.type) {
        case UnbindActionTypes.SHOW_MODAL:
            return {
                ...INITIAL_STATE,
                id: action.id,
            };
        case UnbindActionTypes.REQUEST:
            return {
                ...INITIAL_STATE,
                id: state.id,
                isLoading: true,
            };
        case UnbindActionTypes.FAILURE:
            return {
                ...INITIAL_STATE,
                id: state.id,
                error: action.error,
            };
        case UnbindActionTypes.SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case UnbindActionTypes.RESET_ERROR:
            return {
                ...state,
                error: '',
            };
        case UnbindActionTypes.RESET_SUCCESS:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};

import { ActionTypes, MatchMediaAction, MediaState, MediaTypes } from './media.types';

const INITIAL_STATE: MediaState = {
    isMobile: false,
    isPhone: false,
    isTablet: false,
    isDesktop: false,
    isDesktopMedium: false,
    isDesktopSmall: false,
};

export const mediaReducer = (state = INITIAL_STATE, action: MatchMediaAction): MediaState => {
    if (action.type === ActionTypes.SET) {
        return {
            ...INITIAL_STATE,
            isMobile: action.media === MediaTypes.PHONE || action.media === MediaTypes.TABLET,
            isPhone: action.media === MediaTypes.PHONE,
            isTablet: action.media === MediaTypes.TABLET,
            isDesktop: action.media === MediaTypes.DESKTOP_MEDIUM || action.media === MediaTypes.DESKTOP_SMALL,
            isDesktopSmall: action.media === MediaTypes.DESKTOP_SMALL,
            isDesktopMedium: action.media === MediaTypes.DESKTOP_MEDIUM,
        };
    }

    return state;
};

import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages } from 'react-intl';
import { getCurrentLanguage, isRussianLanguage } from 'src/modules/language/language.selectors';
import { formatMessage, i18n } from 'src/utils/i18n';
import { isExactMatchPage, isExactMatchPageByLanguage, isIndexPage, isMatchPage } from 'src/utils/route.helpers';
import { messages as backupsMessages } from 'src/ui/BackupsPage/BackupsPage';
import { AvailableLanguages } from 'src/modules/language/language.types';
import { config } from 'src/constants/config';

export const messages = defineMessages({
    title: 'Диск-О: — агрегатор ваших облаков',
    keywords:
        'Клиент для Облака Mail.ru, webdav, Google drive, FTP, SFTP, загружать файла в облако, работа с облаками, клиент для облаков, Диско, Диск-О, disco, disko, disc-o, disk-о, ускорит работу, облака, приложение для работы с облаками, быстрый доступ, сохранить в облако, редактировать, облака как виртуальный диск, жесткие диски, единый доступ к облачным хранилищам, публичные ссылки на файлы, оффлайн-доступ, защита данных паролем, цены, тарифы, популярные облака, облако mail.ru, dropbox, onedrive, бесплатно',
    shareTitle: 'Диск-О:',
    shareDescription: 'Одно приложение для разных облаков',
    siteName: 'Диск-О:',
});

const IMAGE_EN = 'sharing_en.png';
const IMAGE_RU = 'sharing.png';
const IMAGE_BACKUPS_EN = 'backups_en.png';
const IMAGE_BACKUPS_RU = 'backups.png';

interface Props {
    title?: string;
    ogTitle?: string;
    description?: string;
    ogDescription?: string;
    keywords?: string;
    sharingImage?: string;
    manifest?: string;
    faviconIco?: string;
    faviconSvg?: string;
    appleTouchIconPng?: string;
}

export const Meta = (props: Props): ReactElement => {
    const currentLanguage = getCurrentLanguage();
    const currentRoute = window.location.pathname.replace(new RegExp(`${currentLanguage}/`), '');
    const isBackupsPage = isExactMatchPage('backups');
    const isFaqPage = isMatchPage('faq');
    const isMainPage = isMatchPage('main');
    const isIndex = isIndexPage();
    const isRuIndex = isExactMatchPageByLanguage(AvailableLanguages.ru, '');
    const isLangIndex = isExactMatchPageByLanguage(currentLanguage, '');

    const getSharingImage = () => {
        const isRu = isRussianLanguage(currentLanguage);
        if (isBackupsPage) {
            return `/assets/${isRu ? IMAGE_BACKUPS_RU : IMAGE_BACKUPS_EN}`;
        }

        return `/assets/${isRu ? IMAGE_RU : IMAGE_EN}`;
    };

    const {
        title = formatMessage(isBackupsPage ? backupsMessages.metaTitle : messages.title),
        ogTitle = formatMessage(messages.shareTitle) as string,
        ogDescription = formatMessage(isBackupsPage ? backupsMessages.metaDescription : messages.shareDescription) as string,
        keywords = formatMessage(messages.keywords) as string,
        description = formatMessage(isBackupsPage ? backupsMessages.metaDescription : messages.shareDescription) as string,
        sharingImage = getSharingImage(),
        manifest = '/assets/manifest.json',
        faviconIco = '/assets/favicon.ico',
        faviconSvg = '/assets/favicon.svg',
        appleTouchIconPng = '/assets/apple-touch-icon.png',
    } = props;

    const getHrefLangLinks = () => {
        if (isRuIndex) {
            return null;
        }

        const result = [];

        if (isLangIndex) {
            result.push(<link rel="alternate" hrefLang="x-default" key="x-default" href={`https://disk-o.cloud/`} />);
        }

        const langs = isFaqPage ? [AvailableLanguages.ru, AvailableLanguages.en] : Object.keys(AvailableLanguages);

        langs.forEach((lang) => {
            const href = currentRoute === '/' && lang === AvailableLanguages.ru ? '' : `${lang}${currentRoute}`;
            result.push(<link rel="alternate" hrefLang={lang} key={lang} href={`https://disk-o.cloud/${href}`} />);
        });

        return result;
    };

    const getAmpTags = () => {
        if (!config.showAmp) {
            return null;
        }

        return isIndex && <link rel="amphtml" href="https://disk-o.cloud/amp/" />;
    };

    let canonicalHref = `https://disk-o.cloud${window.location.pathname}`;

    if (window.location.pathname === '/ru/') {
        canonicalHref = 'https://disk-o.cloud/';
    }

    if (isMainPage) {
        canonicalHref = `https://disk-o.cloud/${currentLanguage === AvailableLanguages.ru ? '' : `${currentLanguage}/`}`;
    }

    return (
        <Helmet>
            <html lang={currentLanguage} />
            <title>{title}</title>
            <link rel="canonical" href={canonicalHref} />
            {getAmpTags()}
            {getHrefLangLinks()}
            <link rel="manifest" href={manifest} />
            <link rel="icon" href={faviconIco} />
            <link rel="icon" href={faviconSvg} type="image/svg+xml" sizes="any" />
            <link rel="apple-touch-icon" href={appleTouchIconPng} />
            <meta name="keywords" content={keywords} />
            <link rel="image_src" href={sharingImage} />
            // OK & MM
            <meta name="mrc__share_title" content={ogTitle} />
            <meta name="mrc__share_description" content={ogDescription} />
            // VK
            <meta name="title" content={ogTitle} />
            <meta name="description" content={description} />
            // FB
            <meta property="og:title" content={`${ogTitle} ${ogDescription}`} />
            <meta property="og:image" content={sharingImage} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:site_name" content={i18n.formatMessage(messages.siteName)} />
            // TW
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@my_disko" />
            <meta name="twitter:title" content={ogTitle} />
            <meta name="twitter:description" content={ogDescription} />
            <meta name="twitter:image" content={sharingImage} />
            <meta name="yandex-verification" content="f2ee3fb6b116a446" />
        </Helmet>
    );
};

import React, { PureComponent, ReactElement } from 'react';
import { AppState } from 'src/store';
import { connect } from 'react-redux';
import classNames from 'clsx';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { isDownloadPromoSnow } from 'src/modules/downloadPromo/downloadPromo.selectors';
import { hideDownloadPromo } from 'src/modules/downloadPromo/downloadPromo.actions';
import { browserName } from 'src/utils/ua-parser';
import { defineMessages, FormattedMessage } from 'react-intl';
import diskoIcon from 'img/icons/logo.svg';
import closeIcon from 'img/icons/close.svg';
import styles from './DownloadPromo.css';

interface MapState {
    isDownloadPromoSnow: boolean;
}

interface MapDispatch {
    hideDownloadPromo: Function;
}

const mapDispatchToProps = {
    hideDownloadPromo,
};

const mapStateToProps = (state: AppState): MapState => ({
    isDownloadPromoSnow: isDownloadPromoSnow(state),
});

const messages = defineMessages({
    thanks: 'Благодарим за выбор Диск-О',
    text: 'Для его установки откройте список ваших загрузок, запустите установщик и следуйте инструкциям',
});

const KEYCODE_ESC = 27;

export class DownloadPromoComponent extends PureComponent<MapState & MapDispatch> {
    handleClickHide = (event: any): void => {
        event.stopPropagation();
        this.props.hideDownloadPromo();
    };

    handleKeyDown = (event: { keyCode: number }): void => {
        if (event.keyCode === KEYCODE_ESC) {
            this.props.hideDownloadPromo();
        }
    };

    componentDidMount() {
        // eslint-disable-next-line no-unused-expressions
        document?.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        // eslint-disable-next-line no-unused-expressions
        document?.removeEventListener('keydown', this.handleKeyDown);
    }

    render(): ReactElement | null {
        const { isDownloadPromoSnow } = this.props;

        if (!isDownloadPromoSnow) {
            return null;
        }

        return (
            <div
                className={classNames({
                    [styles.downloadPromo]: true,
                    [styles[browserName?.toLowerCase() || '']]: true,
                })}
                onClick={this.handleClickHide}
            >
                <div className={styles.balloon}>
                    <img className={styles.logo} src={diskoIcon} alt="" />
                    <div className={styles.col}>
                        <div className={styles.header}>
                            <Text size={TextSize['17px']} weight={Weight['w700']}>
                                <FormattedMessage {...messages.thanks} />:
                            </Text>
                        </div>
                        <div className={styles.text}>
                            <Text size={TextSize['15px']} weight={Weight['normal']}>
                                <FormattedMessage {...messages.text} />
                            </Text>
                        </div>
                    </div>
                    <div onClick={this.handleClickHide}>
                        <img className={styles.close} src={closeIcon} alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

export const DownloadPromo = connect(mapStateToProps, mapDispatchToProps)(DownloadPromoComponent);

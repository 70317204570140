import React from 'react';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';
import { getServices } from 'src/ui/Services/servicesList';
import { EColor, Service } from 'src/ui/Services/Service/Service';
import { Download } from 'src/ui/Download/Download';
import { Player } from 'src/ui/Player/Player';
import { isPhone as isPhoneSelector } from 'src/modules/media/media.selectors';
import poster from 'img/files/playerPoster.png';
import { useSelector } from 'react-redux';
import { Steps } from 'src/ui/Steps/Steps';
import { steps } from './steps';

import styles from './CloudsToConnect.css';

const messages = {
    title: (
        <>
            Какие облака можно подключить к <span className={styles.accent}>Диск-О:</span> и как это сделать
        </>
    ),
    text:
        'Сейчас к облачному агрегатору можно подключить 13 облачных дисков. Среди них есть и востребованные — Облако Mail.ru, Яндекс.Диск и Google Drive, и менее популярные в России хранилища — Box, Amazon S3 и другие.',
    connectedClouds: (
        <>
            Все подключённые к <span className={styles.disko}>Диск-О:</span> облака будут отображаться в проводнике как папки, и вы сможете
            переносить между ними файлы в один клик.
        </>
    ),
    howConnect: 'Как подключить?',
};

export const CloudsToConnect = (): JSX.Element => {
    const services = getServices();
    const isPhone = useSelector(isPhoneSelector);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <div className={styles.texts}>
                    <div className={styles.title}>
                        <Text size={TextSize['32px']} lineHeight={LineHeight['44px']} tabletSize={TextSize['44px']}>
                            {messages.title}
                        </Text>
                    </div>

                    <div className={styles.text}>
                        <Text size={TextSize['20px']} lineHeight={LineHeight['30px']} weight={Weight.w400}>
                            {messages.text}
                        </Text>
                    </div>
                </div>
                <div className={styles.services}>
                    {services.map((service) => (
                        <div className={styles.service} key={service.id}>
                            <Service {...service} color={EColor.NEAR_GHOST} />
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.howConnect}>
                <div className={styles.playerWrapper}>
                    <div className={styles.player}>
                        <Player videoUrl="https://www.youtube.com/embed/lM3RgA3zxVc" poster={poster} />
                    </div>
                    <div className={styles.playerText}>
                        <Text size={TextSize['20px']} lineHeight={LineHeight['30px']} weight={Weight.w400}>
                            {messages.connectedClouds}
                        </Text>
                    </div>
                </div>

                <div className={styles.stepsWrapper}>
                    <div className={styles.stepsTitle}>
                        <Text size={TextSize['32px']} lineHeight={LineHeight['35px']}>
                            {messages.howConnect}
                        </Text>
                    </div>

                    <div className={styles.steps}>
                        <Steps steps={steps} />
                    </div>

                    <div className={styles.download}>
                        <Download showOnTablet showOnPhone noWrap={!isPhone} />
                    </div>
                </div>
            </div>
        </div>
    );
};

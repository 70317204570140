import React, { FunctionComponent, PureComponent, ReactElement, ReactNode } from 'react';
import { LineHeight, Text, TextSize, Weight, WhiteSpace } from 'src/ui/Text/Text';
import classNames from 'clsx';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link as LinkTo } from 'react-router-dom';
import { sendGa } from 'src/utils/ga';
import styles from './Services.css';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { Service, getServices, list, ServiceListItem } from './servicesList';
import { Page, pagesList } from './pagesList';
import { EColor, Service as ServiceIcon } from './Service/Service';

const messages = defineMessages({
    title: `Поддерживает <span>{servicesCount, plural,
        one {# сервис}
        few {# сервиса}
        other {# сервисов}
        }</span>
    `,
    text: 'Подключите к вашему ПК любые из доступных сервисов в Диск‑О:',
    howToConnect: 'Как подключить?',
    whatCanItDo: 'Что может?',
});

interface Props {
    description?: ReactNode;
    className?: string;
    page?: string;
}

export class Services extends PureComponent<Props> {
    static readonly defaultProps = {
        description: <FormattedMessage {...messages.text} />,
    };

    private handleClick = (event: { currentTarget: { id: string } }): void => {
        sendGa({
            category: 'services',
            action: 'click',
            label: event?.currentTarget?.id,
        });
    };

    private renderService = (service: Service): ReactElement => {
        const lang = getCurrentLanguage();

        return (
            <LinkTo
                to={`/${lang}${service.url}`}
                className={styles.serviceItem}
                key={service.id}
                id={service.id}
                onClick={this.handleClick}
            >
                <Text size={TextSize['20px']} weight={Weight.w500} whiteSpace={WhiteSpace.nowrap}>
                    {service.name}
                </Text>
            </LinkTo>
        );
    };

    private renderPages = (page: Page): ReactElement => {
        const lang = getCurrentLanguage();
        const isActive = page.isActive();

        const Element: string | FunctionComponent<any> = isActive ? 'span' : LinkTo;

        return (
            <Element
                to={isActive ? undefined : `/${lang}${page.url}`}
                className={classNames({
                    [styles.pageItem]: true,
                    [styles[`pageItem_active`]]: isActive,
                })}
                key={page.id}
                id={page.id}
                onClick={this.handleClick}
            >
                <Text size={TextSize['20px']} weight={Weight.w500} whiteSpace={WhiteSpace.nowrap}>
                    {page.message}
                </Text>
            </Element>
        );
    };

    private renderServiceIcon = (service: ServiceListItem): ReactElement => {
        const { page } = this.props;

        return (
            <div className={styles.service} key={service.id}>
                <ServiceIcon {...service} color={page === 'main' ? EColor.NEAR_GHOST : EColor.WHITE} />
            </div>
        );
    };

    render(): ReactElement {
        const { description, page } = this.props;
        const services = getServices();

        return (
            <section
                id="about"
                className={classNames({
                    [styles.root]: true,
                    [styles[`root_page_${page}`]]: page,
                })}
            >
                <div className={styles.container}>
                    <div className={styles.texts}>
                        <h2 className={styles.title}>
                            <Text size={TextSize['32px']} tabletSize={TextSize['32px']} desktopSize={TextSize['44px']} weight={Weight.w700}>
                                <FormattedMessage
                                    {...messages.title}
                                    values={{
                                        span: (msg: string): ReactElement => <span className={styles.highlight}>{msg}</span>,
                                        servicesCount: services.length,
                                    }}
                                />
                            </Text>
                        </h2>
                        <div className={styles.description}>
                            <Text size={TextSize['20px']} weight={Weight.w500} lineHeight={LineHeight['30px']}>
                                {description}
                            </Text>
                        </div>
                    </div>
                    <div className={styles.howTo}>
                        <div className={styles.connect}>
                            <h3>
                                <Text size={TextSize['28px']} weight={Weight.w700} lineHeight={LineHeight['35px']}>
                                    <FormattedMessage {...messages.howToConnect} />
                                </Text>
                            </h3>
                            <div className={styles.services}>{list.map(this.renderService)}</div>
                        </div>
                    </div>
                    <div className={styles.list}>{services.map(this.renderServiceIcon)}</div>
                    <div className={styles.whatCanItDo}>
                        <h3>
                            <Text size={TextSize['28px']} weight={Weight.w700} lineHeight={LineHeight['35px']}>
                                <FormattedMessage {...messages.whatCanItDo} />
                            </Text>
                        </h3>
                        <div className={styles.services}>{pagesList.map(this.renderPages)}</div>
                    </div>
                </div>
            </section>
        );
    }
}

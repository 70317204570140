import { xPageId } from 'src/utils/xPageId';
import { parse } from 'qs';
import { isRussianLanguage } from 'src/modules/language/language.selectors';

interface Features {
    trialTariff: boolean;
    action: boolean;
}

interface Config {
    isProd: boolean;
    gaTrackingId: string;
    oneLinkUrl: string;
    xPageId: string;
    dmrTimeout: number;
    showAmp: boolean;
    featuresWithParameters: {
        bundle: any;
    };
    features: Features;
}

const config: Config = {
    isProd: process.env.NODE_ENV === 'production',
    gaTrackingId: 'UA-108235366-1',
    oneLinkUrl: '//1l-hit.mail.ru/v1/hit/103846.js',
    xPageId,
    dmrTimeout: 60 * 1000,
    showAmp: false,
    featuresWithParameters: {
        bundle: null,
    },
    features: {
        trialTariff: true,
        action: false,
    },
};

const BUNDLE_TARIFF_KEY = 'bundle_tariff';

// cloudweb-10995 Акция для бандлов на ЛП Диск-О (хардкод)
function getAvailableBundleTariff(val: string): string | null {
    if (val === 'f72nA20KwOihnnf') {
        return 'disko1y_discount10_rub';
    } else if (val === 'oHlAeTpRWwmtsdn') {
        return 'disko1y_discount20_rub';
    }

    return null;
}

function initConfigFeatureBundle(): void {
    let val: any = null;

    try {
        val = window.localStorage.getItem(BUNDLE_TARIFF_KEY);

        if (val && val.includes('|')) {
            const limit = 90 * 24 * 3600 * 1000; // 90 дней
            const [bundle, lifetime] = val.split('|');

            if (Date.now() - +lifetime > limit) {
                window.localStorage.removeItem(BUNDLE_TARIFF_KEY);
                return;
            }

            const bundleTariff = getAvailableBundleTariff(bundle);

            if (!bundleTariff) {
                return;
            }

            config.featuresWithParameters.bundle = bundleTariff;
            return;
        }

        const { bundle } = parse(window.location.search, { ignoreQueryPrefix: true });

        if (!bundle) {
            return;
        }

        const bundleTariff = getAvailableBundleTariff(bundle);

        if (!bundleTariff) {
            return;
        }

        config.featuresWithParameters.bundle = bundleTariff;
        window.localStorage.setItem(BUNDLE_TARIFF_KEY, bundle + '|' + Date.now());
    } catch (error) {
        console.error(error.message);
    }
}

function initConfig(): void {
    //TODO: get from JSON from rb?

    Object.keys(config.features).forEach((key) => {
        const val = localStorage.getItem(key);

        if (val) {
            config.features[key as keyof Features] = val === 'enabled';
        }
    });

    const removeAndDisableFeatureKeys: string[] = [];

    const query = parse(window.location.search, { ignoreQueryPrefix: true });
    Object.keys(query).forEach((key) => {
        if (!key) {
            return;
        }

        const rawValue = query[key];
        const val = Array.isArray(rawValue) ? rawValue[0].toLowerCase() : rawValue.toLowerCase();
        config.features[key as keyof Features] = val === 'true';

        if (val === 'enable') {
            config.features[key as keyof Features] = true;
            localStorage.setItem(key, 'enabled');
        }

        if (val === 'disable') {
            delete config.features[key as keyof Features];
            localStorage.removeItem(key);
        }
    });

    removeAndDisableFeatureKeys.forEach((key) => {
        config.features[key as keyof Features] = false;
        localStorage.removeItem(key);
    });

    // акции с bundle-ами только для ру тарифов
    if (isRussianLanguage()) {
        initConfigFeatureBundle();
    }
}

export { config, initConfig };

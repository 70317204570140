import xray from '@mail/xray';
import { config } from 'src/constants/config';
import { getCoreKeeper } from '@mail-core/dashboard';

const baseConfig = {
    xrayRadarUrl: '/xray',
    defaultParams: {
        p: 'cloud',
        // eslint-disable-next-line @typescript-eslint/camelcase
        t_feature: 'disko',
    },
    pgid: config.xPageId,
    verbose: true,
    idle: !config.isProd,
};

const initHP = (): void => {
    const coreKeeperXRay = xray.getInstanceCopy();
    // eslint-disable-next-line no-unused-expressions
    getCoreKeeper()?.setXRay(coreKeeperXRay);

    if (!coreKeeperXRay) {
        return;
    }

    coreKeeperXRay.setConfig({
        ...baseConfig,
        defaultParams: {
            p: 'disko-cloud',
            // eslint-disable-next-line @typescript-eslint/camelcase
            t_feature: '',
        },
    });

    coreKeeperXRay.send('pk-xray-ready');
};

xray.setConfig({
    ...baseConfig,
    gaTrackingId: config.gaTrackingId,
});

initHP();

export const sendRadar = (t: string | string[], ...iStrings: string[]): void => xray.send(t, { i: iStrings.join('_') });

export default xray;

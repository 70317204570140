import React, { PureComponent, ReactElement } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Container } from 'src/ui/Container/Container';

import styles from './Header.css';
import { Logo } from './Logo/Logo';
import { Colors, Link } from 'src/ui/Link/Link';
import { Text, TextSize, WhiteSpace } from 'src/ui/Text/Text';
import { ButtonSize } from '../Button/Button';
import { LanguageChoose } from 'src/ui/LanguageChoose/LanguageChoose';
import { i18n } from 'src/utils/i18n';
import { Download } from 'src/ui/Download/Download';
import { sendGa } from 'src/utils/ga';
import { getQueryString } from 'src/utils/location.helpers';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { isRussianLanguage } from 'src/modules/language/language.selectors';
import { isExactMatchPage } from 'src/utils/route.helpers';

const messages = defineMessages({
    about: 'О программе',
    howItWorks: 'Как это работает',
    tariffs: 'Тарифы',
    faq: 'Вопрос-ответ',
    downloadApp: 'Скачать для {osName}',
    logo: 'Логотип',
});

const query = getQueryString();

interface HeaderProps extends RouteComponentProps<{ lang: string }> {
    isShort?: boolean;
    whiteButton?: boolean;
    noLanguage?: boolean;
    whiteLogo?: boolean;
}

class HeaderComponent extends PureComponent<HeaderProps> {
    static defaultProps = {
        isShort: false,
        whiteButton: false,
        noLanguage: false,
        whiteLogo: false,
    };

    private handleClick = (event: any): void => {
        const hash = event.currentTarget.hash;
        const el = document.querySelector(hash);

        if (el) {
            event.preventDefault();
            this.props.history.push({
                hash,
            });
        }

        sendGa({
            category: 'navigation',
            action: 'click',
            label: event.currentTarget && event.currentTarget.hash.replace('#', ''),
        });
    };

    render(): ReactElement {
        const { match, isShort, whiteButton, noLanguage, whiteLogo } = this.props;
        const language = match.params.lang;
        const baseUrl = language ? `/${language}/` : '/';
        const isBackupsPage = isExactMatchPage('backups');
        const isRu = isRussianLanguage();
        const logoLink = isRu ? `/ru/main/${query}` : `${baseUrl}${query}`;

        return (
            <div className={styles.root}>
                <Container>
                    <div className={styles.content}>
                        <Link to={logoLink}>
                            <Logo alt={i18n.formatMessage(messages.logo)} whiteLogo={whiteLogo} flexible={!isShort} />
                        </Link>
                        {!isShort && (
                            <ul className={styles.menu}>
                                <li className={styles.menuItem}>
                                    <Link to={`${baseUrl}${query}#about`} color={Colors.black} onClick={this.handleClick} noPrevent>
                                        <Text size={TextSize['17px']} mediumDesktopSize={TextSize['20px']} whiteSpace={WhiteSpace.nowrap}>
                                            <FormattedMessage {...messages.about} />
                                        </Text>
                                    </Link>
                                </li>
                                <li className={styles.menuItem}>
                                    <Link to={`${baseUrl}${query}#how-it-works`} color={Colors.black} onClick={this.handleClick} noPrevent>
                                        <Text size={TextSize['17px']} mediumDesktopSize={TextSize['20px']} whiteSpace={WhiteSpace.nowrap}>
                                            <FormattedMessage {...messages.howItWorks} />
                                        </Text>
                                    </Link>
                                </li>
                                {!isBackupsPage && (
                                    <li className={styles.menuItem}>
                                        <Link to={`${baseUrl}${query}#tariffs`} color={Colors.black} onClick={this.handleClick} noPrevent>
                                            <Text
                                                size={TextSize['17px']}
                                                mediumDesktopSize={TextSize['20px']}
                                                whiteSpace={WhiteSpace.nowrap}
                                            >
                                                <FormattedMessage {...messages.tariffs} />
                                            </Text>
                                        </Link>
                                    </li>
                                )}
                                <li className={styles.menuItem}>
                                    <Link to={`${baseUrl}${query}#faq`} color={Colors.black} onClick={this.handleClick} noPrevent>
                                        <Text size={TextSize['17px']} mediumDesktopSize={TextSize['20px']} whiteSpace={WhiteSpace.nowrap}>
                                            <FormattedMessage {...messages.faq} />
                                        </Text>
                                    </Link>
                                </li>
                            </ul>
                        )}
                        <div className={styles.right}>
                            <Download
                                buttonSize={ButtonSize.M}
                                showOnlyOne
                                showIcon={!whiteButton}
                                isPrimary={!whiteButton}
                                showOnTablet={isShort}
                            />
                            {!noLanguage && (
                                <div className={styles.language}>
                                    <LanguageChoose />
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export const Header = withRouter(HeaderComponent);

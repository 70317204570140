import React from 'react';
import { useSelector } from 'react-redux';
import { ELableColor, Steps } from 'src/ui/Steps/Steps';
import { TextSize, Text, LineHeight, Weight, WhiteSpace } from 'src/ui/Text/Text';
import { isPhone as isPhoneSelector } from 'src/modules/media/media.selectors';

import styles from './HowDiskoWorks.css';

const steps = [
    { step: 1, text: 'Кликните по нужному файлу правой кнопкой' },
    { step: 2, text: 'Нажмите «Сделать доступным офлайн»' },
];

const messages = {
    howDiskoWorks: (
        <>
            Как работает <span className={styles.disko}>Диск-О:?</span>
        </>
    ),
    description: (
        <>
            Вы подключаете к программе все используемые облака и работаете с ними, как с обычными папками в проводнике. При этом открывать
            браузер или скачивать отдельные приложения Яндекс и Гугл Диска необязательно — достаточно приложения{' '}
            <span className={styles.disko}>Диск-О:</span>
        </>
    ),
    workOffline: 'Работа с файлами офлайн',
    workWithFiles: (
        <>
            В <span className={styles.disko}>Диск-О:</span> можно работать с файлами без подключения к сети. Для этого:
        </>
    ),
};

export const HowDiskoWorks = (): JSX.Element => {
    const isPhone = useSelector(isPhoneSelector);

    return (
        <div className={styles.root}>
            <div className={styles.howWorks}>
                <div className={styles.title}>
                    <Text size={TextSize['32px']} lineHeight={LineHeight['44px']} desktopSize={TextSize['44px']}>
                        {messages.howDiskoWorks}
                    </Text>
                </div>

                <div>
                    <Text size={TextSize['20px']} lineHeight={LineHeight['30px']} weight={Weight.w400}>
                        {messages.description}
                    </Text>
                </div>
            </div>

            <div className={styles.workWithFile}>
                <div className={styles.title}>
                    <Text
                        size={TextSize['32px']}
                        lineHeight={LineHeight['44px']}
                        desktopSize={TextSize['44px']}
                        whiteSpace={isPhone ? undefined : WhiteSpace.nowrap}
                    >
                        {messages.workOffline}
                    </Text>
                </div>

                <div className={styles.text}>
                    <Text size={TextSize['20px']} lineHeight={LineHeight['30px']} weight={Weight.w400}>
                        {messages.workWithFiles}
                    </Text>
                </div>

                <div className={styles.steps}>
                    <Steps steps={steps} lableColor={ELableColor.GRAY} />
                </div>
            </div>
        </div>
    );
};

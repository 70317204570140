const parsePeriod = (period: string): any => period.match(/(^\d*)+(\D)$/);

export const isYearPeriod = (period: string): boolean => /\d+y$/.test(period);
export const isMonthPeriod = (period: string): boolean => /\d+m$/.test(period);
export const isDayPeriod = (period: string): boolean => /\d+d$/.test(period);
export const getMonths = (period: string): number => {
    const periodMatches = parsePeriod(period);
    if (!periodMatches) {
        return 0;
    }

    let count = Number(periodMatches[1]);
    const name = periodMatches[2];

    if (name === 'y') {
        count *= 12;
    } else if (name !== 'm') {
        return 0;
    }

    return count;
};

export const secondsInADay = 86400;

import React, { ReactNode, useCallback, useEffect, memo } from 'react';
import classNames from 'clsx';
import { Modal as SemanticModal } from 'semantic-ui-react';
import 'semantic-ui-css/components/dimmer.css';
import 'semantic-ui-css/components/modal.css';
import styles from './Modal.css';
import { ReactComponent as CloseIcon } from 'img/icons/close.svg';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

interface Props {
    header?: ReactNode;
    children: ReactNode;
    isOpen?: boolean;
    onClose: Function;
    fullScreen?: boolean;
    mod?: string | null;
}

export const Modal = memo(({ header, children, onClose, fullScreen, mod }: Props) => {
    const handleOnClose = useCallback(() => {
        onClose();
    }, [onClose]);

    let size: 'tiny' | 'fullscreen' | 'small' = 'small';

    if (fullScreen) {
        size = 'fullscreen';
    } else if (mod === 'tiny') {
        size = 'tiny';
    }

    useEffect(() => {
        const el = document.querySelector('.ui.page.modals');

        if (el) {
            disableBodyScroll(el);
        }

        return () => {
            if (el) {
                enableBodyScroll(el);
            }
        };
    }, []);

    return (
        <SemanticModal
            open
            className={classNames({
                [styles.root]: true,
                [styles.root_fullscreen]: fullScreen,
                [styles[`root_${mod}`]]: !!mod,
            })}
            onClose={handleOnClose}
            size={size}
            centered
        >
            {header && (
                <div className={styles.header}>
                    <Text size={TextSize['24px']} weight={Weight.w700}>
                        {header}
                    </Text>
                </div>
            )}
            <div onClick={handleOnClose} className={styles.close}>
                <CloseIcon width={11} height={11} />
            </div>
            {children}
        </SemanticModal>
    );
});

Modal.displayName = 'Modal';

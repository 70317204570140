export enum BuyGetLinkActionTypes {
    REQUEST = '@buy/getLink/request',
    SUCCESS = '@buy/getLink/success',
    FAILURE = '@buy/getLink/failure',
    RESET = '@buy/getLink/reset',
}

export interface GetLinkRequestAction {
    type: typeof BuyGetLinkActionTypes.REQUEST;
}

export interface GetLinkSuccessAction {
    type: typeof BuyGetLinkActionTypes.SUCCESS;
    link: string;
    token: string;
}

export interface GetLinkFailureAction {
    type: typeof BuyGetLinkActionTypes.FAILURE;
}

export interface GetLinkResetLinkAction {
    type: typeof BuyGetLinkActionTypes.RESET;
}

export interface BuyState {
    isLoading: boolean;
    hasError: boolean;
    link: string;
    token: string;
}

export type BuyActionsType = GetLinkSuccessAction | GetLinkFailureAction | GetLinkRequestAction | GetLinkResetLinkAction;

import React from 'react';

import styles from './CloudsToConnect.css';

export const steps = [
    {
        step: 1,
        text: (
            <>
                Установите <span className={styles.disko}>Диск-О:</span> на компьютер.
            </>
        ),
    },
    {
        step: 2,
        text: <>Выберите облако и нажмите «Добавить».</>,
    },
    {
        step: 3,
        text: (
            <>
                Авторизуйтесь и выдайте <span className={styles.disko}>Диск-О:</span> разрешение на работу с хранящимися данными.
            </>
        ),
    },
    {
        step: 4,
        text: <>Повторите для всех облачных дисков, которые хотите подключить.</>,
    },
];

import React, { PureComponent, ReactElement } from 'react';
import classNames from 'clsx';
import styles from './Tariff.css';
import { FormattedMessage } from 'react-intl';
import { Text, TextSize, Weight, WhiteSpace } from 'src/ui/Text/Text';
import { isMonthPeriod, isYearPeriod } from 'src/utils/period';
import { NBSP } from 'src/constants/typography';

import { getServices } from 'src/ui/Services/servicesList';
import { Currency, CURRENCY_ROUND, CURRENCY_SYMBOL, TariffMods } from 'src/modules/tariffs/tariffs.types';
import { Button, ButtonColors, ButtonRadius, ButtonSize } from 'src/ui/Button/Button';
import { sendGa } from 'src/utils/ga';
import { Nowrap } from 'src/ui/Nowrap/Nowrap';
import { formatPrice, getMonthlyPrice } from 'src/utils/price.helpers';
import { TariffFeature } from 'src/ui/Tariff/TariffFeature';
import { isBundle } from 'src/utils/isBundle';
import { ReactComponent as CloudDiskIcon } from 'img/icons/cloudDisk.svg';
import cloudLogo from 'img/cloudLogo.png';
import { messages, MESSAGES_BY_LANG } from 'src/ui/Tariff/Tariff.helper';
import { isRussianLanguage } from 'src/modules/language/language.selectors';
import { AvailableLanguages } from 'src/modules/language/language.types';

interface Props {
    isTrial?: boolean;
    isPrimary?: boolean;
    isCompact?: boolean;
    isMobile?: boolean;
    isUnifiedYear?: boolean;
    cloudGift?: string;
    price: number;
    oldPrice?: number;
    currency: Currency;
    id: string;
    period: string;
    trialRenewablePeriod?: string;
    onBuy: (id: Props['id']) => void;
    mod?: TariffMods | null;
}

export class Tariff extends PureComponent<Props> {
    componentDidMount() {
        const { isTrial, mod, price } = this.props;

        if (isTrial) {
            sendGa({
                category: 'tariff',
                action: 'show',
                label: price ? 'trial' : 'test',
            });
        }

        if (isBundle(mod)) {
            sendGa({
                category: 'tariff',
                action: 'show',
                label: String(mod),
            });
        }
    }

    handleOnButtonClick = (): void => {
        const { id, onBuy } = this.props;

        sendGa({
            category: 'payment',
            action: 'click',
            label: id,
        });

        if (this.props.isTrial) {
            sendGa({
                category: 'tariff',
                action: 'click',
                label: this.props.price ? 'trial' : 'test',
            });
        }

        onBuy(id);
    };

    renderTitle = (): ReactElement => {
        const { isTrial, isUnifiedYear, period, cloudGift } = this.props;

        if (isTrial) {
            return (
                <FormattedMessage
                    {...(isMonthPeriod(period) ? messages.trialTitleMonth : messages.trialTitleDay)}
                    values={{
                        period: parseInt(period),
                        nbsp: NBSP,
                    }}
                />
            );
        }

        if (isUnifiedYear) {
            return <FormattedMessage {...messages.titleUnifiedYear} values={{ nowrap: Nowrap }} />;
        }

        if (cloudGift) {
            return (
                <FormattedMessage
                    {...messages.cloudTitle}
                    values={{
                        space: 32, // todo: remove and sync with api
                        nbsp: NBSP,
                    }}
                />
            );
        }

        return (
            <FormattedMessage
                {...(isYearPeriod(period) ? messages.titleYear : messages.titleMonth)}
                values={{
                    period: parseInt(period),
                    nbsp: NBSP,
                }}
            />
        );
    };

    getBtnColor = () => {
        return this.props.isPrimary ? ButtonColors.PRIMARY : ButtonColors.BASE;
    };

    getPeriodText = () => {
        const { isTrial, period } = this.props;

        if (isTrial) {
            return isMonthPeriod(period) ? messages.trialPeriodMonth : messages.trialPeriodDay;
        }

        return isMonthPeriod(period) ? messages.paymentPeriodMonth : messages.paymentPeriodYear;
    };

    renderCustomGift = () => {
        const { mod, isPrimary } = this.props;
        const showGift = isPrimary && mod === TariffMods.action;

        return (
            showGift && (
                <div className={styles.actionBlock}>
                    <div className={styles.content}>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage
                                {...messages.actionText}
                                values={{
                                    br: <br />,
                                }}
                            />
                        </Text>
                    </div>
                </div>
            )
        );
    };

    renderTariffFeature = () => {
        const { mod, isCompact, isMobile, isUnifiedYear, period } = this.props;
        const features = [];
        features.push(
            { id: 1, name: messages.unlimUpload, isCompact: true },
            { id: 2, name: messages.devices, isCommonOnly: true, values: { devicesCount: 3 } },
            { id: 3, name: messages.support },
            { id: 4, name: messages.ssl, isCommonOnly: true },
            { id: 5, name: messages.uploadFiles, isCompact: true, isUnifiedOnly: true },
            { id: 6, name: messages.fileHistory, isUnifiedOnly: true },
            {
                id: 7,
                name: this.getPeriodText(),
                isCompact: true,
                isCommonOnly: true,
                values: { period: parseInt(period), count: 1 },
            },
            { id: 8, name: messages.backupsToClouds, isCompact: true }
        );

        return (
            <div className={styles.features}>
                {features.map((feature) => {
                    if (
                        (isCompact && !feature.isCompact) ||
                        (isUnifiedYear && feature.isCommonOnly) ||
                        (!isUnifiedYear && feature.isUnifiedOnly)
                    ) {
                        return null;
                    }

                    return (
                        <TariffFeature key={feature.id} mod={mod}>
                            <Text size={TextSize['20px']} weight={isMobile ? Weight.w500 : Weight.w600}>
                                <FormattedMessage {...feature.name} values={feature.values} />
                            </Text>
                        </TariffFeature>
                    );
                })}
            </div>
        );
    };

    renderBundle = (mod: TariffMods | null | undefined, isCompact: boolean | undefined) => {
        return (
            (mod === TariffMods.bundle10 || mod === TariffMods.bundle20) && (
                <div
                    className={classNames({
                        [styles.actionBlock]: true,
                        [styles.actionBlockNotCompact]: !isCompact,
                    })}
                >
                    <div
                        className={classNames({
                            [styles.numbers10]: mod === TariffMods.bundle10,
                            [styles.numbers20]: mod === TariffMods.bundle20,
                        })}
                    />
                    <div className={styles.content}>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.personalDiscount} />
                        </Text>
                    </div>
                </div>
            )
        );
    };

    getMod = (mod: TariffMods | null | undefined): string | TariffMods | null | undefined => {
        return isBundle(mod) ? 'bundle' : mod;
    };

    renderPriceDescription = ({ priceTotal }: { priceTotal: string }): ReactElement | null => {
        const { isTrial, isUnifiedYear, period, cloudGift, trialRenewablePeriod } = this.props;

        if (cloudGift) {
            return (
                <div className={styles.priceDescription}>
                    <Text size={TextSize['13px']} weight={Weight.w500}>
                        <FormattedMessage
                            {...messages.priceDescriptionCloud}
                            values={{
                                price: priceTotal,
                                period: parseInt(period),
                                nowrap: Nowrap,
                            }}
                        />
                    </Text>
                </div>
            );
        }

        if (isTrial && priceTotal && trialRenewablePeriod) {
            return (
                <div className={styles.priceDescription}>
                    <Text size={TextSize['13px']} weight={Weight.w500}>
                        <FormattedMessage
                            {...(isYearPeriod(trialRenewablePeriod) ? messages.trialDescriptionYear : messages.trialDescription)}
                            values={{
                                price: priceTotal,
                                nowrap: Nowrap,
                                period: parseInt(trialRenewablePeriod),
                            }}
                        />
                    </Text>
                </div>
            );
        }

        if (!isTrial) {
            const messageTariffs = isMonthPeriod(period) ? messages.priceDescriptionMonth : messages.priceDescriptionYear;
            const message = isUnifiedYear ? messages.priceDescriptionUnified : messageTariffs;

            return (
                <div className={styles.priceDescription}>
                    <Text size={TextSize['13px']} weight={Weight.w500}>
                        <FormattedMessage
                            {...message}
                            values={{
                                price: priceTotal,
                                period: parseInt(period),
                                nowrap: Nowrap,
                            }}
                        />
                    </Text>
                </div>
            );
        }

        return null;
    };

    renderRootHeader = (): ReactElement => {
        const { mod, cloudGift, isCompact } = this.props;

        return (
            <div
                className={classNames({
                    [styles.root_header]: true,
                    [styles[`root_header_${mod}`]]: !!mod,
                })}
            >
                {!!cloudGift && !isCompact && (
                    <>
                        <CloudDiskIcon width={44} height={72} />
                        <Text size={TextSize['32px']} weight={Weight.w700} whiteSpace={WhiteSpace.nowrap}>
                            <FormattedMessage
                                {...messages.cloudTariffTitle}
                                values={{
                                    space: parseInt(cloudGift),
                                    nbsp: NBSP,
                                }}
                            />
                        </Text>
                        <div className={styles.imageWrapper}>
                            <img src={cloudLogo} className={styles.image} alt="" />
                        </div>
                    </>
                )}
            </div>
        );
    };

    get customMessages() {
        return isRussianLanguage() ? MESSAGES_BY_LANG[AvailableLanguages.ru] : MESSAGES_BY_LANG[AvailableLanguages.en];
    }

    render(): ReactElement {
        const { isPrimary, isTrial = false, period, price, currency, isCompact, mod, oldPrice, cloudGift, isMobile = false } = this.props;
        const precision = CURRENCY_ROUND[currency];
        const monthPrice = isTrial ? '0' : getMonthlyPrice(price, period, precision);
        const monthOldPrice = getMonthlyPrice(oldPrice, period, precision);
        const priceTotal = formatPrice(price, precision);
        const showDiscount = mod === TariffMods.action;
        const btnColor = this.getBtnColor();

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles[`root_${this.getMod(mod)}`]]: !!mod,
                    [styles.root_compact]: isCompact,
                    [styles.root_primary]: isPrimary,
                    [styles.root_cloud]: !!cloudGift,
                    [styles.root_default]: isTrial,
                    [styles.root_mobile]: isMobile,
                })}
            >
                {!showDiscount && this.renderRootHeader()}
                <div className={styles.header}>
                    <div
                        className={classNames({
                            [styles.title]: true,
                            [styles[`title_${mod}`]]: !!mod,
                        })}
                    >
                        <div
                            className={classNames({
                                [styles.label]: true,
                                [styles[`label_${mod}`]]: !!mod,
                            })}
                        >
                            <Text size={TextSize['11px']} weight={Weight.w700}>
                                {showDiscount ? this.customMessages.labelDiscount : <FormattedMessage {...messages.label} />}
                            </Text>
                        </div>
                        {isMobile && (
                            <div className={classNames(styles.label, styles.label_orange)}>
                                <Text size={TextSize['11px']} weight={Weight.w700}>
                                    <FormattedMessage {...messages.label} />
                                </Text>
                            </div>
                        )}
                        <Text weight={Weight.w700} size={TextSize[isMobile ? '24px' : '28px']}>
                            {this.renderTitle()}
                        </Text>
                    </div>
                </div>
                <div className={styles.body}>
                    {this.renderTariffFeature()}
                    {this.renderCustomGift()}
                    {!isCompact && (
                        <div className={styles.services}>
                            {getServices().map((service) => (
                                <div
                                    className={classNames({
                                        [styles.service]: true,
                                        [styles[`service_${mod}`]]: !!mod,
                                    })}
                                    key={service.id}
                                >
                                    <img className={styles[service.id]} src={service.logo} alt={service.name} />
                                </div>
                            ))}
                        </div>
                    )}
                    {this.renderBundle(mod, isCompact)}
                    <div
                        className={classNames({
                            [styles.price]: true,
                            [styles[`price_${mod}`]]: !!mod,
                        })}
                    >
                        {!!monthOldPrice && (
                            <Text size={TextSize['44px']} weight={Weight.w500}>
                                <span
                                    className={classNames({
                                        [styles.priceOld]: true,
                                        [styles[`priceOld_${mod}`]]: !!mod,
                                    })}
                                >
                                    {monthOldPrice} {CURRENCY_SYMBOL[currency]}
                                </span>
                            </Text>
                        )}
                        <Text size={TextSize['44px']} weight={Weight.w700}>
                            <FormattedMessage
                                {...(isTrial ? messages.trialPrice : messages.price)}
                                values={{
                                    price: monthPrice,
                                    currency: CURRENCY_SYMBOL[currency],
                                    span: (msg: string): ReactElement => (
                                        <span
                                            className={classNames({
                                                [styles.period]: true,
                                                [styles[`period_${mod}`]]: !!mod,
                                            })}
                                        >
                                            <Text size={TextSize['20px']} weight={Weight.w600}>
                                                {msg}
                                            </Text>
                                        </span>
                                    ),
                                }}
                            />
                        </Text>
                    </div>
                    {this.renderPriceDescription({ priceTotal })}
                </div>
                <div className={styles.button}>
                    <Button
                        fluid
                        color={btnColor}
                        size={isMobile ? ButtonSize.S : ButtonSize.L}
                        onClick={this.handleOnButtonClick}
                        radius={isMobile ? ButtonRadius.r8 : ButtonRadius.r12}
                    >
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...(isTrial ? messages.buttonTextTrial : messages.buttonText)} />
                        </Text>
                    </Button>
                </div>
            </div>
        );
    }
}

import React, { PureComponent, ReactElement, Fragment } from 'react';
import ReactGA from 'react-ga';
import { coreKeeperAppReady } from '@mail-core/dashboard';
import { config, initConfig } from 'src/constants/config';
import { sendGa } from 'src/utils/ga';
import { Home } from '../Home/Home';
import { Unbind } from 'src/ui/Unbind/Unbind';
import { DownloadPromo } from 'src/ui/DownloadPromo/DownloadPromo';
import { Cookie } from 'src/ui/Cookie/Cookie';
import { HelpPage } from 'src/ui/HelpPage/HelpPage';
import { SupportPage } from 'src/ui/SupportPage/SupportPage';
import Helmet from 'react-helmet';
import styles from './App.css';
import { Header } from 'src/ui/Header/Header';
import { Meta } from 'src/ui/Meta/Meta';
import { Footer } from 'src/ui/Footer/Footer';
import { ServicePage } from 'src/ui/ServicePage/ServicePage';
import { TopBanner } from 'src/ui/TopBanner/TopBanner';
import { getCurrentLanguage, isRussianLanguage } from 'src/modules/language/language.selectors';
import { isSSR } from 'src/utils/ua-parser';
import { initApp } from 'src/utils/app';
import { getQueryString } from 'src/utils/location.helpers';
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { getQueryIsWebview, isExactMatchPage } from 'src/utils/route.helpers';
import { Tariffs } from 'src/ui/Tariffs/Tariffs';
import { MainPage } from 'src/ui/Main/Main';
import { BackupsPage } from 'src/ui/BackupsPage/BackupsPage';
import { FilesPage } from 'src/ui/FilesPage/FilesPage';
import { countInvisibleTariffs } from 'src/modules/tariffs/tariffs.actions';
import { DocsPage } from 'src/ui/DocsPage/DocsPage';
import { connect } from 'react-redux';
import { getOmicronConfig } from 'src/modules/omicron/omicron.actions';

if (!isSSR) {
    initApp();
    initConfig();
    ReactGA.initialize(config.gaTrackingId);
    const { location } = window;
    ReactGA.pageview(location.pathname + location.search);
}

interface MapState {
    getOmicronConfig: () => void;
}

const isWebview = getQueryIsWebview();

const mapDispatchToProps = {
    getOmicronConfig,
};

class AppComponent extends PureComponent<{} & MapState & RouteComponentProps> {
    componentDidMount(): void {
        this.props.getOmicronConfig();

        sendGa({
            category: 'ab',
            action: 'new',
        });

        sendGa({
            category: 'landing',
            action: 'view',
        });

        countInvisibleTariffs();

        coreKeeperAppReady();
    }

    componentDidUpdate(nextProps: RouteComponentProps): void {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    renderRoute = (): ReactElement => {
        const {
            match: { path },
        } = this.props;

        return (
            <Switch>
                <Route path={`${path}`} exact>
                    <Helmet>
                        <body className={styles.home} />
                    </Helmet>
                    <Home />
                </Route>
                <Route path={`${path}/faq/:section`}>
                    <Helmet>
                        <body className={styles.faq} />
                    </Helmet>
                    <HelpPage />
                </Route>
                <Redirect from={`${path}/faq/`} to={`${path}/faq/about/`} exact />
                <Redirect from={`${path}/service/yandex/`} to={`${path}/files/`} exact />
                <Route path={`${path}/service/`}>
                    <Helmet>
                        <body className={styles.service} />
                    </Helmet>
                    <ServicePage />
                </Route>
                <Route path={`${path}/backups/`}>
                    <Helmet>
                        <body className={styles.backups} />
                    </Helmet>
                    <BackupsPage />
                </Route>
                <Route path={`${path}/files/`}>
                    <Helmet>
                        <body className={styles.files} />
                    </Helmet>
                    <FilesPage />
                </Route>
                <Route path={`${path}/support`}>
                    <Helmet>
                        <body className={styles.support} />
                    </Helmet>
                    <SupportPage />
                </Route>
                <Route path={`${path}/main/`}>
                    <Helmet>
                        <body className={styles.main} />
                    </Helmet>
                    <MainPage />
                </Route>
                <Route path={`${path}/docs`} exact>
                    <DocsPage />
                </Route>
            </Switch>
        );
    };

    renderScripts = (): ReactElement[] | null => {
        if (isSSR) {
            return null;
        }

        return [
            // <!-- tns-counter.ru -->
            <script type="text/javascript" key="tns">
                {`(function(win, doc, cb) {
                    (win[cb] = win[cb] || []).push(function() {
                        try {
                            tnsCounterMail_ru = new TNS.TnsCounter({
                                'account': 'mail_ru',
                                'tmsec': 'diskocloud_total',
                            });
                        } catch (e) {
                        }
                    });

                    var tnsscript = doc.createElement('script');
                    tnsscript.type = 'text/javascript';
                    tnsscript.async = true;
                    tnsscript.src = ('https:' == doc.location.protocol ? 'https:' : 'http:') +
                        '//www.tns-counter.ru/tcounter.js';
                    var s = doc.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(tnsscript, s);
                })(window, this.document, 'tnscounter_callback');`}
            </script>,
            <noscript key="tnsNoScript">
                {`<img src="//www.tns-counter.ru/V13a****mail_ru/ru/UTF-8/tmsec=diskocloud_total/" width="0" height="0"
                      alt=""/>`}
            </noscript>,
            // <!--/ tns-counter.ru -->
            <script type="text/javascript" key="topMail">
                {`
                var _tmr = window._tmr || (window._tmr = []);
                _tmr.push({id: "3181042", type: "pageView", start: (new Date()).getTime()});
                (function (d, w, id) {
                if (d.getElementById(id)) return;
                var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                ts.src = "https://top-fwz1.mail.ru/js/code.js";
                var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
            })(document, window, "topmailru-code");
            `}
            </script>,
            <noscript key="topMailNoScript">{`
            <div>
                <img
                    src="https://top-fwz1.mail.ru/counter?id=3181042;js=na"
                    style={{
                        border: 0,
                        position: 'absolute',
                        left: '-9999px',
                    }}
                    alt="Top.Mail.Ru"
                />
            </div>
            `}</noscript>,
            <script type="text/javascript" key="oneLink">{`
                (function () {
                    var partnerScript = document.createElement('script');
                    partnerScript.type = 'text/javascript';
                    partnerScript.async = true;
                    partnerScript.src = '${config.oneLinkUrl}?r=' + encodeURIComponent(document.referrer) + '&rnd=' + Math.random();
                    var firstScript = document.getElementsByTagName('script')[0];
                    firstScript.parentNode.insertBefore(partnerScript, firstScript);
                    partnerScript.onload = function(){
                        window.postMessage('updateDownloadLinks');
                    };
                })();
            `}</script>,
        ];
    };

    renderWebview = () => {
        return (
            <Fragment>
                <Helmet>{this.renderScripts()}</Helmet>
                <Tariffs />
            </Fragment>
        );
    };

    render(): ReactElement {
        if (isWebview) {
            return this.renderWebview();
        }

        const noHeader = isExactMatchPage('docs');
        const noFooter = isExactMatchPage('docs');

        return (
            <Fragment>
                <Helmet>{this.renderScripts()}</Helmet>
                <Cookie />
                <TopBanner clickUrl={`/${isRussianLanguage() ? '' : getCurrentLanguage()}${getQueryString()}#tariffs`} />
                <Meta />
                <Unbind />
                <DownloadPromo />
                {!noHeader && (
                    <div className={styles.header}>
                        <Header />
                    </div>
                )}
                {this.renderRoute()}
                {!noFooter && (
                    <div className={styles.footer}>
                        <Footer />
                    </div>
                )}
            </Fragment>
        );
    }
}

export const App = connect(null, mapDispatchToProps)(withRouter(AppComponent));

import React, { ReactElement, ReactNode } from 'react';

import styles from './Tabs.css';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';

export const TabTitle = ({ children }: { children: ReactNode }): ReactElement => (
    <h1 className={styles.title}>
        <Text size={TextSize['32px']} weight={Weight.w700}>
            {children}
        </Text>
    </h1>
);

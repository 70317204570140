import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { render } from 'react-dom';
import { parse } from 'qs';
import React from 'react';
import './index.css';
import { ReduxContainer } from 'src/ui/ReduxContainer/ReduxContainer';
import { init } from 'src/modules/media/media.actions';
import { setupDebugFeatures } from 'src/utils/features.helpers';
import { store } from 'src/store';
import { showUnbindModal } from 'src/modules/unbind/unbind.actions';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/polyfill-locales';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill-locales';

setupDebugFeatures();

const rootElement = document.querySelector('#root') || document.body;

init();

const { unbind } = parse(window.location.hash.replace('#', ''), {});

if (unbind) {
    store.dispatch(showUnbindModal(unbind));
}

/**
 * TODO: можно вернуть hydrate если отдавать разный html для Mac/Win,
 * иначе получаем под маком ссылку на .exe и иконку для винды
 */
// if (rootElement.hasChildNodes()) {
//     hydrate(<ReduxContainer />, rootElement);
// } else {
//     render(<ReduxContainer />, rootElement);
// }
render(<ReduxContainer />, rootElement);

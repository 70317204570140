import React, { PureComponent, ReactElement } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Container } from 'src/ui/Container/Container';
import { Text, TextSize } from 'src/ui/Text/Text';
import x2Icon from 'img/icons/x2.svg';
import offlineIcon from 'img/icons/offline.svg';
import copyIcon from 'img/icons/copy.svg';
import diskoIcon from 'img/icons/disko-icon.svg';

import styles from './ServiceFeatures.css';
import { AppState } from 'src/store';
import { isPhone } from 'src/modules/media/media.selectors';
import { connect } from 'react-redux';
import { Carousel } from 'src/ui/Carousel/Carousel';

const messages = defineMessages({
    faster: 'С Диск-О: все действия с файлами из хранилища будут занимать в 2 раза меньше времени, чем в браузере',
    offline: 'Вы сможете работать с файлами офлайн, а при подключении к интернету они будут автоматически обновляться',
});

interface Props {
    syncText: ReactElement;
    installText: ReactElement;
    fileActionsText?: ReactElement;
    offlineText?: ReactElement;
}

interface MapState {
    isPhone: boolean;
}

const mapStateToProps = (state: AppState): MapState => ({
    isPhone: isPhone(state),
});

class ServiceFeaturesComponent extends PureComponent<Props & MapState> {
    private renderPhone = () => {
        const { syncText, installText, offlineText, fileActionsText } = this.props;

        return (
            <div className={styles.root}>
                <Container>
                    <Carousel>
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <img src={x2Icon} width={42} height={28} alt="" />
                            </div>
                            <div className={styles.itemText}>
                                <Text size={TextSize['20px']}>{fileActionsText || <FormattedMessage {...messages.faster} />}</Text>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <img src={offlineIcon} width={36} height={29} alt="" />
                            </div>
                            <div className={styles.itemText}>
                                <Text size={TextSize['20px']}>{offlineText || <FormattedMessage {...messages.offline} />}</Text>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <img src={copyIcon} width={36} height={36} alt="" />
                            </div>
                            <div className={styles.itemText}>
                                <Text size={TextSize['20px']}>{syncText}</Text>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <img src={diskoIcon} width={44} height={32} alt="" />
                            </div>
                            <div className={styles.itemText}>
                                <Text size={TextSize['20px']}>{installText}</Text>
                            </div>
                        </div>
                    </Carousel>
                </Container>
            </div>
        );
    };

    render(): ReactElement {
        const { syncText, installText, fileActionsText, offlineText, isPhone } = this.props;

        if (isPhone) {
            return this.renderPhone();
        }

        return (
            <div className={styles.root}>
                <Container>
                    <div className={styles.row}>
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <img src={x2Icon} width={42} height={28} alt="" />
                            </div>
                            <div className={styles.itemText}>
                                <Text size={TextSize['20px']}>{fileActionsText || <FormattedMessage {...messages.faster} />}</Text>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <img src={offlineIcon} width={36} height={29} alt="" />
                            </div>
                            <div className={styles.itemText}>
                                <Text size={TextSize['20px']}>{offlineText || <FormattedMessage {...messages.offline} />}</Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <img src={copyIcon} width={36} height={36} alt="" />
                            </div>
                            <div className={styles.itemText}>
                                <Text size={TextSize['20px']}>{syncText}</Text>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <img src={diskoIcon} width={44} height={32} alt="" />
                            </div>
                            <div className={styles.itemText}>
                                <Text size={TextSize['20px']}>{installText}</Text>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export const ServiceFeatures = connect(mapStateToProps)(ServiceFeaturesComponent);

import React, { PureComponent, ReactElement } from 'react';
import styles from './Tariff.css';
import { Currency, CURRENCY_ROUND, CURRENCY_SYMBOL } from 'src/modules/tariffs/tariffs.types';
import { Button, ButtonColors, ButtonSize } from 'src/ui/Button/Button';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { defineMessages, FormattedMessage } from 'react-intl';
import { TariffFeature } from 'src/ui/Tariff/TariffFeature';
import classNames from 'clsx';
import { debounce } from 'lodash/fp';
import { noop } from 'src/utils/helpers';
import { getServices } from 'src/ui/Services/servicesList';
import { formatPrice } from 'src/utils/price.helpers';

const messages = defineMessages({
    label: 'Выгодно',
    forBusiness: 'Для бизнеса',
    deviceNumber: 'Количество лицензий',
    bttnTitle: 'Подключить',
    unlimUpload: 'Снятие ограничений на загрузку файлов',
    devices: `От 10 и более устройств`,
    support: 'Круглосуточная поддержка',
    ssl: 'Защита данных по SSL',
    requestForPrice: 'Стоимость по запросу',
    price: '{price} {currency} <span>/ год</span>',
    backupsToClouds: 'Резервные копии в любые облака',
});

interface Props {
    price: number;
    currency: Currency;
    onBuy: (counter: number) => void;
}

interface State {
    itemsCount: number;
}

export class TariffCorp extends PureComponent<Props, State> {
    public state = {
        itemsCount: 10,
    };

    inputRef = React.createRef<HTMLInputElement>();

    private handleOnBuy = (): void => {
        const { onBuy = noop } = this.props;

        onBuy(this.state.itemsCount);
    };

    private handleDecrease = (): void => this.addToCounter(-1);

    private handleIncrease = (): void => this.addToCounter(1);

    private handleChangeCount = debounce(1000, (): any => {
        if (!this.inputRef.current) {
            return;
        }
        this.addToCounter(Math.round(this.inputRef.current.valueAsNumber) - this.state.itemsCount);
    });

    private addToCounter = (delta: number): void => {
        const { itemsCount } = this.state;

        if (isNaN(delta) || itemsCount + delta < 10) {
            delta = 10 - itemsCount;
        }

        this.setState({ itemsCount: itemsCount + delta }, () => {
            if (this.inputRef.current) {
                this.inputRef.current.value = this.state.itemsCount.toString();
            }
        });
    };

    private handleOnKeypress = (event: any): void => {
        if (event.key === '.' || event.key === ',' || event.key === '-') {
            event.preventDefault();
        }
    };

    private renderCounter(itemsCount: number): ReactElement {
        return (
            <div className={styles.counter}>
                <div className={styles.counterBttn} onClick={this.handleDecrease}>
                    -
                </div>
                <input
                    type="number"
                    step="1"
                    min="10"
                    pattern="\d+"
                    ref={this.inputRef}
                    className={styles.counterValue}
                    defaultValue={itemsCount}
                    onChange={this.handleChangeCount}
                    onKeyPress={this.handleOnKeypress}
                />
                <div className={styles.counterBttn} onClick={this.handleIncrease}>
                    +
                </div>
            </div>
        );
    }

    private renderPrice = (priceTotal: string, currency: Currency) => (
        <div
            className={classNames({
                [styles.price]: true,
            })}
        >
            <Text size={TextSize['44px']} weight={Weight.w700}>
                <FormattedMessage
                    {...messages.price}
                    values={{
                        price: priceTotal,
                        currency: CURRENCY_SYMBOL[currency],
                        span: (msg: string): ReactElement => (
                            <span
                                className={classNames({
                                    [styles.period]: true,
                                })}
                            >
                                <Text size={TextSize['20px']} weight={Weight.w500}>
                                    {msg}
                                </Text>
                            </span>
                        ),
                    }}
                />
            </Text>
        </div>
    );

    public render(): ReactElement | null {
        const { itemsCount } = this.state;
        const { price, currency } = this.props;
        const priceTotal = formatPrice(itemsCount * price, CURRENCY_ROUND[currency]);

        return (
            <div className={classNames(styles.root, styles.root_primary, styles.root_corp)}>
                <div className={styles.header}>
                    <div
                        className={classNames({
                            [styles.title]: true,
                        })}
                    >
                        <div
                            className={classNames({
                                [styles.label]: true,
                            })}
                        >
                            <Text size={TextSize['11px']} weight={Weight.w700}>
                                <FormattedMessage {...messages.label} />
                            </Text>
                        </div>
                        <Text weight={Weight.w700} size={TextSize['28px']}>
                            <FormattedMessage {...messages.forBusiness} />
                        </Text>
                    </div>
                </div>
                <div className={styles.deviceNumberTitle}>
                    <Text size={TextSize['15px']} weight={Weight.w700}>
                        <FormattedMessage {...messages.deviceNumber} />
                    </Text>
                </div>
                {this.renderCounter(itemsCount)}
                <div className={styles.features}>
                    <TariffFeature>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.unlimUpload} />
                        </Text>
                    </TariffFeature>
                    <TariffFeature>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.devices} />
                        </Text>
                    </TariffFeature>
                    <TariffFeature>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.support} />
                        </Text>
                    </TariffFeature>
                    <TariffFeature>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.ssl} />
                        </Text>
                    </TariffFeature>
                    <TariffFeature>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.backupsToClouds} />
                        </Text>
                    </TariffFeature>
                </div>
                <div className={styles.services}>
                    {getServices().map((service) => (
                        <div
                            className={classNames({
                                [styles.service]: true,
                            })}
                            key={service.id}
                        >
                            <img className={styles[service.id]} src={service.logo} alt={service.name} />
                        </div>
                    ))}
                </div>
                {itemsCount <= 25 ? (
                    this.renderPrice(priceTotal, currency)
                ) : (
                    <div className={styles.requestForPrice}>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.requestForPrice} />
                        </Text>
                    </div>
                )}

                <div className={styles.button}>
                    <Button fluid color={ButtonColors.PRIMARY} size={ButtonSize.L} onClick={this.handleOnBuy}>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.bttnTitle} />
                        </Text>
                    </Button>
                </div>
            </div>
        );
    }
}

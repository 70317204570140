export class Base58 {
    ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    ALPHABET_MAP = {} as { [key: string]: number };

    public getBase58Id(length: number) {
        let id = '';

        if (Object.keys(this.ALPHABET_MAP).length <= 0) {
            this.initialize();
        }

        while (id.length !== length) {
            id = this.encode(
                Base58.stringToUint8Array(
                    Math.round(10e16 + Math.random() * 10e16)
                        .toString(36)
                        .slice(0, length - 2)
                )
            );
        }

        return id;
    }

    private initialize() {
        let i = 0;
        while (i < this.ALPHABET.length) {
            this.ALPHABET_MAP[this.ALPHABET.charAt(i)] = i;
            i++;
        }
    }

    private encode(buffer: Uint8Array) {
        let carry;
        if (buffer.length === 0) {
            return '';
        }
        let j = 0;
        const digits = [0];
        let i = 0;
        while (i < buffer.length) {
            j = 0;
            while (j < digits.length) {
                digits[j] <<= 8;
                j++;
            }
            digits[0] += buffer[i];
            carry = 0;
            j = 0;
            while (j < digits.length) {
                digits[j] += carry;
                carry = (digits[j] / 58) | 0;
                digits[j] %= 58;
                ++j;
            }
            while (carry) {
                digits.push(carry % 58);
                carry = (carry / 58) | 0;
            }
            i++;
        }
        i = 0;
        while (buffer[i] === 0 && i < buffer.length - 1) {
            digits.push(0);
            i++;
        }
        return digits
            .reverse()
            .map((digit) => this.ALPHABET[digit])
            .join('');
    }

    private decode(string: string) {
        let c, carry;
        if (string.length === 0) {
            return [];
        }
        let j = 0;
        const bytes = [0];
        let i = 0;
        while (i < string.length) {
            c = string[i];
            if (!(c in this.ALPHABET_MAP)) {
                throw "Base58.decode received unacceptable input. Character '" + c + "' is not in the Base58 alphabet.";
            }
            j = 0;
            while (j < bytes.length) {
                bytes[j] *= 58;
                j++;
            }
            bytes[0] += this.ALPHABET_MAP[c];
            carry = 0;
            j = 0;
            while (j < bytes.length) {
                bytes[j] += carry;
                carry = bytes[j] >> 8;
                bytes[j] &= 0xff;
                ++j;
            }
            while (carry) {
                bytes.push(carry & 0xff);
                carry >>= 8;
            }
            i++;
        }
        i = 0;
        while (string[i] === '1' && i < string.length - 1) {
            bytes.push(0);
            i++;
        }
        return bytes.reverse();
    }

    private static stringToUint8Array(string: string): Uint8Array {
        const uint8Array = new Uint8Array(string.length);

        for (let i = 0, count = string.length; i < count; i++) {
            uint8Array[i] = string.charCodeAt(i);
        }

        return uint8Array;
    }
}

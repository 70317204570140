import React from 'react';
import { TextSize, Text, Weight, LineHeight } from 'src/ui/Text/Text';

import styles from './ShadowBlock.css';

interface Props {
    title: JSX.Element | string;
    text: JSX.Element | string;
}

export const ShadowBlock = (props: Props): JSX.Element => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <Text size={TextSize['44px']} lineHeight={LineHeight['44px']}>
                    {props.title}
                </Text>
            </div>

            <div className={styles.text}>
                <Text size={TextSize['20px']} lineHeight={LineHeight['30px']} weight={Weight.w400}>
                    {props.text}
                </Text>
            </div>
        </div>
    );
};

import React, { PureComponent, ReactElement } from 'react';
import styles from './ServiceWorkWithFiles.css';
import { Carousel } from 'src/ui/Carousel/Carousel';
import { LineHeight, Text, TextSize } from 'src/ui/Text/Text';
import { List } from 'src/ui/List/List';
import { Container } from 'src/ui/Container/Container';
import { BlockTitle } from 'src/ui/BlockTitle/BlockTitle';

interface ListItem {
    image: string;
    title: string | ReactElement;
    description: string | ReactElement;
    list: ReactElement[];
    id: string;
}

interface Props {
    list: ListItem[];
}

export class ServiceWorkWithFiles extends PureComponent<Props> {
    render(): ReactElement {
        const { list } = this.props;
        return (
            <div className={styles.root}>
                <Container>
                    <Carousel dots={true}>
                        {list.map((item) => (
                            <div className={styles.item} key={item.id}>
                                <div className={styles.left}>
                                    <div className={styles.title}>
                                        <BlockTitle>{item.title}</BlockTitle>
                                    </div>
                                    <img src={item.image} className={styles.image} alt="" />
                                </div>
                                <div className={styles.right}>
                                    <div className={styles.description}>
                                        <Text size={TextSize['20px']} lineHeight={LineHeight['30px']}>
                                            {item.description}
                                        </Text>
                                    </div>
                                    <div className={styles.list}>
                                        <List list={item.list} markColor="blue" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </Container>
            </div>
        );
    }
}

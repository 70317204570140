import React, { ReactElement } from 'react';
import styles from './Tabs.css';
import { TabText } from 'src/ui/HelpPage/Tabs/TabText';
import { TabTitle } from 'src/ui/HelpPage/Tabs/TabTitle';
import { defineMessages } from 'react-intl';
import { formatMessage, FormattedMessage } from 'src/utils/i18n';
import { TabName } from 'src/ui/HelpPage/Tabs/TabName';
import { TabList } from 'src/ui/HelpPage/Tabs/TabList';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { AvailableLanguages } from 'src/modules/language/language.types';

import memory1 from 'img/help/memory1.png';
import memory2 from 'img/help/memory2.png';
import memory3 from 'img/help/memory3.png';
import memory4 from 'img/help/memory4.png';
import memoryRu1 from 'img/help/memoryRu1.png';
import memoryRu2 from 'img/help/memoryRu2.png';
import memoryRu3 from 'img/help/memoryRu3.png';
import memoryRu4 from 'img/help/memoryRu4.png';
import settings6 from 'img/help/settings6.png';
import settingsRu6 from 'img/help/settingsRu6.png';
import { TabImage } from 'src/ui/HelpPage/Tabs/TabImage';

const picture =
    getCurrentLanguage() === AvailableLanguages.ru
        ? { memory1: memoryRu1, memory2: memoryRu2, memory3: memoryRu3, memory4: memoryRu4, settings6: settingsRu6 }
        : { memory1, memory2, memory3, memory4, settings6 };

const messages = defineMessages({
    name1: 'Почему <nowrap>Диск-О:</nowrap> сохраняет файлы на{nbsp}компьютере',
    text1:
        '<b>Вы{nbsp}часто пользуетесь этими файлами.</b> Приложение запоминает файлы, которыми чаще всего пользуются, и{nbsp}сохраняет их{nbsp}на{nbsp}компьютере. Благодаря этому важные файлы доступны, даже когда не{nbsp}работает интернет.',
    text2: 'Чтобы отключить функцию:',
    text3:
        'Если вы{nbsp}указали файлы и{nbsp}папки на{nbsp}облаке, которые должны быть доступны без интернета, их{nbsp}копии будут сохраняться на{nbsp}компьютере и{nbsp}автоматически синхронизироваться при подключении к{nbsp}интернету.',
    text4: 'Чтобы удалить файлы, доступные без интернета:',
    text5:
        '<b>Отключено ускорение загрузки.</b> Если отключена опция ускорения загрузки файлов, то <nowrap>Диск-О:</nowrap> сперва загружает файлы во{nbsp}временную папку на{nbsp}системном диске. Пока идёт синхронизация с{nbsp}облачным сервисом, файлы находятся во{nbsp}временной папке, а{nbsp}после загрузки временные файлы удаляются. Если ускорение загрузки файлов включено, файлы не{nbsp}сохраняются во{nbsp}временную папку.',
    text6: 'Не{nbsp}меняйте содержание папки для служебных данных и{nbsp}содержимое файлов в{nbsp}этой папке, иначе данные могут пропасть.',
    name2: 'Как указать, сколько места может использовать <nowrap>Диск-О:</nowrap>',
    text7: 'Вы{nbsp}можете указать максимальный объем свободного места диска, который <nowrap>Диск-О:</nowrap> может использовать.',
    goToSettings: 'Перейдите в настройки приложения.',
});

const offListMessages = defineMessages({
    offStep1: 'Уберите галочку {laquo}Запоминать файлы{raquo}.',
});

const deleteListMessages = defineMessages({
    deleteStep1: 'Нажмите {laquo}Выбрать{raquo} в{nbsp}области {laquo}Синхронизировать папки{raquo}.',
    deleteStep2: 'Уберите галочки с{nbsp}файлов, которые нужно убрать из{nbsp}<nowrap>офлайн-доступа</nowrap>.',
});

const spaceListMessages = defineMessages({
    spaceStep1: 'Нажмите {laquo}Изменить{raquo} в{nbsp}разделе {laquo}Файлы в{nbsp}быстром доступе{raquo}.',
    spaceStep2:
        'В{nbsp}разделе {laquo}Объем памяти для хранения файлов{raquo} потяните бегунок вправо или влево, чтобы увеличить или уменьшить место для хранения файлов.',
});

const offList = [formatMessage(messages.goToSettings), formatMessage(offListMessages.offStep1)];
const deleteList = [
    formatMessage(messages.goToSettings),
    <span key="step2-delete">
        <FormattedMessage {...deleteListMessages.deleteStep1} />
        <TabImage src={picture.settings6} />
    </span>,
    <span key="step3-delete">
        <FormattedMessage {...deleteListMessages.deleteStep2} />
        <TabImage src={picture.memory2} />
    </span>,
];
const spaceList = [
    formatMessage(messages.goToSettings),
    <span key="step2-memory">
        <FormattedMessage {...spaceListMessages.spaceStep1} />
        <TabImage src={picture.memory3} />
    </span>,
    <span key="step3-memory">
        <FormattedMessage {...spaceListMessages.spaceStep2} />
        <TabImage src={picture.memory4} />
    </span>,
];

export const Memory = ({ title }: { title: string }) => (
    <div className={styles.tab}>
        <TabTitle>{title}</TabTitle>
        <TabName>
            <FormattedMessage {...messages.name1} />
        </TabName>
        <TabText>
            <FormattedMessage
                {...messages.text1}
                values={{
                    // eslint-disable-next-line react/display-name
                    b: (msg: string): ReactElement => <b key={msg}>{msg}</b>,
                }}
            />
        </TabText>
        <TabText>
            <FormattedMessage {...messages.text2} />
        </TabText>
        <TabList list={offList} ordered />
        <TabImage src={picture.memory1} />
        <TabText>
            <FormattedMessage
                {...messages.text3}
                values={{
                    // eslint-disable-next-line react/display-name
                    b: (msg: string) => <b>{msg}</b>,
                }}
            />
        </TabText>
        <TabText>
            <FormattedMessage {...messages.text4} />
        </TabText>
        <TabList list={deleteList} ordered />
        <TabText>
            <FormattedMessage
                {...messages.text5}
                values={{
                    // eslint-disable-next-line react/display-name
                    b: (msg: string) => <b>{msg}</b>,
                }}
            />
        </TabText>
        <TabText>
            <FormattedMessage {...messages.text6} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name2} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text7} />
        </TabText>
        <TabList list={spaceList} />
    </div>
);

import { AppState } from 'src/store';
import { OmicronState } from './omicron.types';
import { OmicronConfig, OmicronParam } from 'src/modules/api/getOmicronConfig';
import { getFeaturesByCookie } from 'src/utils/features.helpers';

export const getOmicronState = (state: AppState): OmicronState => state.omicron;
export const getOmicronConfig = (state: AppState): OmicronConfig | undefined => getOmicronState(state).data?.config;
export const getOmicronParam = (state: AppState, paramName: OmicronParam): any => {
    const config = getOmicronConfig(state);
    const extendedConfig = Object.assign(config || {}, getFeaturesByCookie.mapped());

    return extendedConfig[paramName];
};

interface MacOsDownloadLinkResult {
    backupsPage: {
        otherLink: string;
        ruLink: string;
    };
    otherLink: string;
    ruLink: string;
}
export const getMacOsDownloadLink = (state: AppState): MacOsDownloadLinkResult => getOmicronParam(state, 'disk-o-mac-os-download-link');

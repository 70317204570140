import React, { PureComponent, ReactElement } from 'react';
import { LanguageContainer } from 'src/ui/LanguageContainer/LanguageContainer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { RE_LANGS } from 'src/router';
import { DownloadPage } from 'src/ui/DownloadPage/DownloadPage';

export class RouterContainer extends PureComponent {
    render(): ReactElement {
        return (
            <Router>
                <Switch>
                    <Route path={`/v:version(1)`} component={DownloadPage} />
                    <Route path={`/:lang${RE_LANGS}`} component={LanguageContainer} />
                </Switch>
            </Router>
        );
    }
}

import React, { useCallback, useState } from 'react';
import { ReactComponent as PlayIcon } from 'img/play.svg';
import { Modal } from 'src/ui/Modal/Modal';

import styles from './Player.css';
import classNames from 'clsx';

interface Props {
    poster: string;
    videoUrl: string;
    noPosterBackground?: boolean;
    wideVideoPreview?: boolean;
}

export const Player = (props: Props): JSX.Element => {
    const { noPosterBackground, poster, videoUrl, wideVideoPreview } = props;

    const [isOpen, setOpen] = useState<boolean>(false);

    const onOpen = useCallback(() => setOpen(true), []);
    const onClose = useCallback(() => setOpen(false), []);

    return (
        <>
            {isOpen && (
                <Modal onClose={onClose} fullScreen>
                    <iframe
                        width="100%"
                        height={Math.round(window.innerHeight - 60)}
                        src={videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className={styles.iframe}
                        title="video"
                    />
                </Modal>
            )}
            <div className={classNames(styles.root, { [styles.root_wideVideoPreview]: !!wideVideoPreview })}>
                <img
                    className={classNames(styles.poster, { [styles.poster_noBack]: !!noPosterBackground })}
                    src={poster}
                    onClick={onOpen}
                    alt=""
                    loading="lazy"
                />
                <div className={styles.play} onClick={onOpen}>
                    <PlayIcon />
                </div>
            </div>
        </>
    );
};

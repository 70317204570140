import { getMonths } from 'src/utils/period';
import { i18n } from 'src/utils/i18n';

export const formatPrice = (price: number | null | undefined, precisionDigits = 0): string => {
    if (!price) {
        return '';
    }
    const koeff = precisionDigits > 0 ? 10 ** precisionDigits : 1;
    const formatter = { maximumFractionDigits: precisionDigits, minimumFractionDigits: 0 };
    return i18n.formatNumber(Math.round(koeff * price) / koeff, formatter);
};

export const getMonthlyPrice = (priceTotal: number | null | undefined, period: string, precisionDigits = 0): string => {
    if (!priceTotal) {
        return '';
    }
    const res = priceTotal / getMonths(period);
    return formatPrice(res, precisionDigits);
};

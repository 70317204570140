import { ThunkAction } from 'redux-thunk';
import { AppState } from 'src/store';
import { Action } from 'redux';
import { BuyGetLinkActionTypes, GetLinkFailureAction, GetLinkRequestAction, GetLinkSuccessAction } from 'src/modules/buy/buy.types';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { giveSerial, GiveSerialRequest, GiveSerialResponse } from 'src/modules/api/giveSerial';
import { getQueryUtmContent } from 'src/utils/route.helpers';
import { getProlong, GetProlongRequest, GetProlongResponse } from 'src/modules/api/getProlongLink';
import { GetBindRequest, GetBindResponse, getBind } from 'src/modules/api/getBindLink';

const request = (): GetLinkRequestAction => ({
    type: BuyGetLinkActionTypes.REQUEST,
});

const failure = (): GetLinkFailureAction => ({
    type: BuyGetLinkActionTypes.FAILURE,
});

const success = ({ token, url }: GiveSerialResponse): GetLinkSuccessAction => ({
    type: BuyGetLinkActionTypes.SUCCESS,
    link: url,
    token,
});

const utm = getQueryUtmContent();

const getCorrectedLanguage = () => {
    let language: string = getCurrentLanguage();

    if (language === 'zh') {
        language = 'cn';
    }

    if (language === 'hi') {
        language = 'in';
    }

    return language;
};

export const getLink = ({ id, email }: { id: string; email: string; utm?: string }): ThunkAction<void, AppState, null, Action<string>> => (
    dispatch
): void => {
    dispatch(request());

    const params: GiveSerialRequest = { language: getCorrectedLanguage(), plan: id, email };

    if (utm) {
        params.utm = utm;
    }

    giveSerial(params)
        .then(({ url, token }: GiveSerialResponse) => dispatch(success({ url, token })))
        .catch(() => dispatch(failure()));
};

export const getProlongLink = ({ alias }: { alias: string }): ThunkAction<void, AppState, null, Action<string>> => (dispatch): void => {
    dispatch(request());

    const params: GetProlongRequest = { alias, language: getCorrectedLanguage() };

    getProlong(params)
        .then(({ url }: GetProlongResponse) => dispatch(success({ url, token: '' })))
        .catch(() => dispatch(failure()));
};

export const getBindLink = ({ alias }: { alias: string }): ThunkAction<void, AppState, null, Action<string>> => (dispatch): void => {
    dispatch(request());

    const params: GetBindRequest = { alias, language: getCorrectedLanguage() };

    getBind(params)
        .then(({ url }: GetBindResponse) => dispatch(success({ url, token: '' })))
        .catch(() => dispatch(failure()));
};

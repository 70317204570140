import React from 'react';
import classNames from 'clsx';
import { ReactComponent as LogoEn } from 'img/logo/logo.svg';
import { ReactComponent as LogoEnShort } from 'img/logo/logo_short.svg';

import { ReactComponent as LogoRu } from 'img/logo/logo_ru.svg';
import { ReactComponent as LogoRuShort } from 'img/logo/logo_ru_short.svg';

import { isRussianLanguage } from 'src/modules/language/language.selectors';
import styles from './Logo.css';

interface LogoProps {
    flexible?: boolean;
    whiteLogo?: boolean;
    alt?: string;
}

export const Logo = React.memo((props: LogoProps) => {
    const { whiteLogo, alt, flexible } = props;
    const isRu = isRussianLanguage();

    const ShortLogo = isRu ? <LogoRuShort /> : <LogoEnShort />;
    const WideLogo = isRu ? <LogoRu /> : <LogoEn />;

    return (
        <div
            role="img"
            aria-label={alt}
            className={classNames({
                [styles.root]: true,
                [styles.root_white]: whiteLogo,
                [styles.root_flexible]: flexible,
            })}
        >
            {flexible && <div className={styles.logo_short}>{ShortLogo}</div>}
            <div className={styles.logo}>{WideLogo}</div>
        </div>
    );
});

import React, { Fragment, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';

import styles from './LicenseKeyDialog.css';
import { Modal } from 'src/ui/Modal/Modal';
import { Button, ButtonColors } from 'src/ui/Button/Button';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Download } from 'src/ui/Download/Download';
import { Link } from 'src/ui/Link/Link';
import { ReactComponent as ClipboardIcon } from 'img/icons/clipboard.svg';
import { copy } from 'src/utils/copyToClipboard';
import { sendGa } from 'src/utils/ga';
import { getQueryIsWebview } from 'src/utils/route.helpers';
import { AppState } from 'src/store';
import { isMobile, isPhone } from 'src/modules/media/media.selectors';

const messages = defineMessages({
    title: `Подписка оформлена`,
    deepLinkActivate: 'Активировать ключ',
    // eslint-disable-next-line no-irregular-whitespace
    actionInfo: `Активируйте лицензионный ключ — и получите доступ ко всем возможностям приложения Диск-О: на один год. Данный ключ активации можно использовать на трех устройствах. Промокод на Облако придет на email, указанный при покупке`,
    label: `Приобретенная подписка не действует в версиях Диск-О:, которые были установлены из AppStore для macOS. Для активации купленной подписки установите приложение по ссылке.`,
    // eslint-disable-next-line no-irregular-whitespace
    info: `Активируйте лицензионный ключ — и получите доступ ко всем возможностям приложения Диск-О:. Данный ключ активации можно использовать на трех устройствах.`,
    download: 'Скачать Диск-О',
    close: 'Понятно',
});

const isWebview = getQueryIsWebview();

interface MapState {
    isPhone: boolean;
    isMobile: boolean;
}

interface Props extends MapState {
    license: string;
    onClose?: Function;
}

const mapStateToProps = (state: AppState): MapState => ({
    isPhone: isPhone(state),
    isMobile: isMobile(state),
});

const sendAnalytics = (action: string, label?: string) =>
    sendGa({
        category: 'licenseKey',
        action,
        label,
    });

export class LicenseKeyDialogComponent extends PureComponent<Props> {
    componentDidMount() {
        const { license } = this.props;

        sendAnalytics('show');

        if (isWebview && typeof window.disko_activate === 'function') {
            window.disko_activate(license);
        }
    }

    handleOnCopyClick = () => {
        copy(this.props.license);
        sendAnalytics('copy');
    };

    handleOnDeepLinkClick = () => {
        sendAnalytics('deepLink-click');
    };

    handleOnClose = () => {
        const { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    };

    public render(): ReactElement {
        const { license, isPhone, isMobile } = this.props;

        return (
            <Modal
                header={
                    <div className={styles.borderHeader}>
                        <Text size={TextSize['24px']} weight={Weight.w700}>
                            <FormattedMessage {...messages.title} />
                        </Text>
                    </div>
                }
                onClose={this.handleOnClose}
            >
                <div className={styles.root}>
                    <div className={styles.info}>
                        <div className={styles.inputWrap}>
                            {isPhone ? (
                                <div className={styles.license}>
                                    <Text size={TextSize['24px']} weight={Weight.w700}>
                                        {license}
                                    </Text>
                                </div>
                            ) : (
                                <Fragment>
                                    <input className={styles.input} type="text" id="license" readOnly autoFocus value={license} />
                                    <div className={styles.copyIcon} onClick={this.handleOnCopyClick}>
                                        <ClipboardIcon fill="currentColor" />
                                    </div>
                                </Fragment>
                            )}
                        </div>
                        <Text size={TextSize['17px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.info} />
                        </Text>
                    </div>
                    {!isWebview && !isMobile && (
                        <div className={styles.buttons}>
                            <Download showOnlyOne>
                                <Text size={TextSize['20px']}>
                                    <FormattedMessage {...messages.download} />
                                </Text>
                            </Download>
                            <div className={styles.deepLink}>
                                <Link href={`disko://activate/${license}`} onClick={this.handleOnDeepLinkClick}>
                                    <Text weight={Weight.w500} size={TextSize['20px']}>
                                        <FormattedMessage {...messages.deepLinkActivate} />
                                    </Text>
                                </Link>
                            </div>
                        </div>
                    )}
                    {isMobile && (
                        <div className={styles.closeButton}>
                            <Button color={ButtonColors.PRIMARY} onClick={this.handleOnClose}>
                                <div className={styles.textWrap}>
                                    <Text size={TextSize['20px']}>
                                        <FormattedMessage {...messages.close} />
                                    </Text>
                                </div>
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

export const LicenseKeyDialog = connect(mapStateToProps)(LicenseKeyDialogComponent);

import { AppState } from 'src/store';
import { BuyState } from 'src/modules/buy/buy.types';

const getBuyState = (state: AppState): BuyState => state.buy;

export const getDMRUrl = (state: AppState): string => getBuyState(state).link;
export const getTariffBuyToken = (state: AppState): string => getBuyState(state).token;

export const isDMRLoading = (state: AppState): boolean => getBuyState(state).isLoading;
export const dmrHasError = (state: AppState): boolean => getBuyState(state).hasError;

import { BaseAction } from 'src/types/action.types';
import { Status } from 'src/types/status';

export enum ActionTypes {
    REQUEST = '@sendEmail/request',
    SUCCESS = '@sendEmail/success',
    FAIL = '@sendEmail/fail',
}

export enum ResetActionTypes {
    RESET = '@sendEmail/reset',
}

export interface SendEmailState {
    status: Status;
    message: string;
}

type SendEmailAction = BaseAction<ActionTypes.REQUEST> & { email?: string };
type SendEmailFailAction = BaseAction<ActionTypes.FAIL> & { message: string };
type ResetStateAction = BaseAction<ResetActionTypes.RESET>;
type SendEmailSuccessAction = BaseAction<ActionTypes.SUCCESS>;

export type SendEmailActionTypes = SendEmailAction | SendEmailSuccessAction | SendEmailFailAction | ResetStateAction;

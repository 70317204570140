import { createStore, applyMiddleware } from 'redux';
import { reducers } from 'src/modules/reducers';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = applyMiddleware(thunkMiddleware);
const composedEnhancers = composeWithDevTools(middlewares);

export const store = createStore(reducers, {}, composedEnhancers);

export { AppState } from 'src/modules/types';

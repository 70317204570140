import { parse } from 'qs';
import { matchPath } from 'react-router-dom';

const getQueryParams = (): Record<string, string | undefined> =>
    parse(window.location.search, {
        ignoreQueryPrefix: true,
    }) || {};

export const getQueryIsCorp = (): boolean => getQueryParams().corp === '1';

export const getQueryIsWebview = (): boolean => getQueryParams().webview === '1';

export const getQueryIntro = (): string | undefined => getQueryParams().intro;

export const getQueryGclid = (): string | undefined => getQueryParams().gclid;

export const getQueryUtmContent = (): string | undefined => getQueryParams().utm_content;

export const getQueryClientId = (): string | undefined => getQueryParams().client_id;

export const isExactMatchPage = (page: string): boolean =>
    Boolean(
        matchPath(window.location.pathname, {
            path: `/:lang/${page}`,
            exact: true,
        })
    );

export const isExactMatchPageByLanguage = (lang: string, page: string): boolean =>
    Boolean(
        matchPath(window.location.pathname, {
            path: `/${lang}/${page}`,
            exact: true,
        })
    );

export const isMatchPage = (page: string): boolean =>
    Boolean(
        matchPath(window.location.pathname, {
            path: `/:lang/${page}`,
        })
    );

export const isIndexPage = (): boolean =>
    Boolean(
        matchPath(window.location.pathname, {
            path: '/',
            exact: true,
            strict: true,
        })
    );

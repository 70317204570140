import React, { ReactElement } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { FormattedMessage } from 'src/utils/i18n';
import { Accordion } from 'src/ui/Accordion/Accordion';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import styles from './BackupsFAQ.css';
import { Container } from 'src/ui/Container/Container';

const messages = defineMessages({
    title: 'Вопрос-ответ',
});

const faqMessages = defineMessages({
    q1: 'Как часто обновляются резервные копии в Диск-О:?',
    a1:
        'Это происходит автоматически в режиме реального времени. Диск-О: отслеживает любое изменение в папках, для которых созданы резервные копии.',
    q2: 'Что будет, если я удалю файл из папки, у которой есть резервная копия?',
    a2:
        'Диск-О: спросит, удалить ли его из резервной копии на облачном сервисе или оставить там. Программа может запомнить ваш выбор и больше не показывать это оповещение.',
    q3: 'Могу ли я создать резервные копии всех папок на компьютере?',
    a3:
        'Да, можете. Единственное условие – размер резервных копий не может превышать объем облака, в котором они будут храниться. Но вы можете распределить ваши копии по разным облачным сервисам.',
}) as { [key: string]: MessageDescriptor };

const questions: { title: ReactElement; content: ReactElement }[] = new Array(3).fill(true).map((item, index) => ({
    title: <FormattedMessage {...faqMessages[`q${index + 1}`]} />,
    content: <FormattedMessage {...faqMessages[`a${index + 1}`]} />,
}));

export const BackupsFAQ = () => {
    return (
        <Container>
            <div className={styles.root}>
                <div className={styles.title}>
                    <Text size={TextSize['44px']} weight={Weight.w700}>
                        <FormattedMessage {...messages.title} />
                    </Text>
                </div>
                <Accordion items={questions} darkBlock />
            </div>
        </Container>
    );
};

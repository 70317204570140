import React, { PureComponent, ReactElement } from 'react';
import classNames from 'clsx';
import styles from './TabMenu.css';
import { getHash } from 'src/utils/location.helpers';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { getQueryIsCorp } from 'src/utils/route.helpers';

interface TabMenuItem {
    title: string | ReactElement;
    id: string;
    component: ReactElement | null;
}

interface Props extends RouteComponentProps {
    items: TabMenuItem[];
    onClick?: (id: string) => void;
    isVertical?: boolean;
}

interface State {
    activeId?: string;
}

class TabMenuComponent extends PureComponent<Props, State> {
    state = {
        activeId: this.props.items[0].id,
    };

    componentDidMount(): void {
        const isCorpMode = getQueryIsCorp();
        const itemId = isCorpMode ? 'corp' : getHash();
        if (!itemId) {
            return;
        }
        const hasId: boolean = this.props.items.some((item: TabMenuItem): boolean => item.id === itemId);
        if (hasId) {
            this.setState({ activeId: itemId });
        }
    }

    private handleClick = (event: any): void => {
        this.setState({ activeId: event.currentTarget.id });
    };

    public render(): ReactElement | null {
        const { items, isVertical } = this.props;
        const { activeId } = this.state;

        if (!items || items.length <= 0) {
            return null;
        }

        return (
            <div className={styles.root}>
                <div
                    className={classNames({
                        [styles.header]: true,
                        [styles.header_vertical]: isVertical,
                    })}
                >
                    {items.map(
                        (item: TabMenuItem): ReactElement => (
                            <Link
                                key={item.id}
                                id={item.id}
                                className={classNames({ [styles.item]: true, [styles.itemActive]: item.id === activeId })}
                                to={`#${item.id}`}
                                onClick={this.handleClick}
                            >
                                <Text size={TextSize['17px']} weight={Weight.w500}>
                                    {item.title}
                                </Text>
                            </Link>
                        )
                    )}
                </div>
                <div className={styles.content}>
                    {items.map((item: TabMenuItem): ReactElement | null => (
                        <div
                            key={item.id}
                            className={classNames({ [styles.contentItem]: true, [styles.contentItemActive]: item.id === activeId })}
                        >
                            {item.component}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export const TabMenu = withRouter(TabMenuComponent);

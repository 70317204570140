import React, { PureComponent, ReactElement } from 'react';
import { Dropdown } from 'src/ui/Dropdown/Dropdown';
import classNames from 'clsx';
import ruFlag from 'img/flags/ru.svg';
import deFlag from 'img/flags/de.svg';
import esFlag from 'img/flags/es.svg';
import enFlag from 'img/flags/en.svg';
import trFlag from 'img/flags/tr.svg';
import frFlag from 'img/flags/fr.svg';
import chFlag from 'img/flags/ch.svg';
import hiFlag from 'img/flags/hi.svg';
import styles from './LanguageChoose.css';
import { AvailableLanguages } from 'src/modules/language/language.types';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { sendGa } from 'src/utils/ga';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface State {
    isOpen: boolean;
}

const RE_LANGUAGE_IN_URL = new RegExp(`^/(${getCurrentLanguage()})((/|$).*)`);
const getUrlByLang = (lang: AvailableLanguages) =>
    `/${lang}${window.location.pathname.replace(RE_LANGUAGE_IN_URL, (match, p1, p2) => {
        return p2;
    })}`;

export class LanguageChooseComponent extends PureComponent<{} & RouteComponentProps, State> {
    readonly state = {
        isOpen: false,
    };

    handleOnLanguageClick = (id: string): void => {
        sendGa({
            category: 'language',
            action: 'click',
            label: id,
            sendImmediately: true,
        });
        window.location.assign(getUrlByLang(id as AvailableLanguages));
    };

    handleOnOpen = (): void => {
        sendGa({
            category: 'language',
            action: 'click',
            label: 'choose',
        });
        this.setState({
            isOpen: true,
        });
    };

    handleOnClose = (): void => {
        this.setState({
            isOpen: false,
        });
    };

    renderFlag = (id: string): ReactElement => {
        let path;
        switch (id) {
            case AvailableLanguages.de:
                path = deFlag;
                break;
            case AvailableLanguages.en:
                path = enFlag;
                break;
            case AvailableLanguages.ru:
                path = ruFlag;
                break;
            case AvailableLanguages.es:
                path = esFlag;
                break;
            case AvailableLanguages.tr:
                path = trFlag;
                break;
            case AvailableLanguages.fr:
                path = frFlag;
                break;
            case AvailableLanguages.hi:
                path = hiFlag;
                break;
            case AvailableLanguages.zh:
                path = chFlag;
                break;
        }

        return <img src={path} alt="флаг" width={16} height={14} />;
    };

    getList = () => {
        const {
            location: { pathname },
        } = this.props;

        let list = [
            {
                text: 'Русский',
                id: AvailableLanguages.ru,
                icon: this.renderFlag('ru'),
            },
        ];

        // cloudweb-10844 404 ошибка при переключении на турецкий язык в разделе Помощь
        if (pathname.split('/')[2] === 'faq') {
            list = [
                ...list,
                {
                    text: 'English',
                    id: AvailableLanguages.en,
                    icon: this.renderFlag('en'),
                },
            ];
        } else {
            list = [
                ...list,
                {
                    text: 'English',
                    id: AvailableLanguages.en,
                    icon: this.renderFlag('en'),
                },
                {
                    text: 'French',
                    id: AvailableLanguages.fr,
                    icon: this.renderFlag('fr'),
                },
                {
                    text: 'German',
                    id: AvailableLanguages.de,
                    icon: this.renderFlag('de'),
                },
                {
                    text: 'Spanish',
                    id: AvailableLanguages.es,
                    icon: this.renderFlag('es'),
                },
                {
                    text: 'Hindi',
                    id: AvailableLanguages.hi,
                    icon: this.renderFlag('hi'),
                },
                {
                    text: 'Chinese',
                    id: AvailableLanguages.zh,
                    icon: this.renderFlag('zh'),
                },
                {
                    text: 'Turkish',
                    id: AvailableLanguages.tr,
                    icon: this.renderFlag('tr'),
                },
            ];
        }

        return list;
    };

    render(): ReactElement {
        return (
            <Dropdown
                additionMarginY={8}
                additionMarginX={-24}
                onClick={this.handleOnLanguageClick}
                list={this.getList()}
                onOpen={this.handleOnOpen}
                onClose={this.handleOnClose}
            >
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_open]: this.state.isOpen,
                    })}
                >
                    <div className={styles.links}>
                        {this.getList().map((item) => (
                            <a key={item.id} href={getUrlByLang(item.id)}>
                                {item.text}
                            </a>
                        ))}
                    </div>
                    <div className={styles.flag}>{this.renderFlag(getCurrentLanguage())}</div>
                    <div className={styles.arrow} />
                </div>
            </Dropdown>
        );
    }
}

export const LanguageChoose = withRouter(LanguageChooseComponent);

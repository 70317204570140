import { AppState } from 'src/store';
import { MediaState } from 'src/modules/media/media.types';
import { get, flow } from 'lodash/fp';

export const getMediaState = (state: AppState): MediaState => state.media;

export const isPhone = flow([getMediaState, get('isPhone')]);

export const isMobile = flow([getMediaState, get('isMobile')]);

export const isTablet = flow([getMediaState, get('isTablet')]);

export const isDesktop = flow([getMediaState, get('isDesktop')]);

export const isSmallDesktop = flow([getMediaState, get('isDesktopSmall')]);

export const isMediumDesktop = flow([getMediaState, get('isDesktopMedium')]);

import React, { PureComponent, ReactElement, ReactNode } from 'react';
import classNames from 'clsx';
import { Link as RouterLink, LinkProps as RouterProps } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import styles from './Link.css';

export enum Colors {
    primary = 'primary',
    black = 'black',
    white = 'white',
}

interface RouterLinkProps extends RouterProps {
    underline?: boolean;
    color?: Colors;
    onClick?: (e: React.MouseEvent) => void;
}

interface LinkProps {
    href: string;
    onClick?: (e: React.MouseEvent) => void;
    target?: '_blank' | '_self';
    noPrevent?: boolean;
    underline?: boolean;
    color?: Colors;
    children: ReactNode;
}

interface ButtonLinkProps {
    onClick: (e: React.MouseEvent) => void;
    underline?: boolean;
    color?: Colors;
    children: ReactNode;
}

type Props = LinkProps | ButtonLinkProps | RouterLinkProps;

const isLinkProps = (props: Props): props is LinkProps => 'href' in props;
const isRouterLinkProps = (props: Props): props is RouterLinkProps => 'to' in props;
const isButtonLinkProps = (props: Props): props is ButtonLinkProps =>
    'onClick' in props && !isLinkProps(props) && !isRouterLinkProps(props);

export class Link extends PureComponent<Props> {
    static defaultProps = {
        color: Colors.primary,
    };

    handleOnClick = (e: React.MouseEvent): void => {
        if (isButtonLinkProps(this.props)) {
            e.preventDefault();
        }

        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    getClassNames = (): string => {
        const { color, underline } = this.props;

        return classNames({
            [styles.root]: true,
            [styles[`root_color_${color}`]]: color,
            [styles.root_underline]: underline,
        });
    };

    renderLink = (): ReactElement => {
        const { href, children, target } = this.props as LinkProps;

        return (
            <a href={href} target={target} onClick={this.handleOnClick} className={this.getClassNames()}>
                {children}
            </a>
        );
    };

    renderButton = (): ReactElement => {
        const { children } = this.props as ButtonLinkProps;

        return (
            <button type="button" onClick={this.handleOnClick} className={this.getClassNames()}>
                {children}
            </button>
        );
    };

    renderRouterLink = (): ReactElement => {
        const { to, children } = this.props as RouterLinkProps;

        if ((typeof to === 'string' && to.includes('#')) || (typeof to === 'object' && to.hash)) {
            return (
                <HashLink to={to} onClick={this.handleOnClick} className={this.getClassNames()} smooth>
                    {children}
                </HashLink>
            );
        }

        return (
            <RouterLink to={to} onClick={this.handleOnClick} className={this.getClassNames()}>
                {children}
            </RouterLink>
        );
    };

    render(): ReactElement {
        if (isRouterLinkProps(this.props)) {
            return this.renderRouterLink();
        } else if (isButtonLinkProps(this.props)) {
            return this.renderButton();
        }

        return this.renderLink();
    }
}

export interface DownloadPromoState {
    isDownloadPromoSnow: boolean;
}

export enum DownloadPromoActionsTypes {
    SHOW = '@downloadPromo/show',
    HIDE = '@downloadPromo/hide',
}

export interface DownloadPromoIsShow {
    type: typeof DownloadPromoActionsTypes.SHOW;
}

export interface DownloadPromoIsHide {
    type: typeof DownloadPromoActionsTypes.HIDE;
}

export type DownloadPromoActions = DownloadPromoIsShow | DownloadPromoIsHide;

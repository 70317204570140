import React, { PureComponent, ReactElement } from 'react';
import classNames from 'clsx';
import { ReactComponent as MarkIcon } from 'img/icons/mark.svg';

import styles from './Checkbox.css';
import { noop } from 'src/utils/helpers';

type Props = { initialValue?: boolean; onChange: (checked: boolean) => void } | { checked: boolean; onChange: () => void };

interface State {
    checked: boolean;
}

export class Checkbox extends PureComponent<Props, State> {
    static defaultProps = {
        checked: false,
        onChange: noop,
        initialValue: false,
    };

    public readonly state = {
        checked: Boolean('initialValue' in this.props && this.props.initialValue),
    };

    handleOnChange = (): void => {
        const { onChange } = this.props;
        const { checked } = this.state;

        this.setState({
            checked: !checked,
        });

        if (onChange) {
            onChange(!checked);
        }
    };

    render(): ReactElement {
        const { checked } = 'checked' in this.props && this.props.checked !== undefined ? this.props : this.state;

        return (
            <label
                className={classNames({
                    [styles.root]: true,
                    [styles.root_checked]: checked,
                })}
            >
                <div className={styles.check}>
                    <MarkIcon />
                </div>
                <input type="checkbox" className={styles.input} checked={checked} onChange={this.handleOnChange} />
            </label>
        );
    }
}

import { Margin } from './helpers';

export const ANIMATION_DURATION = 500;
export const LINE_WIDTH = 2;
export const ARROW_SIZE = 8;
export const CIRCLE_SIZE = 3;
export const ARROW_MARGIN = 50;
export const ARROW_MIN_MARGIN = 10;
export const BEGIN_ATTITUDE = 0.75;
export const END_ATTITUDE = 0.75;

export const BrowserDownloadDot: Record<string, Margin> = {
    default: {
        right: 50,
        top: 50,
    },
    Chrome: {
        bottom: 50,
        left: 50,
    },
    Firefox: {
        right: 50,
        top: 50,
    },
    Opera: {
        right: 50,
        top: 50,
    },
    Yandex: {
        right: 50,
        top: 50,
    },
    Edge: {
        right: 125,
        top: 50,
    },
};

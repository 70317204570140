import { ActionTypes, ResetActionTypes, SendEmailActionTypes, SendEmailState } from './sendEmail.types';
import { Status } from 'src/types/status';

const INITIAL_STATE: SendEmailState = {
    status: Status.none,
    message: '',
};

export const sendEmailReducer = (state = INITIAL_STATE, action: SendEmailActionTypes): SendEmailState => {
    switch (action.type) {
        case ActionTypes.REQUEST:
            return {
                ...INITIAL_STATE,
                status: Status.isLoading,
                message: '',
            };
        case ActionTypes.SUCCESS:
            return {
                ...INITIAL_STATE,
                status: Status.success,
                message: '',
            };
        case ActionTypes.FAIL:
            return {
                ...INITIAL_STATE,
                status: Status.error,
                message: action.message,
            };
        case ResetActionTypes.RESET:
            return {
                ...INITIAL_STATE,
                status: Status.none,
                message: '',
            };
        default:
            return state;
    }
};

import { CalcCrc } from 'src/utils/calcCRC';
import { ga } from 'react-ga';
import { getQueryClientId } from 'src/utils/route.helpers';

const jigurdaTimeout = 1000;
let clientId = getQueryClientId();

export const getClientId = (): Promise<string> =>
    new Promise((resolve) => {
        if (clientId) {
            resolve(clientId);
            return;
        }

        // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id?hl=ru#getting_the_client_id_from_the_cookie
        ga((tracker: any) => {
            clientId = tracker.get('clientId');
            resolve(clientId);
        });
    });

export const getCrc = (suffix: string, userid: string, limit: number): boolean => {
    let crc: any = null;

    try {
        crc = window.localStorage.getItem(`jigurda:${suffix}`);
    } catch (error) {
        console.error(error.message);
    }

    if (!crc) {
        try {
            crc = CalcCrc(suffix + userid);
            window.localStorage.setItem(`jigurda:${suffix}`, crc);
        } catch (error) {
            console.error(error.message);
        }
    }

    return crc >= 0 && crc < limit * 10;
};

export const jigurda = (name: string, percent: number): Promise<boolean> => {
    return new Promise((resolve) => {
        const getUserIdTimeout = setTimeout(() => {
            resolve(getCrc(name, '0', percent));
        }, jigurdaTimeout);

        getClientId().then((id) => {
            clearTimeout(getUserIdTimeout);
            resolve(getCrc(name, id, percent));
        });
    });
};

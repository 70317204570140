import React, { memo, ReactElement } from 'react';
import styles from './Hero.css';
import { Text, TextSize, Weight } from '../Text/Text';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Container } from 'src/ui/Container/Container';
import { Download } from 'src/ui/Download/Download';
import { isRussianLanguage } from 'src/modules/language/language.selectors';

const description =
    'Программа, которая умеет объединять разные облака в\u00A0одном пространстве и\u00A0быстро переносить все файлы с\u00A0любого диска в\u00A0Облако Mail.ru.';

const messages = defineMessages({
    title: `Диск-О: агрегатор <span>облачных</span> дисков`,
    description:
        'Программа, с которой вы сможете переключаться между облаками в один клик, не открывая браузер, а их содержимое не будет занимать место в памяти компьютера',
    download: 'Скачать для {osName}',
});

export const Hero = memo(() => {
    return (
        <Container>
            <div className={styles.root}>
                <div className={styles.content}>
                    <h1 className={styles.title}>
                        <Text size={TextSize['40px']} desktopSize={TextSize['64px']} weight={Weight.w700}>
                            <FormattedMessage
                                {...messages.title}
                                values={{
                                    span: (msg: string): ReactElement => <span className={styles.highlight}>{msg}</span>,
                                }}
                            />
                        </Text>
                    </h1>
                    <div className={styles.text}>
                        <Text size={TextSize['20px']} desktopSize={TextSize['24px']} weight={Weight.w500}>
                            {isRussianLanguage() ? description : <FormattedMessage {...messages.description} />}
                        </Text>
                    </div>
                    <Download />
                </div>
                <div className={styles.image} />
            </div>
        </Container>
    );
});

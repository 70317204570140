import React, { PureComponent, ReactElement } from 'react';
import { IntlProvider } from 'react-intl';
import { App } from '../App/App';
import { i18n as intlConfig } from 'src/utils/i18n';

export class LanguageContainer extends PureComponent {
    public render(): ReactElement {
        return (
            <IntlProvider {...intlConfig}>
                <App />
            </IntlProvider>
        );
    }
}

import React, { PureComponent, ReactElement } from 'react';
import styles from './Loader.css';
import classNames from 'clsx';

interface LoaderProps {
    fullSize?: boolean;
}

export class Loader extends PureComponent<LoaderProps> {
    static defaultProps = {
        fullSize: false,
    };

    render(): ReactElement {
        return (
            <div
                className={classNames({
                    [styles.loader]: true,
                    [styles.fullSize]: this.props.fullSize,
                })}
            />
        );
    }
}

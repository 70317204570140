import React, { PureComponent, ReactElement, ReactNode } from 'react';
import styles from './Main.css';
import { sendGa } from 'src/utils/ga';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { FormattedMessage, i18n } from 'src/utils/i18n';
import { Header } from 'src/ui/Header/Header';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { DescriptionBlock } from 'src/ui/Main/components/DescriptionBlock/DescriptionBlock';
import { NBSP } from 'src/constants/typography';
import { Accordion } from '../Accordion/Accordion';
import { ReactComponent as FoldersIcon } from 'img/main/folders.svg';
import { ReactComponent as CloudIcon } from 'img/main/cloudAdd.svg';
import { ReactComponent as BackupIcon } from 'img/main/backup.svg';
import { ReactComponent as RestoreIcon } from 'img/main/restore.svg';
import disko from 'img/main/disko.png';
import backup from 'img/main/backup.png';
import Helmet from 'react-helmet';
import { isSSR } from 'src/utils/ua-parser';
import classNames from 'clsx';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Services } from 'src/ui/Services/Services';
import { Tariffs } from 'src/ui/Tariffs/Tariffs';
import { Container } from 'src/ui/Container/Container';

const messages = defineMessages({
    metaTitle: 'Диск-О: резервное копирование и объединение облачных сервисов',
    metaDescription:
        'Бесплатная программа, позволяющий подключать разные облачные хранилища и передавать файлы между ними в два клика, а также создавать резервное копирование важных папок и файлов.',
    title: 'Упрощаем работу с облаками{br}и заботимся о ваших файлах',
    titleBlock1: 'Объединение облаков',
    descriptionBlock1: 'Все облачные сервисы в едином интерфейсе',
    block1Item1: 'Работайте с ними как с папками на компьютере',
    block1Item2: 'Не беспокойтесь о заполнении памяти — файлы из облаков не займут места на устройстве',
    titleBlock2: 'Защита файлов',
    descriptionBlock2: 'Резервные копии важных файлов с компьютера в облаках',
    block2Item1: 'Выбирайте сами, что именно вы хотите защитить от потери',
    block2Item2: 'Восстанавливайте файлы, если они удалятся с устройства',
    faqTitle: 'Вопрос-ответ',
    question1: 'Я могу выбрать только одну функцию Диск-О:?',
    answer1: 'Да, функции Диск-О: не связаны между собой. Поэтому вы можете пользоваться как обеими сразу, так и только одной из них.',
    question2: 'Как понять, какая функция мне подойдет?',
    answer2:
        'Если вы регулярно пользуетесь большим числом облаков, то вам стоит подключить Диск-О: как агрегатор облачных сервисов. Единый удобный интерфейс заметно упростит и ускорит вашу работу.{br}{br}Если вы переживаете о сохранности файлов, то вам стоит создать резервные копии важных папок в Диск-О:. Так актуальные версии ваших файлов будут под рукой с любого устройства, и вы всегда сможете вернуть их на компьютер.',
    question3: 'Эти функции платные?',
    answer3:
        'Чтобы использовать Диск-О: как агрегатор облаков и подключать к нему любые сервисы, нужно выбрать один из тарифов и оформить подписку. Но попробовать можно бесплатно! Для этого подключите пробную версию Диск-О: – так вы оцените возможности программы и поймете, подходит ли она вам.{br}{br}Что касается защиты файлов в Диск-О:, то эта функция абсолютно бесплатная! Вы можете создавать любое количество резервных копий и сохранять их в любом из ваших облаков.',
}) as { [key: string]: MessageDescriptor };

const questions: { title: string; content: ReactNode }[] = Array.from(new Array(3).keys()).map((val, index) => ({
    title: i18n.formatMessage(messages[`question${1 + index}`], { nbsp: NBSP }),
    content: i18n.formatMessage(messages[`answer${1 + index}`], { br: <br /> }),
}));

export class MainPageComponent extends PureComponent<RouteComponentProps<{ lang: string }>> {
    componentDidMount(): void {
        sendGa({
            category: 'event',
            action: 'show',
            label: 'mainpage',
        });
    }

    private handleOnButtonClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void => {
        sendGa({
            category: 'event',
            action: 'click',
            label: 'mainpage-' + e.currentTarget.id,
            sendImmediately: true,
        });
    };

    render(): ReactElement {
        const { match } = this.props;
        const language = match.params.lang;
        const baseUrl = language ? `/${language}/` : '/ru/';

        return (
            <div className={classNames({ [styles.root]: true, [styles.rootAnimate]: !isSSR })}>
                <Helmet>
                    <title>{i18n.formatMessage(messages.metaTitle)}</title>
                    <meta name="description" content={i18n.formatMessage(messages.metaDescription)} />
                </Helmet>
                <Container>
                    <div className={styles.header}>
                        <Header isShort whiteButton whiteLogo />
                    </div>
                    <h1 className={styles.title}>
                        <Text size={TextSize['56px']} weight={Weight.w700}>
                            <FormattedMessage {...messages.title} values={{ br: <br /> }} />
                        </Text>
                    </h1>
                    <div className={styles.blocks}>
                        <DescriptionBlock
                            gaId="aggr"
                            title={messages.titleBlock1}
                            description={messages.descriptionBlock1}
                            buttonUrl="/?utm_source=disko&utm_medium=main_page&utm_campaign=button_agregator"
                            Image={<img src={disko} className={styles.disko} alt="" />}
                            onButtonClick={this.handleOnButtonClick}
                        >
                            <div className={styles.items_wrapper}>
                                <div className={styles.item}>
                                    <div className={styles.item_icon}>
                                        <FoldersIcon width={36} height={32} />
                                    </div>
                                    <div className={styles.item_text}>
                                        <FormattedMessage {...messages.block1Item1} />
                                    </div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.item_icon}>
                                        <CloudIcon width={38} height={36} />
                                    </div>
                                    <div className={styles.item_text}>
                                        <FormattedMessage {...messages.block1Item2} />
                                    </div>
                                </div>
                            </div>
                        </DescriptionBlock>
                        <DescriptionBlock
                            gaId="backup"
                            title={messages.titleBlock2}
                            description={messages.descriptionBlock2}
                            buttonUrl={`${baseUrl}backups/?utm_source=disko&utm_medium=main_page&utm_campaign=button_backup`}
                            Image={<img src={backup} className={styles.backup} alt="" />}
                            onButtonClick={this.handleOnButtonClick}
                        >
                            <div className={styles.items_wrapper}>
                                <div className={styles.item}>
                                    <div className={styles.item_icon}>
                                        <BackupIcon width={38} height={31} />
                                    </div>
                                    <div className={styles.item_text}>
                                        <FormattedMessage {...messages.block2Item1} />
                                    </div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.item_icon}>
                                        <RestoreIcon width={35} height={27} />
                                    </div>
                                    <div className={styles.item_text}>
                                        {' '}
                                        <FormattedMessage {...messages.block2Item2} />
                                    </div>
                                </div>
                            </div>
                        </DescriptionBlock>
                    </div>
                </Container>
                <div className={styles.services} id="about">
                    <Container>
                        <Services page="main" />
                    </Container>
                </div>
                <div className={styles.tariffs}>
                    <Tariffs />
                </div>
                <div className={styles.faq}>
                    <Container>
                        <div className={styles.faqTitle}>
                            <Text size={TextSize['44px']} weight={Weight.w700}>
                                <FormattedMessage {...messages.faqTitle} />
                            </Text>
                        </div>
                        <Accordion items={questions} />
                    </Container>
                </div>
            </div>
        );
    }
}

export const MainPage = withRouter(MainPageComponent);

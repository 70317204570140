import React from 'react';
import styles from './Tabs.css';
import { TabTitle } from 'src/ui/HelpPage/Tabs/TabTitle';
import { defineMessages } from 'react-intl';
import { TabName } from 'src/ui/HelpPage/Tabs/TabName';
import { formatMessage, FormattedMessage } from 'src/utils/i18n';
import { TabList } from 'src/ui/HelpPage/Tabs/TabList';
import { Link } from 'src/ui/Link/Link';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { TabText } from 'src/ui/HelpPage/Tabs/TabText';
import { Text, TextSize } from 'src/ui/Text/Text';

const messages = defineMessages({
    name1: 'Оплата',
    name2: 'Подписка на{nbsp}три месяца',
    text1: '590 ₽ (196 ₽ в месяц). Сумма будет списываться раз в три месяца.',
    name3: 'Подписка на{nbsp}год',
    text2: '1 790 ₽ (149 ₽ в месяц). Сумма будет списываться раз в год.',
    text4:
        'Подписка на{nbsp}<nowrap>Диск-О:</nowrap> не{nbsp}влияет на{nbsp}размер загружаемых файлов в{nbsp}Облаке Mail.ru. Если вам необходимо загружать больше 2 ГБ{nbsp}в{nbsp}Облаке, подключите тариф.',
    name4: 'Активация на Windows',
    name5: 'Активация на macOS',
    text5: 'Один лицензионный ключ можно использовать на{nbsp}3 устройствах.',
    name6: 'Бесплатная версия',
    name7: 'Платная версия',
    linkDesc: 'Подробнее о тарифах в Диск-О: - {link}',
    text6:
        'Если вы скачали приложение из App Store, купите подписку на <nowrap>Диск-О:</nowrap> прямо внутри приложения, а не на сайте — нажмите значок настроек → {laquo}Купить полную версию{raquo}. После оплаты тарифа подписка оформится автоматически.',
    text7:
        'Если вы уже купили подписку на сайте и получили лицензионный ключ, перейдите по ссылке <nowrap>disko://activate/ключ</nowrap> (вместо слова {laquo}ключ{raquo} подставьте код, который приходил вам на почту) и нажмите {laquo}Активировать ключ{raquo}.',
});

const payStepsMessages = defineMessages({
    payStep1: 'Перейдите <a>на сайт{nbsp}Диск-О:</a>',
    payStep2: 'Перейдите в{nbsp}раздел <a>Тарифы</a>.',
    payStep3: 'Выберите тариф.',
    payStep4: 'Введите вашу почту. На{nbsp}нее придет лицензионный ключ.',
    payStep5: 'Выберите способ оплаты и введите данные.',
    payStep6: 'Нажмите {laquo}Оплатить{raquo}.',
});

const activateStepsMessages = defineMessages({
    activateStep1: 'Перейдите в{nbsp}почтовый ящик, который вы{nbsp}указали при покупке тарифа.',
    activateStep2: 'Найдите письмо с ключом на{nbsp}<nowrap>Диск-О</nowrap>:, которое пришло после оплаты тарифа.',
    activateStep3: 'Скопируйте лицензионный ключ из{nbsp}письма.',
    activateStep4: 'Откройте приложение <nowrap>Диск-О:</nowrap> на{nbsp}компьютере.',
    activateStep5: 'Нажмите на иконку настроек → {laquo}Активировать{raquo}',
    activateStep6: 'Вставьте ключ в{nbsp}поле и{nbsp}нажмите {laquo}Активировать{raquo}.',
});

const freeFeatureMessages = defineMessages({
    feature1: 'Один аккаунт в Облаке Mail.ru, VK WorkDisk и S3 от MCS — без лимитов на количество загрузок файлов',
    feature2: 'Загрузка файлов в остальные сервисы S3, Яндекс.Диск, Google Drive, Dropbox, OneDrive, Box, pCloud, WebDAV — 10 в сутки',
    feature3: 'Просмотр файлов — без ограничений',
    feature4: 'Удаление файлов — без ограничений',
    feature5: 'Поддержка — ответ в течение 3-х суток',
});

const paidFeatureMessages = defineMessages({
    featurePaid1:
        'Загрузка файлов в Облако Mail.ru, Яндекс.Диск, Google Drive, Dropbox, OneDrive, VK WorkDisk, Box, pCloud, WebDAV, любые сервисы S3 без ограничений',
    featurePaid2: 'Просмотр файлов — без ограничений',
    featurePaid3: 'Удаление файлов — без ограничений',
    featurePaid4: 'Онлайн-поддержка в чате — ежедневно с 9 до 22 по московскому времени',
});

const paySteps = [
    formatMessage(payStepsMessages.payStep1, {
        // eslint-disable-next-line react/display-name
        a: (msg: string) => (
            <Link key={msg} href={`/${getCurrentLanguage()}`}>
                {msg}
            </Link>
        ),
    }),
    formatMessage(payStepsMessages.payStep2, {
        // eslint-disable-next-line react/display-name
        a: (msg: string) => (
            <Link key={msg} href={`/${getCurrentLanguage()}#tariffs`}>
                {msg}
            </Link>
        ),
    }),
    <span key="step3-pay">
        <FormattedMessage {...payStepsMessages.payStep3} />
        <TabName>
            <FormattedMessage {...messages.name2} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text1} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name3} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text2} />
        </TabText>
        <br />
    </span>,
    formatMessage(payStepsMessages.payStep4),
    formatMessage(payStepsMessages.payStep5),
    formatMessage(payStepsMessages.payStep6),
];
const activateSteps = [
    formatMessage(activateStepsMessages.activateStep1),
    formatMessage(activateStepsMessages.activateStep2),
    formatMessage(activateStepsMessages.activateStep3),
    formatMessage(activateStepsMessages.activateStep4),
    formatMessage(activateStepsMessages.activateStep5),
    formatMessage(activateStepsMessages.activateStep6),
];

export const Subscription = ({ title }: { title: string }) => (
    <div className={styles.tab}>
        <TabTitle>{title}</TabTitle>
        <TabName>
            <FormattedMessage {...messages.name6} />
        </TabName>
        <TabList list={Object.values(freeFeatureMessages).map((item) => formatMessage(item))} dashed />
        <TabName>
            <FormattedMessage {...messages.name7} />
        </TabName>
        <TabList list={Object.values(paidFeatureMessages).map((item) => formatMessage(item))} dashed />
        <div className={styles.listItem}>
            <Text size={TextSize['20px']}>
                <FormattedMessage
                    {...messages.linkDesc}
                    values={{
                        link: <a href="https://disk-o.cloud/#tariffs">https://disk-o.cloud/#tariffs</a>,
                    }}
                />
            </Text>
        </div>
        <TabName>
            <FormattedMessage {...messages.name1} />
        </TabName>
        <TabList list={paySteps} ordered />
        <TabText>
            <FormattedMessage {...messages.text4} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name4} />
        </TabName>
        <TabList list={activateSteps} ordered />
        <TabText>
            <FormattedMessage {...messages.text5} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name5} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text6} />
            <br />
            <br />
            <FormattedMessage {...messages.text7} />
        </TabText>
    </div>
);

import React, { ReactElement, ReactNode } from 'react';
import styles from './Tabs.css';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';

export const TabName = ({ children, id }: { children: ReactNode; id?: string }): ReactElement => (
    <div className={styles.name} id={id}>
        <Text size={TextSize['28px']} weight={Weight.w700}>
            {children}
        </Text>
    </div>
);

import React from 'react';
import { Container } from 'src/ui/Container/Container';
import classNames from 'clsx';
import cloudLogo from 'img/cloud-logo.svg';
import cloudLogoEn from 'img/cloud-logo-en.svg';

import styles from './Footer.css';
import { Colors, Link } from 'src/ui/Link/Link';
import { defineMessages, FormattedMessage } from 'react-intl';
import { LineHeight, Text, TextSize, Weight, WhiteSpace } from 'src/ui/Text/Text';
import { isMacOS } from 'src/utils/ua-parser';
import appStore from 'img/appstore.svg';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { AvailableLanguages } from 'src/modules/language/language.types';
import { sendPixel } from 'src/utils/sendPixel';
import { useSelector } from 'react-redux';
import { getMacOsDownloadLink } from 'src/modules/links/links.selectors';
import { RB_PIXEL_URL } from 'src/modules/links/links.consts';

const messages = defineMessages({
    mail: 'Mail.ru',
    about: 'О компании',
    feedback: 'Обратная связь',
    help: 'Помощь',
});

const onMacItemClick = () => sendPixel(RB_PIXEL_URL);

export const Footer = () => {
    const renderItem = (href: string, text: React.ReactNode) => {
        return (
            <div className={styles.item}>
                <Link color={Colors.black} href={href} target="_blank">
                    <Text size={TextSize['17px']} weight={Weight.w500} whiteSpace={WhiteSpace.nowrap} lineHeight={LineHeight['30px']}>
                        {text}
                    </Text>
                </Link>
            </div>
        );
    };

    const lang = getCurrentLanguage();
    const macOsDownloadLink = useSelector(getMacOsDownloadLink);

    return (
        <div className={styles.root}>
            <Container>
                <div className={styles.content}>
                    <img src={lang === AvailableLanguages.ru ? cloudLogo : cloudLogoEn} className={styles.logo} alt="Cloud.Mail.ru" />
                    <div className={styles.menu}>
                        {lang !== AvailableLanguages.ru && renderItem('/legal/disko_privacy', 'Privacy policy')}
                        {lang !== AvailableLanguages.ru && renderItem('/legal/disko_termsofuse', 'Terms of use')}
                        {lang === AvailableLanguages.ru &&
                            renderItem('https://cloud.mail.ru?utm_source=disko&utm_medium=landing&utm_campaign=footer', 'Облако Mail.ru')}
                        {renderItem('https://mail.ru', <FormattedMessage {...messages.mail} />)}
                        {renderItem(
                            `/${lang === AvailableLanguages.ru || lang === AvailableLanguages.en ? lang : 'en'}/faq/`,
                            <FormattedMessage {...messages.help} />
                        )}
                        {lang === AvailableLanguages.ru && renderItem('https://cloud.mail.ru/promo/partners/', 'Партнёрам')}
                        {lang === AvailableLanguages.ru && renderItem('/ru/files/', 'Перенос файлов')}
                    </div>
                    {isMacOS && (
                        <div
                            className={classNames({
                                [styles.item]: true,
                                [styles.item_mac]: true,
                            })}
                        >
                            <Link href={macOsDownloadLink} target="_blank" onClick={onMacItemClick} noPrevent>
                                <img src={appStore} alt="Mac App Store" />
                            </Link>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
};

export interface UnbindState {
    id: string;
    isLoading: boolean;
    success: boolean;
    error: string;
}

export enum UnbindActionTypes {
    REQUEST = '@unbind/request',
    SUCCESS = '@unbind/success',
    FAILURE = '@unbind/failure',
    RESET_ERROR = '@unbind/resetError',
    SHOW_MODAL = '@unbind/showModal',
    RESET_SUCCESS = '@unbind/resetSuccess',
}

export interface UnbindRequestAction {
    type: typeof UnbindActionTypes.REQUEST;
}

export interface UnbindSuccessAction {
    type: typeof UnbindActionTypes.SUCCESS;
}

export interface UnbindFailureAction {
    type: typeof UnbindActionTypes.FAILURE;
    error: string;
}

export interface UnbindShowModalAction {
    type: typeof UnbindActionTypes.SHOW_MODAL;
    id: string;
}

export interface UnbindResetError {
    type: typeof UnbindActionTypes.RESET_ERROR;
}

export interface UnbindResetSuccess {
    type: typeof UnbindActionTypes.RESET_SUCCESS;
}

export type UnbindActions =
    | UnbindFailureAction
    | UnbindRequestAction
    | UnbindSuccessAction
    | UnbindShowModalAction
    | UnbindResetError
    | UnbindResetSuccess;

import React from 'react';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import styles from './BackupsHero.css';
import { FormattedMessage } from 'src/utils/i18n';
import { MessageDescriptor } from 'react-intl';
import { Container } from 'src/ui/Container/Container';
import { Download } from 'src/ui/Download/Download';

interface Props {
    title?: MessageDescriptor;
    text?: MessageDescriptor;
}

export const BackupsHero = ({ title, text }: Props) => {
    return (
        <div className={styles.wrap}>
            <Container>
                <div className={styles.root}>
                    <div className={styles.content}>
                        {title && (
                            <h1 className={styles.title}>
                                <Text size={TextSize['40px']} desktopSize={TextSize['64px']} weight={Weight.w700}>
                                    <FormattedMessage {...title} />
                                </Text>
                            </h1>
                        )}
                        {text && (
                            <div className={styles.text}>
                                <Text size={TextSize['20px']} desktopSize={TextSize['24px']} weight={Weight.w500}>
                                    <FormattedMessage {...text} />
                                </Text>
                            </div>
                        )}
                        <Download />
                    </div>
                    <div className={styles.image}>
                        <div className={styles.folderWrap}>
                            <div className={styles.folder} />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

import React, { ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages } from 'react-intl';
import { TabText } from 'src/ui/HelpPage/Tabs/TabText';
import { TabTitle } from 'src/ui/HelpPage/Tabs/TabTitle';
import styles from './SupportPage.css';
import { FormattedMessage } from 'src/utils/i18n';
import { isSSR } from 'src/utils/ua-parser';

const messages = defineMessages({
    title: 'Здравствуйте!',
    text1: 'Благодарим вас за использование платной версии Диск-О:!',
    text2:
        'Мы сожалеем, что во время вашей работы что-то пошло не так, и хотим помочь вам как можно быстрее. Воспользуйтесь онлайн-чатом в правом нижнем углу, где наши специалисты ответят на любые ваши вопросы ежедневно с 9:00 до 21:00.',
    text3: 'Если вы напишете нам в другое время, мы оформим обращение и свяжемся с вами по почте в начале рабочего дня.',
    text4: 'Вы также можете написать нам на почту {email}',
});

const HDE_DISPLAY_CHAT_KEY = 'HDE_DISPLAY_CHAT';
let hasScript = false;

export const SupportPage = (): ReactElement => {
    useEffect(() => {
        if (!isSSR && localStorage.getItem(HDE_DISPLAY_CHAT_KEY) === null) {
            localStorage.setItem(HDE_DISPLAY_CHAT_KEY, 'true');
            window.location.reload();
        }
    }, []);

    const needScript = !isSSR && !hasScript;
    hasScript = true;

    return (
        <>
            <Helmet>
                {needScript && (
                    <script
                        src="//cdn.helpdeskeddy.com//js/modules/chat_widget/hde.js"
                        data-lang="ru"
                        id="hde-chat-widget"
                        data-host="mrsupport.helpdeskeddy.com"
                        data-position="bottom-right"
                        defer
                    />
                )}
            </Helmet>
            <div className={styles.root}>
                <TabTitle>
                    <FormattedMessage {...messages.title} />
                </TabTitle>
                <TabText>
                    <FormattedMessage {...messages.text1} />
                </TabText>
                <TabText>
                    <FormattedMessage {...messages.text2} />
                </TabText>
                <TabText>
                    <FormattedMessage {...messages.text3} />
                </TabText>
                <TabText>
                    <FormattedMessage
                        {...messages.text4}
                        values={{
                            email: (
                                <a href="mailto:support@disk-o.cloud" target="_blank">
                                    support@disk-o.cloud
                                </a>
                            ),
                        }}
                    />
                </TabText>
            </div>
        </>
    );
};

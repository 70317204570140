import React, { PureComponent, ReactElement, Fragment } from 'react';
import { defineMessages } from 'react-intl';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';
import { Helmet } from 'react-helmet';
import classNames from 'clsx';

import styles from './HelpPage.css';
import { FormattedMessage, i18n } from 'src/utils/i18n';
import { Container } from 'src/ui/Container/Container';
import { find, propEq } from 'lodash/fp';
import { About } from 'src/ui/HelpPage/Tabs/About';
import { Windows } from 'src/ui/HelpPage/Tabs/Windows';
import { OSX } from 'src/ui/HelpPage/Tabs/Osx';
import { Backups } from 'src/ui/HelpPage/Tabs/Backups';
import { Settings } from 'src/ui/HelpPage/Tabs/Settings';
import { Sync } from 'src/ui/HelpPage/Tabs/Sync';
import { Memory } from 'src/ui/HelpPage/Tabs/Memory';
import { Subscription } from 'src/ui/HelpPage/Tabs/Subscription';
import { Button, ButtonColors, ButtonSize } from 'src/ui/Button/Button';
import { SubmitRequest } from 'src/ui/SubmitRequest/SubmitRequest';
import { messages as meta } from 'src/ui/Meta/Meta';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

const messages = defineMessages({
    sidebarTitle: 'Помощь',
    helpTitle: 'Не нашли ответа?',
    helpText:
        'Нам важно, чтобы каждый пользователь <nowrap>Диск-О:</nowrap> остался доволен продуктом. Если вы{nbsp}не{nbsp}нашли ответа на свой вопрос, напишите нам через форму обратной связи, и{nbsp}мы{nbsp}обязательно ответим вам в{nbsp}максимально короткие сроки.',
    helpBtn: 'Задать вопрос',
});

const menuMessages = defineMessages({
    about: 'О программе',
    windows: 'Приложение на Windows',
    mac: 'Приложение на macOS',
    backups: 'Резервные копии',
    settings: 'Настройки приложения',
    sync: 'Синхронизация',
    memory: 'Использование памяти ПК',
    subscription: 'Подписка',
});

interface Tab {
    title: string;
    id: string;
    component: ({ title }: { title: string }) => ReactElement | null;
    isHidden?: boolean;
}

const tabs: Tab[] = [
    {
        title: i18n.formatMessage(menuMessages.about),
        id: 'about',
        component: About,
    },
    {
        title: i18n.formatMessage(menuMessages.windows),
        id: 'app-win',
        component: Windows,
    },
    {
        title: i18n.formatMessage(menuMessages.mac),
        id: 'app-osx',
        component: OSX,
    },
    {
        title: i18n.formatMessage(menuMessages.backups),
        id: 'backups',
        component: Backups,
    },
    {
        title: i18n.formatMessage(menuMessages.settings),
        id: 'settings',
        component: Settings,
    },
    {
        title: i18n.formatMessage(menuMessages.sync),
        id: 'synchronization',
        component: Sync,
    },
    {
        title: i18n.formatMessage(menuMessages.memory),
        id: 'memory',
        component: Memory,
    },
    {
        title: i18n.formatMessage(menuMessages.subscription),
        id: 'subscription',
        component: Subscription,
    },
];

type Props = {} & RouteComponentProps<{ section: string; lang: string }>;

interface State {
    showRequestForm: boolean;
}

class HelpPageComponent extends PureComponent<Props, State> {
    public readonly state = {
        showRequestForm: false,
    };

    private handleSubmitRequestClick = (): void => this.setState({ showRequestForm: true });
    private handleSubmitRequestClose = (): void => this.setState({ showRequestForm: false });

    renderMenu = (tab: Tab): ReactElement => (
        <Link
            to={(location) => location.pathname.replace(this.props.match.params.section, tab.id)}
            key={tab.id}
            className={classNames({
                [styles.menuItem]: true,
                [styles.menuItem_active]: this.props.match.params.section === tab.id,
                [styles.hidden]: tab.isHidden,
            })}
        >
            <Text size={TextSize['17px']} desktopSize={TextSize['20px']}>
                {tab.title}
            </Text>
        </Link>
    );

    renderTab = (): ReactElement | null => {
        const tab = find(propEq('id', this.props.match.params.section), tabs);

        if (!tab) {
            return null;
        }

        return (
            <Fragment>
                <Helmet>
                    <title>
                        {i18n.formatMessage(meta.siteName)} - {tab.title}
                    </title>
                </Helmet>
                <tab.component key={tab.id} title={tab.title} />
            </Fragment>
        );
    };

    render(): ReactElement {
        return (
            <div className={styles.root}>
                {this.state.showRequestForm && <SubmitRequest onClose={this.handleSubmitRequestClose} />}
                <Container>
                    <div className={styles.questions}>
                        <div className={styles.sidebar}>
                            <div className={styles.sidebarTitle}>
                                <Text size={TextSize['32px']} weight={Weight.w700}>
                                    <FormattedMessage {...messages.sidebarTitle} />
                                </Text>
                            </div>
                            <div className={styles.menu}>{tabs.map(this.renderMenu)}</div>
                        </div>
                        <div className={styles.main}>{this.renderTab()}</div>
                    </div>
                </Container>
                <div className={styles.feedback}>
                    <div className={styles.feedbackTitle}>
                        <Text size={TextSize['32px']} desktopSize={TextSize['44px']} weight={Weight.w700} lineHeight={LineHeight.inherit}>
                            <FormattedMessage {...messages.helpTitle} values={{ br: <br key="breakLine" /> }} />
                        </Text>
                    </div>
                    <div className={styles.feedbackContent}>
                        <div className={styles.feedbackText}>
                            <Text size={TextSize['20px']} weight={Weight.w500}>
                                <FormattedMessage {...messages.helpText} />
                            </Text>
                        </div>
                        <Button color={ButtonColors.PRIMARY} size={ButtonSize.M} onClick={this.handleSubmitRequestClick}>
                            <Text size={TextSize['20px']}>
                                <FormattedMessage {...messages.helpBtn} />
                            </Text>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export const HelpPage = withRouter(HelpPageComponent);

import { store as reduxStore } from 'src/store';
import { getOmicronConfig } from 'src/modules/omicron/omicron.selectors';

const enableFeature = (featureName: string, data: any = true): void => {
    const WEEK_MAX_AGE = 3600 * 24 * 7;

    let value = data;

    try {
        value = JSON.stringify(data);
    } catch (_) {}

    document.cookie = `cloudFeature_${featureName}=${encodeURIComponent(value)}; max-age=${WEEK_MAX_AGE}; samesite=strict; path=/`;
    location.reload();
};

const disableFeature = (featureName: string): void => {
    const WEEK_MAX_AGE = 3600 * 24 * 7;
    document.cookie = `cloudFeature_${featureName}=false; max-age=${WEEK_MAX_AGE}; samesite=strict; path=/`;
    location.reload();
};

const resetFeature = (featureName: string): void => {
    document.cookie = `cloudFeature_${featureName}=; max-age=-1; path=/`;
    location.reload();
};

export const getFeaturesByCookie = () => {
    const cookies = document.cookie?.split('; ').map((cookieString) => cookieString.split('='));

    return (cookies || []).reduce((cookieConf, [key, value]) => {
        if (key?.startsWith('cloudFeature_')) {
            const featureName = key?.replace('cloudFeature_', '');

            let data = value;

            try {
                data = JSON.parse(decodeURIComponent(value));
            } catch (_) {}

            cookieConf[featureName] = {
                enabled: Boolean(data),
                params: data || {},
            };
        }

        return cookieConf;
    }, {} as Record<string, { enabled: boolean; params: any }>);
};
getFeaturesByCookie.mapped = (): Record<string, any> =>
    Object.entries(getFeaturesByCookie()).reduce(
        (acc, [key, value]) => ({ ...acc, ...(value.enabled ? { [key]: value.params } : {}) }),
        []
    );

export const setupDebugFeatures = (): void => {
    Object.assign(window, {
        app: {
            enableFeature,
            disableFeature,
            resetFeature,
            settings: {
                features: {
                    cookie: getFeaturesByCookie.mapped(),
                    get omicron() {
                        return getOmicronConfig(reduxStore.getState());
                    },
                },
            },
        },
    });
};

import { Coordinate, Direction } from 'src/ui/DownloadPage/arrow';
import styles from 'src/ui/DownloadPage/DownloadPage.css';
import { ARROW_MARGIN, ARROW_MIN_MARGIN } from 'src/ui/DownloadPage/constants';

export interface Margin {
    bottom?: number;
    left?: number;
    top?: number;
    right?: number;
}

export function getDotFromMargin(margin: Margin): Coordinate {
    let x, y, vector: Direction;
    if (margin.left) {
        x = margin.left;
    } else if (margin.right) {
        x = window.innerWidth - margin.right;
    } else {
        x = window.innerWidth / 2;
    }

    if (margin.top) {
        y = window.scrollY + margin.top;
        vector = 'down';
    } else if (margin.bottom) {
        y = window.scrollY + window.innerHeight - margin.bottom;
        vector = 'up';
    } else {
        y = window.scrollY + window.innerHeight / 2;
        vector = 'down';
    }

    return { x, y, vector };
}

const getLeftBeginningDot = (margin: Margin): Coordinate => {
    const component = document.querySelector(`.${styles.card}`)?.getBoundingClientRect();
    if (!component) {
        return { x: 100, y: window.scrollY + window.innerHeight / 2, vector: 'left' };
    }

    let x = component.x - ARROW_MARGIN;
    let y = window.scrollY + component.y + component.height / 2;
    let vector: Direction = 'left';

    if (margin.left && component.x < margin.left + ARROW_MARGIN + ARROW_MIN_MARGIN) {
        x = component.x + ARROW_MARGIN;
        y = window.scrollY + component.y + component.height + ARROW_MARGIN;
        vector = 'down';
    } else if (margin.left && x < margin.left + ARROW_MARGIN) {
        x = margin.left + ARROW_MARGIN;
    }

    return { x, y, vector };
};

const getRightBeginningDot = (margin: Margin): Coordinate => {
    const component = document.querySelector(`.${styles.title} *`)?.getBoundingClientRect();
    if (!component) {
        return { x: window.innerWidth - 100, y: window.scrollY + window.innerHeight / 2, vector: 'right' };
    }

    let x = component.x + component.width + ARROW_MARGIN;
    let y = window.scrollY + component.y + component.height / 2;
    let vector: Direction = 'right';

    if (margin.right && component.x + component.width > window.innerWidth - (margin.right + ARROW_MARGIN + ARROW_MIN_MARGIN)) {
        x = component.x + component.width - ARROW_MARGIN;
        y = window.scrollY + component.y - ARROW_MARGIN;
        vector = 'up';
    } else if (margin.right && x > window.innerWidth - margin.right - ARROW_MARGIN) {
        x = window.innerWidth - margin.right - ARROW_MARGIN;
    }

    return { x, y, vector };
};

export const getBeginningDot = (margin: Margin): Coordinate => {
    if (margin.right) {
        return getRightBeginningDot(margin);
    } else if (margin.left) {
        return getLeftBeginningDot(margin);
    }

    return {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
        vector: 'right',
    };
};

import React, { ReactElement } from 'react';
import classNames from 'clsx';
import { Container } from 'src/ui/Container/Container';
import styles from './ServiceDownload.css';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';
import { Download } from 'src/ui/Download/Download';

export const ServiceDownload = ({
    title,
    description,
    descriptionMore,
}: {
    title: ReactElement;
    description: ReactElement;
    descriptionMore?: ReactElement;
}): ReactElement => (
    <Container>
        <div className={styles.root}>
            <div className={styles.title}>
                <Text size={TextSize['40px']} lineHeight={LineHeight.inherit} weight={Weight.w700}>
                    {title}
                </Text>
            </div>
            <div className={styles.content}>
                <div className={styles.text}>
                    <Text size={TextSize['20px']} lineHeight={LineHeight['30px']}>
                        {description}
                    </Text>
                </div>
                <Download />
                {!!descriptionMore && (
                    <div className={classNames(styles.text, styles.descriptionMore)}>
                        <Text size={TextSize['20px']} lineHeight={LineHeight['30px']}>
                            {descriptionMore}
                        </Text>
                    </div>
                )}
            </div>
        </div>
    </Container>
);

import { Currency, TariffMods } from 'src/modules/tariffs/tariffs.types';

export const mapTariffsByTariffMode: {
    [key: string]: {
        tariffIdPrefix?: string;
        [Currency.EUR]?: string[];
        [Currency.USD]?: string[];
        [Currency.RUB]?: string[];
    };
} = {
    [TariffMods.none]: {
        [Currency.EUR]: ['disko1y_v2_eur', 'disko3m_v2_eur'],
        [Currency.USD]: ['disko1y_v2_usd', 'disko3m_v2_usd'],
        [Currency.RUB]: ['disko1y_v2', 'disko3m_v2'],
    },
    [TariffMods.trials]: {
        [Currency.EUR]: ['disko1m_trial1m_eur'],
        [Currency.USD]: ['disko1m_trial1m_usd'],
        [Currency.RUB]: ['disko1m_trial1m'],
    },
    [TariffMods.trials3m]: {
        [Currency.USD]: ['disko3m_trial3m_usd'],
        [Currency.RUB]: ['disko3m_trial3m'],
    },
    [TariffMods.trials1mYear]: {
        [Currency.RUB]: ['disko1y_trial1m'],
    },
    [TariffMods.bundle10]: {
        [Currency.RUB]: ['disko1y_discount10_rub', 'disko3m_v2'],
    },
    [TariffMods.bundle20]: {
        [Currency.RUB]: ['disko1y_discount20_rub', 'disko3m_v2'],
    },
    [TariffMods.cloud128]: {
        [Currency.RUB]: ['disko1y_cloud128_rub', 'disko1y_v2'],
    },
    [TariffMods.cloud256]: {
        [Currency.RUB]: ['disko1y_cloud256_rub', 'disko1y_v2'],
    },
    [TariffMods.costDecrease]: {
        [Currency.RUB]: ['disko1y_v3', 'disko3m_v2'],
    },
    [TariffMods.action]: {
        tariffIdPrefix: 'disko1y_newyear23_',
        [Currency.EUR]: ['disko1y_newyear23_eur', 'disko3m_v2_eur'],
        [Currency.USD]: ['disko1y_newyear23_usd', 'disko3m_v2_usd'],
        [Currency.RUB]: ['disko1y_newyear23_rub', 'disko3m_v2'],
    },
};

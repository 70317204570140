import { API } from 'src/modules/api/api';

export const unlinkPerform = (id: string): Promise<Response> =>
    new API().makeRequest({
        method: 'post',
        url: 'unlink/perform',
        body: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            unlink_id: id,
        },
    });

import { TariffLoadActionTypes, TariffLoadTypes, TariffsState } from 'src/modules/tariffs/tariffs.types';
import { values } from 'lodash/fp';

const INITIAL_STATE: TariffsState = {
    list: {},
    isLoading: false,
    hasError: false,
};

const ONE_M = 31;
const ONE_Y = 365;

const getDurationStr = (duration: number): string => {
    let durationValue = duration / 60 / 60 / 24;
    let durationName = 'd';

    if (durationValue < ONE_Y && durationValue >= ONE_M) {
        durationValue = Math.ceil(durationValue / ONE_M);
        durationName = 'm';
    } else if (durationValue >= ONE_Y) {
        durationValue = Math.ceil(durationValue / ONE_Y);
        durationName = 'y';
    } else if (durationValue < 1) {
        // если меньше 1 дня то вероятно это тестовый тариф
        durationValue = 1;
        durationName = 'y';
    }

    return `${durationValue}${durationName}`;
};

export const tariffReducer = (state = INITIAL_STATE, action: TariffLoadTypes): TariffsState => {
    switch (action.type) {
        case TariffLoadActionTypes.REQUEST:
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        case TariffLoadActionTypes.FAILURE:
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        case TariffLoadActionTypes.SUCCESS: {
            const list = values(action.list).reduce((acc, cur) => {
                const { duration, price, extra, plan: id } = cur || {};
                const { enabled, currency, has_trial_period: hasTrialPeriod, trial_period: trialPeriod, discount, cloud_gift } =
                    extra || {};
                const discountPrice = discount?.price;

                return {
                    ...acc,
                    [cur.plan]: {
                        duration,
                        durationStr: getDurationStr(duration),
                        price: discountPrice ?? price,
                        id,
                        enabled: Boolean(enabled),
                        currency,
                        trialPeriod: hasTrialPeriod ? trialPeriod : null,
                        oldPrice: discountPrice ? price : null,
                        cloudGift: cloud_gift,
                    },
                };
            }, {});

            return {
                ...state,
                list,
                isLoading: false,
                hasError: false,
            };
        }
        default:
            return state;
    }
};

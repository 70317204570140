import React, { FC, ReactElement, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabTitle } from 'src/ui/HelpPage/Tabs/TabTitle';
import styles from './Tabs.css';
import { defineMessages } from 'react-intl';
import { TabName } from 'src/ui/HelpPage/Tabs/TabName';
import { formatMessage, FormattedMessage } from 'src/utils/i18n';
import { TabList } from 'src/ui/HelpPage/Tabs/TabList';
import { Link } from 'src/ui/Link/Link';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { TabText } from 'src/ui/HelpPage/Tabs/TabText';
import folderIcon from 'img/icons/folder.svg';
import playIcon from 'img/icons/play.svg';
import settingsIcon from 'img/icons/settings.svg';
import { showDownloadPromo } from 'src/modules/downloadPromo/downloadPromo.actions';
import { getDownloadUrlGetter } from 'src/modules/links/links.selectors';

const messages = defineMessages({
    name1: 'Установка',
    warningInstall:
        'Приложение требует прав администратора, потому что оно работает с{nbsp}файловой системой компьютера. Если у{nbsp}вас нет таких прав, обратитесь к{nbsp}системному администратору.',
    name2: 'Добавить диск',
    text1: 'После того как закончится синхронизация, откроется окно с{nbsp}облаком, и{nbsp}вы{nbsp}увидите содержимое своего диска.',
    name3: 'Действия с диском',
    text2: 'Наведите на{nbsp}область с{nbsp}диском, чтобы увидеть доступные действия.',
    diskFolder: 'Перейти в облачный диск на компьютере',
    diskSettings: 'Настройки облачного диска',
    diskOn: 'Включить облачный диск (если отключен)',
});

const installListMessages = defineMessages({
    installStep1:
        '<b>Скачайте</b> установщик <a>на{nbsp}сайте</a>. <nowrap>Диск-О:</nowrap> работает на{nbsp}Windows 7 Service Pack 1 и{nbsp}выше.',
    installStep2: 'Щелкните дважды по{nbsp}иконке установщика.',
    installStep3: 'Нажмите {laquo}Установить{raquo}.',
    installStep4: 'Введите пароль от{nbsp}компьютера.',
});

const diskListMessages = defineMessages({
    diskStep1: 'Если у{nbsp}вас еще не{nbsp}добавлены диски, нажмите кнопку {laquo}Добавить диск{raquo}.',
    diskStep2: 'Выберите облачный сервис, на{nbsp}котором располагаются ваши файлы.',
    diskStep3:
        'Введите адрес электронной почты и{nbsp}пароль, либо подтвердите права доступа <nowrap>Диск-О:</nowrap> в{nbsp}окне браузера. Не{nbsp}переживайте, <nowrap>Диск-О:</nowrap> никому не{nbsp}передает ваши пароли, авторизация для вас безопасна.',
});

const diskList = [
    formatMessage(diskListMessages.diskStep1),
    formatMessage(diskListMessages.diskStep2),
    formatMessage(diskListMessages.diskStep3),
];

interface WindowsComponentProps {
    title: string;
}

export const Windows: FC<WindowsComponentProps> = ({ title }) => {
    const dispatch = useDispatch();
    const getDownloadUrl = useSelector(getDownloadUrlGetter);

    const onDownloadClick = useCallback((): void => {
        window.open(getDownloadUrl({ isMac: false }));

        dispatch(showDownloadPromo());
    }, []);

    const installList = useMemo(
        () => [
            formatMessage(installListMessages.installStep1, {
                // eslint-disable-next-line react/display-name
                a: (msg: string): ReactElement => <Link href={`/${getCurrentLanguage()}`}>{msg}</Link>,
                // eslint-disable-next-line react/display-name
                b: (msg: string): ReactElement => <Link onClick={onDownloadClick}>{msg}</Link>,
            }),
            formatMessage(installListMessages.installStep2),
            formatMessage(installListMessages.installStep3),
            formatMessage(installListMessages.installStep4),
        ],
        []
    );

    return (
        <div className={styles.tab}>
            <TabTitle>{title}</TabTitle>
            <TabName>
                <FormattedMessage {...messages.name1} />
            </TabName>
            <TabList list={installList} ordered />
            <TabText>
                <FormattedMessage {...messages.warningInstall} />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name2} />
            </TabName>
            <TabList list={diskList} ordered />
            <TabText>
                <FormattedMessage {...messages.text1} />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name3} />
            </TabName>
            <TabText>
                <FormattedMessage {...messages.text2} />
            </TabText>
            <TabText>
                <img width={16} height={16} className={styles.listIcon} src={folderIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.diskFolder} />
                </span>
            </TabText>
            <TabText>
                <img width={16} height={16} className={styles.listIcon} src={settingsIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.diskSettings} />
                </span>
            </TabText>
            <TabText>
                <img width={16} height={16} className={styles.listIcon} src={playIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.diskOn} />
                </span>
            </TabText>
        </div>
    );
};

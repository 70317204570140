import React, { useCallback } from 'react';
import { LineHeight, Text, TextSize, Weight, WhiteSpace } from 'src/ui/Text/Text';
import { sendGa } from 'src/utils/ga';
import classNames from 'clsx';

import styles from './Service.css';

export enum EColor {
    NEAR_GHOST = 'nearGhost',
    WHITE = 'white',
}

interface Props {
    name: string;
    href?: string;
    id: string;
    logo: string;
    color?: EColor;
}

export const Service = (props: Props): JSX.Element => {
    const { name, href, id, logo, color = EColor.WHITE } = props;

    const handleClick = useCallback((event: { currentTarget: { id: string } }): void => {
        sendGa({
            category: 'services',
            action: 'click',
            label: event?.currentTarget?.id,
        });
    }, []);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`root_color_${color}`]]: color,
            })}
        >
            <a className={styles.service} key={id} id={id} onClick={handleClick} target="_blank" href={href}>
                <div className={styles.serviceTooltip}>
                    <Text size={TextSize['17px']} lineHeight={LineHeight.inherit} weight={Weight.w500} whiteSpace={WhiteSpace.nowrap}>
                        {name}
                    </Text>
                </div>
                <div className={styles.serviceWrapper}>
                    <img src={logo} className={styles.serviceIcon} alt={name} />
                </div>
            </a>
        </div>
    );
};

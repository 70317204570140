import React, { createRef, FormEvent, PureComponent, ReactElement } from 'react';
import styles from './SubmitRequest.css';
import classNames from 'clsx';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { ReactComponent as InfoIcon } from 'img/icons/info.svg';
import { ReactComponent as AttachIcon } from 'img/icons/attach.svg';
import { ReactComponent as CloseIcon } from 'img/icons/close.svg';
import { Modal } from 'src/ui/Modal/Modal';
import { Button, ButtonColors } from 'src/ui/Button/Button';
import { Text, TextSize, Weight, WhiteSpace } from 'src/ui/Text/Text';
import { i18n } from 'src/utils/i18n';
import { sendGa } from 'src/utils/ga';
import { submitHelpRequest } from 'src/modules/api/submitHelpRequest';
import { validateEmail } from 'src/utils/email.helpers';
import { isMobile } from 'src/modules/media/media.selectors';
import { AppState } from 'src/store';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';

const messages = defineMessages({
    title: 'Отправить сообщение',
    labelName: 'Ваше имя',
    labelEmail: 'Ваш email',
    labelLicense: 'Лицензионный ключ',
    labelDescription: 'Сообщение',
    submit: 'Отправить',
    errorWrongEmail: 'Неверный адрес',
    error: 'Произошла ошибка',
    errorRequiredEmail: 'Укажите адрес',
    errorRequiredName: 'Укажите имя',
    errorRequiredDescription: 'Введите сообщение',
    useEnLanguage: 'Пожалуйста, пишите на английском',
    errorApiError: 'Ошибка!',
    labelAttach: 'Прикрепить файл',
});

const ERRORS = {
    error: i18n.formatMessage(messages.error),
    requiredEmail: i18n.formatMessage(messages.errorRequiredEmail),
    wrongEmail: i18n.formatMessage(messages.errorWrongEmail),
    requiredName: i18n.formatMessage(messages.errorRequiredName),
    requiredDescription: i18n.formatMessage(messages.errorRequiredDescription),
    apiError: i18n.formatMessage(messages.errorApiError),
};

const gaCategorySubmit = 'submit-request';

interface MapProps {
    isMobile?: boolean;
}

interface Props extends MapProps {
    onClose: Function;
}

interface State {
    [key: string]: string | object | boolean;
    error: { [key: string]: string };
    email: string;
    name: string;
    license: string;
    description: string;
    isLoading: boolean;
    attachFileName: string;
}

const mapStateToProps = (state: AppState): MapProps => ({
    isMobile: isMobile(state),
});

export class SubmitRequestComponent extends PureComponent<Props, State> {
    public readonly state: State = {
        error: {},
        name: '',
        email: '',
        license: '',
        description: '',
        isLoading: false,
        attachFileName: '',
    };

    inputFilesRef = createRef<HTMLInputElement>();

    componentDidMount() {
        sendGa({
            category: gaCategorySubmit,
            action: 'show',
        });
    }

    private handleOnSubmitClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent> | FormEvent): void => {
        e.preventDefault();

        let attachment = null;
        if (
            this.inputFilesRef.current &&
            this.inputFilesRef.current.files &&
            this.inputFilesRef.current.files[0] &&
            this.state.attachFileName
        ) {
            attachment = this.inputFilesRef.current.files[0];
        }
        const { name, email, license, description } = this.state;
        if (!name) {
            this.setState({ error: { name: ERRORS.requiredName } });
            return;
        }
        if (!email || !validateEmail(email)) {
            this.setState({
                error: { email: !email ? ERRORS.requiredEmail : ERRORS.wrongEmail },
            });
            return;
        }
        if (!description) {
            this.setState({ error: { description: ERRORS.requiredDescription } });
            return;
        }
        sendGa({
            category: gaCategorySubmit,
            action: 'send',
            label: 'click',
        });
        submitHelpRequest({
            attachment,
            name,
            email,
            license,
            description,
        })
            .then(() => {
                sendGa({
                    category: gaCategorySubmit,
                    action: 'send',
                    label: 'ok',
                });
                this.props.onClose();
            })
            .catch(() => {
                this.setState({ error: { description: ERRORS.apiError } });
                sendGa({
                    category: gaCategorySubmit,
                    action: 'send',
                    label: 'error',
                });
            });
    };

    private handleOnResetError = (event: { target: { id: string }; persist: () => void }): void => {
        event.persist();

        this.setState((prevState) => ({ error: { ...prevState.error, [event.target.id]: '' } }));
    };

    private handleOnChange = (event: { target: { id: string; value: string } }): void =>
        this.setState({ [event.target.id]: event.target.value });

    private handleAttachFile = (event: any): void => this.setState({ attachFileName: event.target.value.split('\\').pop() });

    private handleResetFile = (event: any): void => {
        if (!this.state.attachFileName) {
            return;
        }
        event.preventDefault();
        this.setState({ attachFileName: '' });
    };

    private renderLabelFor = (forId: string, message: MessageDescriptor): React.ReactElement => (
        <label htmlFor={forId}>
            <Text size={TextSize['13px']} weight={Weight.w700}>
                <FormattedMessage {...message} />
            </Text>
        </label>
    );

    private renderInput = (id: string, errorName: string, type = 'text', autoFocus = false): React.ReactElement => (
        <div className={styles.inputWrap}>
            <input
                className={classNames({
                    [styles.input]: true,
                    [styles.input_error]: errorName,
                })}
                type={type}
                id={id}
                onChange={this.handleOnResetError}
                onBlur={this.handleOnChange}
                autoFocus={autoFocus}
            />
            {!!errorName && (
                <div className={styles.error}>
                    <Text size={TextSize['15px']} weight={Weight.w500}>
                        {errorName}
                    </Text>
                </div>
            )}
        </div>
    );

    render(): ReactElement {
        const { attachFileName, error, isLoading } = this.state;
        const { isMobile } = this.props;

        return (
            <Modal onClose={this.props.onClose} header={<FormattedMessage {...messages.title} />}>
                <form className={styles.root} onSubmit={this.handleOnSubmitClick}>
                    {getCurrentLanguage() !== 'ru' && (
                        <div className={styles.attention}>
                            <div className={styles.info}>
                                <InfoIcon />
                            </div>
                            <Text size={TextSize['17px']} weight={Weight.w500}>
                                <FormattedMessage {...messages.useEnLanguage} />
                            </Text>
                        </div>
                    )}
                    <div className={styles.controlsRow}>
                        <div>
                            {this.renderLabelFor('name', messages.labelName)}
                            {this.renderInput('name', error.name, 'text', true)}
                        </div>

                        <div>
                            {this.renderLabelFor('email', messages.labelEmail)}
                            {this.renderInput('email', error.email, 'email')}
                        </div>
                    </div>

                    {this.renderLabelFor('license', messages.labelLicense)}
                    {this.renderInput('license', error.license)}

                    {this.renderLabelFor('description', messages.labelDescription)}
                    <div className={styles.inputWrap}>
                        <textarea
                            data-required="true"
                            className={classNames({
                                [styles.text_area]: true,
                                [styles.input_error]: error.description,
                            })}
                            id="description"
                            onChange={this.handleOnResetError}
                            onBlur={this.handleOnChange}
                        />
                        {!!error.description && (
                            <div className={styles.error}>
                                <Text size={TextSize['15px']} weight={Weight.w500}>
                                    {error.description}
                                </Text>
                            </div>
                        )}
                    </div>

                    <div className={styles.fileBlock}>
                        <label className={styles.fileLabel} htmlFor="support-attachment" onClick={this.handleResetFile}>
                            <AttachIcon width={14} height={14} />
                            {!attachFileName && (
                                <div className={styles.fileDescription}>
                                    <Text size={TextSize['15px']}>
                                        <FormattedMessage {...messages.labelAttach} />
                                    </Text>
                                </div>
                            )}
                            {!!attachFileName && (
                                <div className={classNames(styles.fileDescription)}>
                                    <Text size={TextSize['15px']} whiteSpace={WhiteSpace.nowrap}>
                                        {attachFileName}
                                    </Text>
                                </div>
                            )}
                            {!!attachFileName && (
                                <div className={classNames(styles.deleteFile)}>
                                    <CloseIcon width={8} height={8} />
                                </div>
                            )}
                        </label>
                        {!attachFileName && (
                            <input
                                id="support-attachment"
                                type="file"
                                className={styles.file}
                                placeholder=""
                                ref={this.inputFilesRef}
                                onChange={this.handleAttachFile}
                            />
                        )}
                    </div>

                    <div className={styles.footer}>
                        <Button color={ButtonColors.PRIMARY} onClick={this.handleOnSubmitClick} disabled={isLoading} fluid={isMobile}>
                            <Text size={TextSize['20px']}>
                                <FormattedMessage {...messages.submit} />
                            </Text>
                        </Button>
                    </div>
                </form>
            </Modal>
        );
    }
}

export const SubmitRequest = connect(mapStateToProps)(SubmitRequestComponent);

import { UnbindState } from 'src/modules/unbind/unbind.types';
import { AppState } from 'src/store';

const getUnbindState = (state: AppState): UnbindState => state.unbind;

export const getUnbindId = (state: AppState): string => getUnbindState(state).id;

export const getUnbindError = (state: AppState): string => getUnbindState(state).error;

export const isSuccessUnbind = (state: AppState): boolean => getUnbindState(state).success;

import React, { ReactElement } from 'react';

import styles from './Tabs.css';
import { TabTitle } from 'src/ui/HelpPage/Tabs/TabTitle';
import { TabName } from 'src/ui/HelpPage/Tabs/TabName';
import { TabText } from 'src/ui/HelpPage/Tabs/TabText';
import { formatMessage, FormattedMessage } from 'src/utils/i18n';
import { TabList } from 'src/ui/HelpPage/Tabs/TabList';
import { TabImage } from 'src/ui/HelpPage/Tabs/TabImage';
import backupsRu1 from 'img/help/backupsRu1.png';
import backupsEn1 from 'img/help/backupsEn1.png';
import backupsRu2 from 'img/help/backupsRu2.png';
import backupsEn2 from 'img/help/backupsEn2.png';
import backupsRu3 from 'img/help/backupsRu3.png';
import backupsEn3 from 'img/help/backupsEn3.png';
import backupsRu4 from 'img/help/backupsRu4.png';
import backupsEn4 from 'img/help/backupsEn4.png';
import backupsRu5 from 'img/help/backupsRu5.png';
import backupsEn5 from 'img/help/backupsEn5.png';
import backupsRu6 from 'img/help/backupsRu6.png';
import backupsEn6 from 'img/help/backupsEn6.png';
import backupsRu7 from 'img/help/backupsRu7.png';
import backupsEn7 from 'img/help/backupsEn7.png';
import backupsRu8 from 'img/help/backupsRu8.png';
import { defineMessages } from 'react-intl';
import { isRussianLanguage } from 'src/modules/language/language.selectors';

const cloudsListMessages = defineMessages({
    cloudsList1: 'Облако Mail.ru',
    cloudsList2: 'Яндекс.Диск',
    cloudsList3: 'S3 от MCS',
    cloudsList4: 'Любое хранилище S3',
});

const cloudsList = [
    formatMessage(cloudsListMessages.cloudsList1),
    formatMessage(cloudsListMessages.cloudsList2),
    'Google Drive',
    'Dropbox',
    'OneDrive',
    'VK WorkDisk',
    formatMessage(cloudsListMessages.cloudsList3),
    'Box',
    'pCloud',
    'WebDAV',
    'Amazon S3',
    formatMessage(cloudsListMessages.cloudsList4),
];

const isRu = isRussianLanguage();

const picture = {
    backups1: isRu ? backupsRu1 : backupsEn1,
    backups2: isRu ? backupsRu2 : backupsEn2,
    backups3: isRu ? backupsRu3 : backupsEn3,
    backups4: isRu ? backupsRu4 : backupsEn4,
    backups5: isRu ? backupsRu5 : backupsEn5,
    backups6: isRu ? backupsRu6 : backupsEn6,
    backups7: isRu ? backupsRu7 : backupsEn7,
    backups8: backupsRu8,
};

const messages = defineMessages({
    name1: 'Что такое резервные копии в Диск-О:?',
    text1: 'Это актуальная копия всего содержимого выбранных папок с ПК на любое из перечисленных облачных сервисов:',
    name2: 'Как Диск-О: поддерживает актуальность резервных копий?',
    text2:
        'Диск-О: отслеживает любое изменение файлов в указанных папках для резервного копирования. Это происходит автоматически, без расписания, практически в режиме реального времени.',
    text3:
        'Важно! Не убирайте Диск-О: из автозагрузки, иначе приложение не сможет поддерживать актуальность ваших данных в облачном сервисе.',
    name3: 'Как создать резервные копии?',
    text4: 'При запуске Диск-О: нажмите на кнопку “Защитите файлы”',
    text5: 'Если у вас уже есть подключенные резервные копии, то воспользуйтесь пунктом “Защитить данные” в соответствующей закладке',
    text6: 'Укажите папки, которые надо сохранять в резервной копии и придумайте название для вашей резервной копии',
    text7: 'Укажите облачный сервис, в который вы будете сохранять резервные копии. Авторизуйтесь в нем',
    text8:
        'Если места для создания резервной копии достаточно, Диск-О: автоматически создаст резервную копию на указанном облачном сервисе и будет его поддерживать в актуальном состоянии',
    name4: 'Как восстановить резервные копии на ПК?',
    text9: 'Если вы только установили Диск-О:, то воспользуйтесь ссылкой “у меня уже есть бэкап в облаке”',
    text10: 'Если вы уже пользуетесь Диск-О:, то перейдите по ссылке “восстановить данные из копии”',
    text11: 'Укажите облачный сервис, в котором находится резервная копия. Авторизуйтесь в нем',
    text12: 'Подтвердите место, куда надо восстановить копию или укажите новое место восстановления',
    name5: 'Версионирование резервных копий',
    text13: 'Для экономии места в облачных сервисах, Диск-О: не создает версии файлов из резервной копии',
    text14:
        'Однако, большинство облачных сервисов поддерживают версионирование на своей стороне. Для подробной информации обратитесь к справке вашего облачного сервиса',
    name6: 'Как найти резервные копии в моем облачном сервисе?',
    text15: 'Резервные копии сохраняются в /Backups/название_резервной_копии',
    name7: 'Что будет, если я изменю файл из резервной копии на облачном сервисе?',
    text16: 'Диск-О: автоматически обновит файл на тот, который находится у вас на ПК',
    name8: 'Что будет, если я удалю файл из папки, которая сохраняется в резервную копию?',
    text17:
        'Диск-О: спросит, надо ли удалять его и из резервной копии на облачном сервисе или оставить там. Ваш выбор можно запомнить и Диск-О: больше не будет спрашивать вас',
});

export const Backups = ({ title }: { title: string }): ReactElement => (
    <div className={styles.tab}>
        <TabTitle>{title}</TabTitle>
        <TabName>
            <FormattedMessage {...messages.name1} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text1} />
            <TabList list={cloudsList} ordered />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name2} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text2} />
        </TabText>
        <TabText>
            <FormattedMessage {...messages.text3} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name3} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text4} />
        </TabText>
        <TabImage src={picture.backups1} />
        <TabText>
            <FormattedMessage {...messages.text5} />
        </TabText>
        <TabImage src={picture.backups2} />
        <TabText>
            <FormattedMessage {...messages.text6} />
        </TabText>
        <TabImage src={picture.backups3} />
        <TabText>
            <FormattedMessage {...messages.text7} />
        </TabText>
        <TabImage src={picture.backups4} />
        <TabImage src={picture.backups5} />
        <TabText>
            <FormattedMessage {...messages.text8} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name4} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text9} />
        </TabText>
        <TabImage src={picture.backups6} />
        <TabText>
            <FormattedMessage {...messages.text10} />
        </TabText>
        <TabImage src={picture.backups7} />
        <TabText>
            <FormattedMessage {...messages.text11} />
        </TabText>
        <TabImage src={picture.backups4} />
        <TabImage src={picture.backups5} />
        <TabText>
            <FormattedMessage {...messages.text12} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name5} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text13} />
        </TabText>
        <TabText>
            <FormattedMessage {...messages.text14} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name6} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text15} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name7} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text16} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name8} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text17} />
        </TabText>
        {isRu && <TabImage src={picture.backups8} />}
    </div>
);

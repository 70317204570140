import React, { PureComponent, ReactElement } from 'react';
import classNames from 'clsx';
import styles from './HowItWorks.css';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { Download } from 'src/ui/Download/Download';
import { Player } from 'src/ui/Player/Player';

interface Props {
    title?: ReactElement;
    steps: { text: ReactElement | string; step: number }[];
    videoUrl: string;
    poster: string;
    showDownload?: boolean;
    secondBg?: boolean;
    hideBg?: boolean;
    reversed?: boolean;
    newPosterMod?: boolean;
    wideVideoPreview?: boolean;
}

export class HowItWorks extends PureComponent<Props> {
    render(): ReactElement {
        const { secondBg, reversed, hideBg, title, steps, poster, videoUrl, newPosterMod, showDownload, wideVideoPreview } = this.props;

        return (
            <div
                className={classNames({
                    [styles.wrap]: true,
                    [styles.wrap_secondBg]: secondBg,
                })}
            >
                <div
                    id="how-it-works"
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_reversed]: !!reversed,
                        [styles.root_hideBg]: !!hideBg,
                        [styles.root_wideVideoPreview]: !!wideVideoPreview,
                    })}
                >
                    <div className={styles.content}>
                        <div className={styles.left}>
                            {title && (
                                <div className={styles.title}>
                                    <Text desktopSize={TextSize['44px']} size={TextSize['32px']} weight={Weight.w700}>
                                        {title}
                                    </Text>
                                </div>
                            )}
                            <div className={styles.list}>
                                {steps.map(({ step, text }) => (
                                    <div className={styles.item} key={step}>
                                        <div className={styles.count}>
                                            <Text size={TextSize['24px']} weight={Weight.w700}>
                                                {step}
                                            </Text>
                                        </div>
                                        <div className={styles.text}>
                                            <Text size={TextSize['17px']} tabletSize={TextSize['20px']} weight={Weight.w500}>
                                                {text}
                                            </Text>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {showDownload && (
                                <div className={styles.download}>
                                    <Download />
                                </div>
                            )}
                        </div>
                        <div className={classNames(styles.right, { [styles.right_newPosterMod]: newPosterMod })}>
                            <div className={classNames(styles.player, { [styles.player_newPosterMod]: newPosterMod })}>
                                <Player
                                    poster={poster}
                                    videoUrl={videoUrl}
                                    noPosterBackground={newPosterMod}
                                    wideVideoPreview={wideVideoPreview}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import { connect } from 'react-redux';
import React, { PureComponent, ReactElement } from 'react';
import styles from './TopBanner.css';
import classNames from 'clsx';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';
import { Button, ButtonColors, ButtonPadding, ButtonRadius } from 'src/ui/Button/Button';
import { AppState } from 'src/store';
import { isMobile, isPhone } from 'src/modules/media/media.selectors';
import { getTariffModFromFeatures } from 'src/modules/tariffs/tariffs.selectors';
import { defineMessages, FormattedMessage } from 'react-intl';
import { TariffMods } from 'src/modules/tariffs/tariffs.types';

const messages = defineMessages({
    title: 'Подписка за полцены!',
    text: 'Новый год в Диск-О:',
    buttonText: 'Подключить',
});

interface State {
    show: boolean;
}

interface MapProps {
    isMobile: boolean;
    isPhone: boolean;
    tariffMod: TariffMods | null;
}

interface Props {
    clickUrl: string;
}

const mapStateToProps = (state: AppState): MapProps => ({
    isMobile: isMobile(state),
    isPhone: isPhone(state),
    tariffMod: getTariffModFromFeatures(),
});

export class TopBannerComponent extends PureComponent<MapProps & Props, State> {
    render(): ReactElement | null {
        const { tariffMod: mod, isPhone, clickUrl } = this.props;

        // Для акций:
        const isShown = mod === TariffMods.action;
        if (!isShown) {
            return null;
        }

        // Если акция только на РУ
        // if (!isRussianLanguage()) {
        //     return null;
        // }

        return (
            <a
                href={clickUrl}
                className={classNames({
                    [styles.root]: true,
                    [styles[`root_${mod}`]]: Boolean(mod),
                    [styles.root_phone]: Boolean(isPhone),
                })}
            >
                <div className={styles.text}>
                    <Text size={TextSize['20px']} lineHeight={LineHeight['26px']} weight={Weight.w500}>
                        <FormattedMessage
                            {...messages.title}
                            values={{
                                br: <br />,
                            }}
                        />
                    </Text>

                    {!isPhone && (
                        <Text size={TextSize['20px']} weight={Weight.w400} lineHeight={LineHeight['26px']}>
                            <FormattedMessage
                                {...messages.text}
                                values={{
                                    br: <br />,
                                }}
                            />
                        </Text>
                    )}
                </div>
                {!isPhone && (
                    <div className={styles.buttonWrapper}>
                        <Button color={ButtonColors.PRIMARY} radius={ButtonRadius.r8} padding={ButtonPadding.large}>
                            <Text size={TextSize['20px']} weight={Weight.w500}>
                                <FormattedMessage {...messages.buttonText} />
                            </Text>
                        </Button>
                    </div>
                )}
                <div className={styles.imageWrapper} />
            </a>
        );
    }
}

export const TopBanner = connect(mapStateToProps)(TopBannerComponent);

import React, { ReactElement, ReactNode } from 'react';
import classNames from 'clsx';
import styles from './Tabs.css';
import { TextSize, Text } from 'src/ui/Text/Text';

export const TabList = ({ list, ordered, dashed }: { list: (string | ReactNode)[]; ordered?: boolean; dashed?: boolean }): ReactElement => {
    const Tag = ordered ? 'ol' : 'ul';

    return (
        <Tag className={classNames({ [styles.list]: true, [styles.listDashed]: dashed })}>
            {list.map((item: string | ReactNode, index: number) => (
                <li className={styles.listItem} key={index}>
                    <Text size={TextSize['20px']}>
                        {ordered && `${index + 1}. `}
                        {item}
                    </Text>
                </li>
            ))}
        </Tag>
    );
};

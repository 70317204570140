import { BuyActionsType, BuyGetLinkActionTypes, BuyState } from 'src/modules/buy/buy.types';

const INITIAL_STATE: BuyState = {
    isLoading: false,
    hasError: false,
    link: '',
    token: '',
};

export const buyReducer = (state = INITIAL_STATE, action: BuyActionsType): BuyState => {
    switch (action.type) {
        case BuyGetLinkActionTypes.REQUEST:
            return {
                ...INITIAL_STATE,
                isLoading: true,
            };
        case BuyGetLinkActionTypes.FAILURE:
            return {
                ...INITIAL_STATE,
                hasError: true,
            };
        case BuyGetLinkActionTypes.SUCCESS: {
            return {
                ...INITIAL_STATE,
                link: action.link,
                token: action.token,
            };
        }
        default:
            return state;
    }
};

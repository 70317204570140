import React, { ReactElement } from 'react';

import styles from './Tabs.css';
import { TabTitle } from 'src/ui/HelpPage/Tabs/TabTitle';
import { defineMessages } from 'react-intl';
import { TabName } from 'src/ui/HelpPage/Tabs/TabName';
import { TabText } from 'src/ui/HelpPage/Tabs/TabText';
import { formatMessage, FormattedMessage } from 'src/utils/i18n';
import { TabList } from 'src/ui/HelpPage/Tabs/TabList';
import folderIcon from 'img/icons/folder.svg';
import playIcon from 'img/icons/play.svg';
import settingsIcon from 'img/icons/settings.svg';
import { Link } from 'src/ui/Link/Link';
import { useSelector } from 'react-redux';
import { getMacOsDownloadLink } from 'src/modules/links/links.selectors';

const messages = defineMessages({
    name1: 'Установка',
    name2: 'Добавить диск',
    text1: 'После того как закончится синхронизация, откроется окно с{nbsp}облаком, и{nbsp}вы{nbsp}увидите содержимое своего диска.',
    name3: 'Действия с диском',
    text2: 'Наведите на{nbsp}область с{nbsp}диском, чтобы увидеть доступные действия.',
    diskFolder: 'Перейти в облачный диск на компьютере',
    diskSettings: 'Настройки облачного диска',
    diskOn: 'Включить облачный диск (если отключен)',
});

const installListMessages = defineMessages({
    step1: 'Установите <nowrap>Диск-О:</nowrap> из{nbsp}<a>Mac App Store</a>. Для его работы требуется macOS 10.9 и{nbsp}выше.',
});

const diskListMessages = defineMessages({
    diskStep1: 'Если у{nbsp}вас еще не{nbsp}добавлены диски, нажмите кнопку {laquo}Добавить диск{raquo}.',
    diskStep2: 'Выберите облачный сервис, на{nbsp}котором располагаются ваши файлы.',
    diskStep3:
        'Введите адрес электронной почты и{nbsp}пароль, либо подтвердите права доступа <nowrap>Диск-О:</nowrap> в{nbsp}окне браузера. Не{nbsp}переживайте, <nowrap>Диск-О:</nowrap> никому не{nbsp}передает ваши пароли, авторизация для вас безопасна.',
});

const diskList = [
    formatMessage(diskListMessages.diskStep1),
    formatMessage(diskListMessages.diskStep2),
    formatMessage(diskListMessages.diskStep3),
];

export const OSX = ({ title }: { title: string }): ReactElement => {
    const macOsDownloadLink = useSelector(getMacOsDownloadLink);

    const installList = [
        formatMessage(installListMessages.step1, {
            // eslint-disable-next-line react/display-name
            a: (msg: string): ReactElement => (
                <Link key="downloadMac" href={macOsDownloadLink} target="_blank">
                    {msg}
                </Link>
            ),
        }),
    ];

    return (
        <div className={styles.tab}>
            <TabTitle>{title}</TabTitle>
            <TabName>
                <FormattedMessage {...messages.name1} />
            </TabName>
            <TabList list={installList} ordered />
            <TabName>
                <FormattedMessage {...messages.name2} />
            </TabName>
            <TabList list={diskList} ordered />
            <TabText>
                <FormattedMessage {...messages.text1} />
            </TabText>
            <TabName>
                <FormattedMessage {...messages.name3} />
            </TabName>
            <TabText>
                <FormattedMessage {...messages.text2} />
            </TabText>
            <TabText>
                <img key={folderIcon} width={16} height={16} className={styles.listIcon} src={folderIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.diskFolder} values={{}} />
                </span>
            </TabText>
            <TabText>
                <img key={settingsIcon} width={16} height={16} className={styles.listIcon} src={settingsIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.diskSettings} />
                </span>
            </TabText>
            <TabText>
                <img key={playIcon} width={16} height={16} className={styles.listIcon} src={playIcon} alt="" />
                <span className={styles.text}>
                    <FormattedMessage {...messages.diskOn} />
                </span>
            </TabText>
        </div>
    );
};

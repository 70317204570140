import { RefObject, useCallback, useEffect, useRef } from 'react';

export const useIntersectionObserver = (onIntersecting: () => void): RefObject<HTMLDivElement> => {
    const ROOT_MARGIN = '30px';
    const ref = useRef<HTMLDivElement>(null);
    const onIntersectingCallback = useCallback(
        (entries: IntersectionObserverEntry[]): void => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (entry.isIntersecting) {
                    onIntersecting();
                }
            });
        },
        [onIntersecting]
    );

    const observer = useRef<IntersectionObserver | null>(null);

    const unobserve = useCallback(() => {
        if (!ref.current) {
            return;
        }

        // eslint-disable-next-line no-unused-expressions
        observer.current?.unobserve(ref.current);
        // eslint-disable-next-line no-unused-expressions
        observer.current?.disconnect();
    }, []);

    useEffect(() => {
        if (!ref.current || !('IntersectionObserver' in window)) {
            return;
        }

        if (observer.current) {
            unobserve();
        }

        observer.current = new IntersectionObserver(onIntersectingCallback, { threshold: 0.1, rootMargin: ROOT_MARGIN });
        // eslint-disable-next-line no-unused-expressions
        observer.current?.observe(ref.current);

        return (): void => unobserve();
    }, [onIntersectingCallback, unobserve]);

    return ref;
};

import React, { ReactElement } from 'react';
import { OneDrive } from 'src/ui/ServicePage/OneDrive';
import { Dropbox } from 'src/ui/ServicePage/Dropbox';
import { GoogleDrive } from 'src/ui/ServicePage/GoogleDrive';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

export const ServicePage = (): ReactElement => {
    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route path={url + '/one-drive'} component={OneDrive} exact />
            <Route path={url + '/dropbox'} component={Dropbox} exact />
            <Route path={url + '/google-drive'} component={GoogleDrive} exact />
        </Switch>
    );
};

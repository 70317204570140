import { DownloadPromoActionsTypes, DownloadPromoActions, DownloadPromoState } from './downloadPromo.types';

const INITIAL_STATE: DownloadPromoState = {
    isDownloadPromoSnow: false,
};

export const downloadPromoReducer = (state = INITIAL_STATE, action: DownloadPromoActions): DownloadPromoState => {
    switch (action.type) {
        case DownloadPromoActionsTypes.SHOW:
            return {
                ...INITIAL_STATE,
                isDownloadPromoSnow: true,
            };
        case DownloadPromoActionsTypes.HIDE:
            return {
                ...INITIAL_STATE,
                isDownloadPromoSnow: false,
            };
        default:
            return state;
    }
};

import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { connect } from 'react-redux';
import classNames from 'clsx';
import { Button, ButtonColors, ButtonSize } from 'src/ui/Button/Button';
import { Text, TextSize, WhiteSpace } from 'src/ui/Text/Text';
import { defineMessages, FormattedMessage } from 'react-intl';
import styles from './Download.css';
import { isMacOS } from 'src/utils/ua-parser';
import appleIcon from 'img/icons/apple.svg';
import windowsIcon from 'img/icons/windows.svg';
import { Link } from 'src/ui/Link/Link';
import { sendGa } from 'src/utils/ga';
import { sendPixel } from 'src/utils/sendPixel';
import { showDownloadPromo } from 'src/modules/downloadPromo/downloadPromo.actions';
import { AppState } from 'src/store';
import { isPhone } from 'src/modules/media/media.selectors';
import { RB_PIXEL_URL } from 'src/modules/links/links.consts';
import { getDownloadUrlGetter } from 'src/modules/links/links.selectors';

interface MapProps {
    isPhone?: boolean;
    getDownloadUrl: ReturnType<typeof getDownloadUrlGetter>;
}
interface Props extends MapProps {
    showOnlyOne?: boolean;
    buttonSize?: ButtonSize;
    textSize?: TextSize;
    children?: ReactNode;
    showDownloadPromo: Function;
    showIcon?: boolean;
    isPrimary?: boolean;
    showOnTablet?: boolean;
    showOnPhone?: boolean;
    noWrap?: boolean;
}

const mapDispatchToProps = {
    showDownloadPromo,
};

const message = defineMessages({
    download: 'Скачать для {osName}',
});

const mapStateToProps = (state: AppState): MapProps => ({
    isPhone: isPhone(state),
    getDownloadUrl: getDownloadUrlGetter(state),
});

export class DownloadComponent extends PureComponent<Props> {
    static defaultProps = {
        showOnlyOne: false,
        buttonSize: ButtonSize.L,
        textSize: TextSize['20px'],
        showIcon: true,
        isPrimary: true,
        noWrap: false,
    };

    componentDidMount(): void {
        window.addEventListener('message', this.handleUpdateDownloadLinks);
    }

    componentWillUnmount(): void {
        window.removeEventListener('message', this.handleUpdateDownloadLinks);
    }

    private handleUpdateDownloadLinks = (event: { data: string | object | null }): void => {
        if (event.data === 'updateDownloadLinks') {
            this.forceUpdate();
        }
    };

    private sendDownloadStats = (isMac: boolean): void => {
        sendGa({
            category: 'download',
            action: 'click',
            label: isMac ? 'mac' : 'win',
        });
        sendPixel(RB_PIXEL_URL);
    };

    private showDownloadPromo = (isMac: boolean): void => {
        if (!isMac) {
            this.props.showDownloadPromo();
        }
    };

    private handleClick = (): void => {
        this.showDownloadPromo(isMacOS);
        this.sendDownloadStats(isMacOS);
    };

    private handleClickLink = (): void => {
        this.showDownloadPromo(!isMacOS);
        this.sendDownloadStats(!isMacOS);
    };

    render(): ReactElement {
        const {
            textSize,
            buttonSize,
            showOnlyOne,
            children,
            showIcon,
            isPrimary,
            showOnTablet,
            showOnPhone,
            isPhone,
            noWrap,
            getDownloadUrl,
        } = this.props;

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_one]: showOnlyOne,
                    [styles.root_showTablet]: showOnTablet,
                    [styles.root_showPhone]: showOnPhone,
                    [styles.root_noWrap]: noWrap,
                })}
            >
                <div className={styles.button}>
                    <Button
                        color={isPrimary ? ButtonColors.PRIMARY : ButtonColors.WHITE_BLUE}
                        href={getDownloadUrl()}
                        size={buttonSize}
                        onClick={this.handleClick}
                        target={isMacOS ? '_blank' : '_self'}
                        noPrevent
                        fluid={isPhone}
                    >
                        {children ? (
                            children
                        ) : (
                            <>
                                {showIcon && (
                                    <img
                                        className={styles.downloadIcon}
                                        src={isMacOS ? appleIcon : windowsIcon}
                                        alt={isMacOS ? 'macOs' : 'Windows'}
                                    />
                                )}
                                <Text size={textSize}>
                                    <FormattedMessage
                                        {...message.download}
                                        values={{
                                            osName: isMacOS ? 'macOS' : 'Windows',
                                        }}
                                    />
                                </Text>
                            </>
                        )}
                    </Button>
                </div>
                {!showOnlyOne && (
                    <div className={styles.link}>
                        <Link
                            href={getDownloadUrl({ isMac: !isMacOS })}
                            onClick={this.handleClickLink}
                            target={isMacOS ? '_self' : '_blank'}
                            noPrevent
                        >
                            <Text size={textSize} whiteSpace={noWrap ? WhiteSpace.nowrap : undefined}>
                                <FormattedMessage
                                    {...message.download}
                                    values={{
                                        osName: !isMacOS ? 'macOS' : 'Windows',
                                    }}
                                />
                            </Text>
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}

export const Download = connect(mapStateToProps, mapDispatchToProps)(DownloadComponent);

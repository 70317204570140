import { config } from 'src/constants/config';
import { API } from './api';

export type OmicronParam = 'disk-o-mac-os-download-link' | 'другой параметр';
export type OmicronConfig = {
    [key in OmicronParam]: unknown;
};

export type GetOmicronConfigResponse = {
    config_v: number;
    cond_s: string;
    config: OmicronConfig;
};

export const getOmicronConfig = (): Promise<GetOmicronConfigResponse> => {
    return new API().makeRequest({
        baseURL: config.isProd
            ? 'https://portal.mail.ru'
            : '/portal' /* Для локальной разработки нужно подменить на '/portal', либо вытащить отдельный NODE_ENV=local */,
        url: '/api/v1/omicron/get',
        method: 'post',
        timeout: 15000,
        params: {
            config_id: 'a1f3b23-web',
        },
        withCredentials: true,
    });
};

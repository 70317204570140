import React, { PureComponent, ReactElement } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'clsx';

import styles from './GetLinkViaEmail.css';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { i18n } from 'src/utils/i18n';
import DiskoIcon from 'img/disko.svg';
import { Button, ButtonColors } from 'src/ui/Button/Button';
import { SendEmailState } from 'src/modules/sendEmail/sendEmail.types';
import { noop } from 'src/utils/helpers';
import { AppState } from 'src/store';
import { getMessage, getStatus } from 'src/modules/sendEmail/sendEmail.selectors';
import { requestEmailLink, resetEmailLinkState } from 'src/modules/sendEmail/sendEmail.actions';
import { Status } from 'src/types/status';
import { sendGa } from 'src/utils/ga';
import { Nowrap } from 'src/ui/Nowrap/Nowrap';

const messages = defineMessages({
    title:
        '<nowrap>Диск-О:</nowrap> — это десктопное приложение. Оставьте ваш email, чтобы получить приложение <nowrap>Диск-О:</nowrap> для Mac или Windows',
    titleSuccess: 'Мы отправили ссылку на установку приложения <nowrap>Диск-О:</nowrap> на почту {email}',
    sendEmail: 'Отправить ссылку на email',
    sendAgain: 'Отправить ссылку еще раз',
    emailPlaceholder: 'Ваш email',
});

const emailPlaceholder = i18n.formatMessage(messages.emailPlaceholder);

const mapStateToProps = (state: AppState): SendEmailState => {
    const status = getStatus(state);
    const message = getMessage(state);
    return {
        status,
        message,
    };
};

const mapDispatchToProps = {
    onSendEmail: requestEmailLink,
    onResetState: resetEmailLinkState,
};

interface Props {
    status: Status;
    message: string;
    onSendEmail?: Function;
    onResetState?: Function;
}

interface State {
    email?: string;
}

export class GetLinkViaEmailComponent extends PureComponent<Props, State> {
    state = {
        email: '',
    };

    private handleSendEmail = (): void => {
        const { onSendEmail = noop } = this.props;
        sendGa({
            category: 'sendemail',
            action: 'click',
        });

        onSendEmail(this.state.email)
            .then((): void =>
                sendGa({
                    category: 'sendemail',
                    action: 'success',
                })
            )
            .catch((): void =>
                sendGa({
                    category: 'sendemail',
                    action: 'fail',
                })
            );
    };
    private handleOnChange = (event: { target: { value?: string } }): void => {
        const { onResetState = noop, status } = this.props;
        const email = event.target.value;
        this.setState({ email }, () => {
            if (status !== Status.none) {
                onResetState();
            }
        });
    };
    private handleSendAgain = (): void => {
        const { onSendEmail = noop } = this.props;
        onSendEmail(this.state.email);
    };

    render(): ReactElement {
        const { status, message } = this.props;
        const { email } = this.state;

        if (status === Status.success) {
            return (
                <div className={classNames(styles.root, styles.rootSuccess)}>
                    <img src={DiskoIcon} className={styles.diskoIcon} alt="disk-o" />
                    <div className={styles.buttonBlock}>
                        <div className={styles.title}>
                            <Text size={TextSize['20px']} weight={Weight.w500}>
                                <FormattedMessage
                                    {...messages.titleSuccess}
                                    values={{
                                        email,
                                        nowrap: Nowrap,
                                    }}
                                />
                            </Text>
                        </div>
                        <Button color={ButtonColors.WHITE} onClick={this.handleSendAgain}>
                            <Text size={TextSize['20px']} weight={Weight.w500}>
                                <FormattedMessage {...messages.sendAgain} />
                            </Text>
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.root}>
                <div className={styles.top}>
                    <div className={styles.title}>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage
                                {...messages.title}
                                values={{
                                    email,
                                    nowrap: Nowrap,
                                }}
                            />
                        </Text>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={classNames({ [styles.inputWrapper]: true, [styles.inputWrapperError]: status === Status.error })}>
                        <input
                            className={classNames({ [styles.input]: true, [styles.inputError]: status === Status.error })}
                            value={this.state.email}
                            onChange={this.handleOnChange}
                            placeholder={emailPlaceholder}
                        />
                        {!!message && (
                            <div className={styles.message}>
                                <Text size={TextSize['13px']} weight={Weight.w500}>
                                    {message}
                                </Text>
                            </div>
                        )}
                    </div>
                    <Button color={ButtonColors.ORANGE} onClick={this.handleSendEmail}>
                        <Text size={TextSize['20px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.sendEmail} />
                        </Text>
                    </Button>
                </div>
            </div>
        );
    }
}

export const GetLinkViaEmail = connect(mapStateToProps, mapDispatchToProps)(GetLinkViaEmailComponent);

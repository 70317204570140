import { API } from 'src/modules/api/api';

export interface GiveTariffsResponse {
    [key: string]: {
        duration: number;
        extra: {
            currency: string;
            duration_str: string;
            cloud_gift?: string;
            enabled: boolean;
            has_trial_period?: boolean;
            trial_period?: number;
            discount?: {
                duration: number;
                price: number;
            };
        };
        plan: string;
        price: number;
    };
}

export const giveTariffs = (): Promise<GiveTariffsResponse> =>
    new API().makeRequest({
        method: 'get',
        url: 'give/tariffs',
    });

import { API } from 'src/modules/api/api';

export interface GetLicenseResponse {
    serial: string;
}

export const getLicense = ({ token, plan }: { plan: string; token: string }): Promise<GetLicenseResponse> =>
    new API().makeRequest({
        method: 'post',
        url: 'give/serial_token',
        body: {
            token,
            plan,
        },
    });

import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { FormattedMessage } from 'src/utils/i18n';
import { HowItWorks } from 'src/ui/HotItWorks/HowItWorks';
import poster from 'img/backups/poster1.png';
import posterEn from 'img/backups/posterEn.png';
import { isRussianLanguage } from 'src/modules/language/language.selectors';

const messages = defineMessages({
    step1:
        'Нажмите кнопку «Защитить файлы» при первом запуске Диск-О: или выберите пункт «Создать резервную копию», если вы уже пользуетесь программой.',
    step2: 'Придумайте название для резервной копии и выберите папки, копии которых нужно создать.',
    step3: 'Укажите облачный сервис, в который будут сохраняться резервные копии, и авторизуйтесь в нем.',
});

export const BackupsHowToEnable = ({ title }: { title?: MessageDescriptor }) => {
    return (
        <HowItWorks
            poster={isRussianLanguage() ? poster : posterEn}
            secondBg={true}
            title={title && <FormattedMessage {...title} />}
            steps={[
                {
                    text: <FormattedMessage {...messages.step1} />,
                    step: 1,
                },
                {
                    text: <FormattedMessage {...messages.step2} />,
                    step: 2,
                },
                {
                    text: <FormattedMessage {...messages.step3} />,
                    step: 3,
                },
            ]}
            videoUrl={`https://www.youtube.com/embed/${isRussianLanguage() ? 'PJEe0CmfZmY' : 'szmls4GxjNg'}?controls=0`}
            newPosterMod
            wideVideoPreview
        />
    );
};

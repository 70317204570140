import React, { PureComponent, ReactElement, ReactNode } from 'react';
import classNames from 'clsx';
import styles from './Button.css';

export enum ButtonColors {
    PRIMARY = 'primary',
    BASE = 'base',
    WHITE = 'white',
    WHITE_BLUE = 'white_blue',
    ORANGE = 'orange',
    GREEN = 'green',
    BLUE = 'blue',
    SEA_WAVE = 'sea_wave',
    NAVY_BLUE = 'navy_blue',
    BLACK = 'black',
}

export enum ButtonSize {
    XS = 'xs',
    S = 's',
    M = 'm',
    L = 'l',
}

export enum ButtonRadius {
    r12 = 12,
    r8 = 8,
}

export enum ButtonPadding {
    normal = 'normal',
    large = 'large',
}

interface ButtonProps {
    id?: string;
    color?: ButtonColors;
    size?: ButtonSize;
    radius?: ButtonRadius;
    padding?: ButtonPadding;
    fluid?: boolean;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void;
    href?: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
    children: ReactNode;
    disabled?: boolean;
    loading?: boolean;
    noPrevent?: boolean;
}

export class Button extends PureComponent<ButtonProps> {
    static defaultProps = {
        size: ButtonSize.M,
        color: ButtonColors.BASE,
        radius: ButtonRadius.r8,
        padding: ButtonPadding.normal,
        disabled: false,
        loading: false,
        fluid: false,
        noPrevent: true,
        id: 'bttn',
    };

    handleOnClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void => {
        if (!this.props.noPrevent) {
            e.preventDefault();
        }

        if (this.props.disabled || this.props.loading) {
            return;
        }

        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    render(): ReactElement {
        const { href, children, target, color, size, disabled, loading, fluid, id, radius, padding } = this.props;
        const Tag = href ? 'a' : 'button';
        return (
            <Tag
                id={id}
                href={href}
                onClick={this.handleOnClick}
                target={target}
                disabled={disabled}
                className={classNames({
                    [styles.root]: true,
                    [styles.root_fluid]: fluid,
                    [styles.root_state_disabled]: disabled,
                    [styles.root_state_loading]: loading,
                    [styles[`root_color_${color}`]]: color,
                    [styles[`root_size_${size}`]]: size,
                    [styles[`root_radius_${radius}`]]: radius,
                    [styles[`root_padding_${padding}`]]: padding,
                })}
            >
                {children}
            </Tag>
        );
    }
}

import { API } from 'src/modules/api/api';

export interface GiveSerialRequest {
    language: string;
    plan: string;
    email: string;
    utm?: string;
}

export interface GiveSerialResponse {
    token: string;
    url: string;
}

export const giveSerial = (params: GiveSerialRequest): Promise<GiveSerialResponse> =>
    new API().makeRequest({
        method: 'post',
        url: 'give/serial',
        body: params,
    });

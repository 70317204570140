export enum LinksActionTypes {
    SET_MAC_OS_LINK = '@links/setMacOsLink',
}

export interface SetMacOsLink {
    type: typeof LinksActionTypes.SET_MAC_OS_LINK;
    payload: DownloadMacOsDetails;
}

export interface DownloadMacOsDetails {
    backupsPage: {
        otherLink: string;
        ruLink: string;
    };
    otherLink: string;
    ruLink: string;
}

export interface LinksState {
    downloadMacOs: DownloadMacOsDetails;
}

export type LinksActionTypesUnion = SetMacOsLink;

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'src/store';
import { GetConfigFailureAction, GetConfigRequestAction, GetConfigSuccessAction, OmicronGetConfigActionTypes } from './omicron.types';
import { getOmicronConfig as _getOmicronConfig } from 'src/modules/api/getOmicronConfig';
import { getMacOsDownloadLink } from 'src/modules/omicron/omicron.selectors';
import { setMacOsLink } from 'src/modules/links/links.actions';

const request = (): GetConfigRequestAction => ({
    type: OmicronGetConfigActionTypes.REQUEST,
});

const failure = (): GetConfigFailureAction => ({
    type: OmicronGetConfigActionTypes.FAILURE,
});

const success = (data: any): GetConfigSuccessAction => ({
    type: OmicronGetConfigActionTypes.SUCCESS,
    payload: data,
});

const thunk: ThunkAction<void, AppState, null, Action<string>> = (dispatch, getState): void => {
    dispatch(request());

    _getOmicronConfig()
        .then((res) => {
            dispatch(success(res));

            const macOsLink = getMacOsDownloadLink(getState());
            if (macOsLink) {
                dispatch(setMacOsLink(macOsLink));
            }
        })
        .catch(() => {
            dispatch(failure());
        });
};

export const getOmicronConfig = (): ThunkAction<void, AppState, null, Action<string>> => thunk;

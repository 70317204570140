import React, { FC, useCallback, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styles from './DownloadPage.css';
import { ReactComponent as Logo } from 'img/downloadPage/logo.svg';
import { browserName, isWindows } from 'src/utils/ua-parser';
import { Arrow } from 'src/ui/DownloadPage/arrow';
import { BrowserDownloadDot } from 'src/ui/DownloadPage/constants';
import { getBeginningDot, getDotFromMargin } from 'src/ui/DownloadPage/helpers';
import { Text } from 'src/ui/Text/Text';
import { sendGa } from 'src/utils/ga';
import { Meta } from 'src/ui/Meta/Meta';
import { useSelector } from 'react-redux';
import { getDownloadUrlGetter } from 'src/modules/links/links.selectors';

let arrow: Arrow;

const handleClick = () => {
    arrow.startAnimation();
    sendGa({
        category: 'download_v1',
        action: 'click',
        label: isWindows ? 'windows' : 'else',
    });
};

export const DownloadPage: FC<RouteComponentProps<{ version: string }>> = () => {
    const canvas = useRef<HTMLCanvasElement>(null);
    const getDownloadUrl = useSelector(getDownloadUrlGetter);

    const downloadUrl = getDownloadUrl({ isMac: false });

    const downloadExe = useCallback(() => {
        const anchor = document.createElement('a');
        anchor.setAttribute('download', String(true));
        anchor.setAttribute('href', downloadUrl);
        anchor.click();
    }, []);

    useEffect(() => {
        if (!canvas.current) return;

        const endMargins = BrowserDownloadDot[browserName || 'default'] || BrowserDownloadDot.default;
        const begin = getBeginningDot(endMargins);
        const end = getDotFromMargin(endMargins);

        arrow = new Arrow({ canvas: canvas.current, coordinates: { begin, end }, color: '#489AFF' });

        const rerenderArrow = () => {
            const begin = getBeginningDot(endMargins);
            const end = getDotFromMargin(endMargins);
            arrow.rerender({ begin, end });
        };

        window.addEventListener('resize', rerenderArrow);
        window.addEventListener('scroll', rerenderArrow);

        if (isWindows) {
            try {
                downloadExe();
                arrow.startAnimation();
                sendGa({
                    category: 'download_v1',
                    action: 'auto',
                });
            } catch (error) {
                sendGa({
                    category: 'download_v1',
                    action: 'error',
                });
            }
        }

        return () => {
            window.removeEventListener('resize', rerenderArrow);
            window.removeEventListener('scroll', rerenderArrow);
        };
    }, []);

    return (
        <>
            <Meta />
            <div className={styles.page}>
                <div className={styles.centredWrapper}>
                    <div className={styles.logo}>
                        <Logo />
                    </div>
                    <div className={styles.title}>
                        <h1>Благодарим за выбор Диск-О:</h1>
                    </div>
                    <div className={styles.description}>
                        <Text>Чтобы начать работу, выполните следующие действия:</Text>
                    </div>
                    <canvas className={styles.canvas} ref={canvas} />
                    <div className={styles.cardRow}>
                        <div className={styles.card}>
                            <div className={styles.card__number}>
                                <Text>1</Text>
                            </div>
                            <div className={styles.card__text}>
                                <Text>
                                    Дождитесь пока скачается файл <b>Disk-O_setup.exe</b>
                                </Text>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.card__number}>
                                <Text>2</Text>
                            </div>
                            <div className={styles.card__text}>
                                <Text>Запустите файл и установите Диск-О: на ваш компьютер.</Text>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.card__number}>
                                <Text>3</Text>
                            </div>
                            <div className={styles.card__text}>
                                <Text>Окно программы откроется после завершения установки.</Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.linkRow}>
                        <Text>
                            Если загрузка не началась автоматичеcки,{' '}
                            <a onClick={handleClick} href={downloadUrl} download={true}>
                                повторите попытку
                            </a>
                        </Text>
                    </div>
                </div>
            </div>
        </>
    );
};

import React, { PureComponent, ReactElement, ReactNode } from 'react';
import styles from './DescriptionBlock.css';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { FormattedMessage } from 'src/utils/i18n';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { Button, ButtonColors, ButtonSize } from 'src/ui/Button/Button';

const messages = defineMessages({
    buttonText: 'Подробнее',
});

interface DescriptionBlockProps {
    title: MessageDescriptor;
    description: MessageDescriptor;
    buttonUrl: string;
    Image: ReactNode;
    onButtonClick: Function;
    gaId: string;
}

export class DescriptionBlock extends PureComponent<DescriptionBlockProps> {
    private handleOnClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>): void => this.props.onButtonClick(e);

    render(): ReactElement {
        const { title, description, buttonUrl, children, Image, gaId } = this.props;

        return (
            <div className={styles.root}>
                <div className={styles.title}>
                    <Text size={TextSize['44px']} weight={Weight.w700}>
                        <FormattedMessage {...title} values={{ br: <br /> }} />
                    </Text>
                </div>
                <div className={styles.image}>{Image}</div>
                <div className={styles.description}>
                    <Text size={TextSize['28px']} weight={Weight.w400}>
                        <FormattedMessage {...description} />
                    </Text>
                </div>
                <div className={styles.children}>{!!children && children}</div>
                <div className={styles.button}>
                    <Button id={gaId} color={ButtonColors.PRIMARY} href={buttonUrl} size={ButtonSize.L} onClick={this.handleOnClick} fluid>
                        <Text size={TextSize['20px']}>
                            <FormattedMessage {...messages.buttonText} />
                        </Text>
                    </Button>
                </div>
            </div>
        );
    }
}

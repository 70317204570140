import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { Weight, TextSize, Text } from 'src/ui/Text/Text';
import { Download } from 'src/ui/Download/Download';
import { Container } from 'src/ui/Container/Container';
import styles from './ServiceHero.css';

interface Props {
    title: string | ReactNode;
    text: string | ReactNode;
    logo: ReactNode;
}

export class ServicesHero extends PureComponent<Props> {
    render(): ReactElement {
        const { title, text, logo } = this.props;

        return (
            <div className={styles.wrap}>
                <Container>
                    <div className={styles.root}>
                        <div className={styles.content}>
                            <h1 className={styles.title}>
                                <Text
                                    weight={Weight.w700}
                                    desktopSize={TextSize['64px']}
                                    tabletSize={TextSize['40px']}
                                    size={TextSize['32px']}
                                >
                                    {title}
                                </Text>
                            </h1>
                            <div className={styles.description}>
                                <Text size={TextSize['20px']} desktopSize={TextSize['24px']}>
                                    {text}
                                </Text>
                            </div>
                            <div className={styles.downloadButtons}>
                                <Download />
                            </div>
                        </div>
                        <div className={styles.clouds}>
                            <div className={styles.logo}>{logo}</div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

import { isMacOS } from 'src/utils/ua-parser';
import { getCookie } from 'src/utils/helpers';
import { getQueryIntro, getQueryGclid, isExactMatchPage } from 'src/utils/route.helpers';
import { isRussianLanguage } from 'src/modules/language/language.selectors';
import { AppState } from 'src/modules/types';

import { getDownloadWindowsUrl } from './links.helpers';

export const getLinks = (state: AppState) => state.links;

export const getMacOsDownloadLink = (state: AppState) => {
    const { downloadMacOs } = getLinks(state);

    const isRu = isRussianLanguage();
    if (isExactMatchPage('backups')) {
        return isRu ? downloadMacOs.backupsPage.ruLink : downloadMacOs.backupsPage.otherLink;
    }

    return isRu ? downloadMacOs.ruLink : downloadMacOs.otherLink;
};

export const getDownloadUrlGetter = (state: AppState) => ({ isMac = isMacOS, utm = 'landing_new' } = {}): string => {
    const mr1lad = getCookie('mr1lad');
    const intro = isRussianLanguage() ? getQueryIntro() : undefined;
    const gclid = getQueryGclid();

    return isMac ? getMacOsDownloadLink(state) : getDownloadWindowsUrl({ utm: mr1lad ? mr1lad : utm, intro, gclid });
};

import React from 'react';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';
import { Download } from 'src/ui/Download/Download';

import styles from './FilesHero.css';

const messages = {
    howTo: (
        <>
            Как перенести файлы с<br />
            Яндекс.Диска и Гугл Диска
            <br />в Облако Mail.ru
        </>
    ),
    description: (
        <>
            Если вы одновременно пользуетесь Яндекс.Диском, Гугл Диском, Облаком Mail.ru и другими хранилищами и периодически вам нужно
            переносить между ними данные, обратите внимание на <span className={styles.disko}>Диск-О:</span>
        </>
    ),
};

export const FilesHero = (): JSX.Element => {
    return (
        <div>
            <h1 className={styles.title}>
                <Text size={TextSize['40px']} tabletSize={TextSize['40px']} desktopSize={TextSize['56px']} lineHeight={LineHeight['65px']}>
                    {messages.howTo}
                </Text>
            </h1>
            <div className={styles.text}>
                <Text size={TextSize['20px']} weight={Weight.w400}>
                    {messages.description}
                </Text>
            </div>
            <Download showOnTablet showOnPhone />
        </div>
    );
};

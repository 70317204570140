import React, { PureComponent, ReactElement } from 'react';
import { Container } from 'src/ui/Container/Container';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';
import { defineMessages } from 'react-intl';
import { FormattedMessage } from 'src/utils/i18n';
import avatar from 'img/avatar.png';
import avatar2 from 'img/avatar2.png';
import { ReactComponent as RatingIcon } from 'img/icons/rating.svg';
import { ReactComponent as ArrowIcon } from 'img/icons/arrow-next.svg';
import classNames from 'clsx';
import styles from './Reviews.css';

const messages = defineMessages({
    title: 'Отзывы',
    description:
        'Тысячи людей уже сделали выбор в{nbsp}пользу Диск-О:. Приводим несколько цитат наших пользователей о{nbsp}том, как программа упрощает работу с{nbsp}облачными сервисами',
    more: 'Больше отзывов',
});

const reviews: {
    name: string;
    title: string;
    description: string;
    avatar: string;
}[] = [
    {
        name: 'Yutitimos',
        title: 'Удобно - все облака под рукой',
        description:
            'У меня много облаков. Так много, что путался в них. Теперь все есть в одном месте. Все работает ровно, но есть и мелочи - Pcloud иногда отключатеся и требует подключить его заново. В остальном - 5 баллов!',
        avatar,
    },
    {
        name: 'Pionerband',
        title: 'Очень удобно!',
        description:
            'Во время удаленки незаменимое приложение для меня. Главное отличие от яндекс диска - я вижу все файлы облака в окне Finder, но они не подгружены на комп, что экономит место. Прям ни копейки не жалко за эту штуку!',
        avatar: avatar2,
    },
];

export class Reviews extends PureComponent {
    renderReviews = (): ReactElement => {
        return (
            <div className={styles.reviews}>
                {reviews.map((review) => (
                    <div key={review.name} className={styles.card}>
                        <div className={styles.cardHeader}>
                            <img src={review.avatar} alt="" className={styles.cardIcon} />
                            <div>
                                <div className={styles.cardName}>
                                    <Text size={TextSize['20px']} lineHeight={LineHeight['30px']}>
                                        {review.name}
                                    </Text>
                                </div>
                                <RatingIcon width={96} height={16} />
                            </div>
                        </div>
                        <div className={styles.cardContent}>
                            <div className={styles.cardTitle}>
                                <Text size={TextSize['28px']} weight={Weight.w700}>
                                    {review.title}
                                </Text>
                            </div>
                            <div className={styles.cardDescription}>
                                <Text size={TextSize['20px']} lineHeight={LineHeight['30px']}>
                                    {review.description}
                                </Text>
                            </div>
                        </div>
                    </div>
                ))}
                <a className={classNames(styles.card, styles.emptyCard)} target="_blank" href="https://trk.mail.ru/c/lqvv59?">
                    <div className={styles.more}>
                        <Text size={TextSize['20px']} lineHeight={LineHeight['30px']}>
                            <FormattedMessage {...messages.more} />
                        </Text>
                        <ArrowIcon width={20} height={20} />
                    </div>
                </a>
            </div>
        );
    };

    render(): ReactElement {
        return (
            <div className={styles.root}>
                <Container>
                    <div className={styles.texts}>
                        <div className={styles.title}>
                            <Text
                                size={TextSize['32px']}
                                desktopSize={TextSize['44px']}
                                lineHeight={LineHeight.inherit}
                                weight={Weight.w700}
                            >
                                <FormattedMessage {...messages.title} values={{ br: <br /> }} />
                            </Text>
                        </div>
                        <div className={styles.description}>
                            <Text size={TextSize['20px']} lineHeight={LineHeight['30px']}>
                                <FormattedMessage {...messages.description} />
                            </Text>
                        </div>
                    </div>
                    {this.renderReviews()}
                </Container>
            </div>
        );
    }
}

import { combineReducers } from 'redux';
import { mediaReducer } from 'src/modules/media/media.reducer';
import { tariffReducer } from 'src/modules/tariffs/tariffs.reducer';
import { buyReducer } from 'src/modules/buy/buy.reducer';
import { sendEmailReducer } from 'src/modules/sendEmail/sendEmail.reducer';
import { unbindReducer } from 'src/modules/unbind/unbind.reducer';
import { downloadPromoReducer } from 'src/modules/downloadPromo/downloadPromo.reducer';
import { omicronReducer } from 'src/modules/omicron/omicron.reducers';
import { linksReducer } from './links/links.reducers';

export const reducers = combineReducers({
    media: mediaReducer,
    tariffs: tariffReducer,
    sendEmail: sendEmailReducer,
    buy: buyReducer,
    unbind: unbindReducer,
    downloadPromo: downloadPromoReducer,
    omicron: omicronReducer,
    links: linksReducer,
});

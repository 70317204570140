import React, { ReactElement, ReactNode } from 'react';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';
import styles from './BlockTitle.css';

export const BlockTitle = ({ children }: { children: ReactElement | string | ReactNode }): ReactElement => (
    <h2 className={styles.root}>
        <Text size={TextSize['32px']} desktopSize={TextSize['44px']} lineHeight={LineHeight.inherit} weight={Weight.w700}>
            {children}
        </Text>
    </h2>
);

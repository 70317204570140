import { defineMessages } from 'react-intl';
import { i18n } from 'src/utils/i18n';
import { isMatchPage } from 'src/utils/route.helpers';

export interface Page {
    message: string;
    isActive: () => boolean;
    url: string;
    id: string;
}

const pagesMessages = defineMessages({
    backup: 'Резервное копирование',
    aggregation: 'Объединение облаков',
});

export const pagesList = [
    {
        message: i18n.formatMessage(pagesMessages.backup),
        isActive: () => isMatchPage('backups'),
        url: '/backups',
        id: 'backup',
    },
    {
        message: i18n.formatMessage(pagesMessages.aggregation),
        isActive: () => isMatchPage('/') || window.location.pathname === '/',
        url: '/',
        id: 'aggr',
    },
];

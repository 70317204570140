import React from 'react';
import { defineMessages } from 'react-intl';
import { Button, ButtonColors, ButtonSize } from 'src/ui/Button/Button';
import styles from './BackupsLearnMore.css';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { FormattedMessage } from 'src/utils/i18n';
import { isPhone as isPhoneSelector } from 'src/modules/media/media.selectors';
import { useSelector } from 'react-redux';

const messages = defineMessages({
    title: 'Агрегатор <span>облачных</span> дисков',
    text: 'Возможность переключаться между облаками в один клик, не открывая браузер.',
    buttonText: 'Узнать больше о Диск-О:',
});

export const BackupsLearnMore = () => {
    const isPhone = useSelector(isPhoneSelector);
    return (
        <div className={styles.root}>
            <div className={styles.texts}>
                <div className={styles.title}>
                    <Text size={TextSize['32px']} desktopSize={TextSize['44px']} weight={Weight.w700}>
                        <FormattedMessage
                            {...messages.title}
                            values={{
                                span: (msg: string) => <span className={styles.highlight}>{msg}</span>,
                            }}
                        />
                    </Text>
                </div>
                <div className={styles.text}>
                    <Text size={TextSize['20px']} weight={Weight.w500}>
                        <FormattedMessage {...messages.text} />
                    </Text>
                </div>
            </div>
            <div className={styles.button}>
                <Button
                    size={ButtonSize.L}
                    color={ButtonColors.PRIMARY}
                    fluid={isPhone}
                    target="_blank"
                    href="https://disk-o.cloud/?utm_source=disko&utm_medium=backup&utm_campaign=landing_backup&utm_content=rezerv"
                >
                    <Text weight={Weight.w500} size={TextSize['20px']}>
                        <FormattedMessage {...messages.buttonText} />
                    </Text>
                </Button>
            </div>
        </div>
    );
};

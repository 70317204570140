declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        app: any;
        disko_activate?: Function;
    }
}

const app = {
    enableFeature: (key: string): void => {
        localStorage.setItem(key, 'enabled');
        window.location.reload();
    },
    disableFeature: (key: string): void => {
        localStorage.setItem(key, 'disabled');
        window.location.reload();
    },
};

export function initApp(): void {
    window.app = app;
}

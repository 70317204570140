import { Currency, Tariff, TariffMods, TariffsState } from 'src/modules/tariffs/tariffs.types';
import { createSelector } from 'reselect';
import { AppState } from 'src/store';
import { get } from 'lodash/fp';
import { mapTariffsByTariffMode } from 'src/modules/tariffs/tariffs.constants';
import { config } from 'src/constants/config';

const getTariffState = (state: AppState): TariffsState => state.tariffs;

function isTariff(tariff: unknown | Tariff): tariff is Tariff {
    return !!tariff;
}

export function getTariffModFromFeatures(): TariffMods | null {
    if (config.features.action) {
        return TariffMods.action;
    }

    if (config.featuresWithParameters.bundle === 'disko1y_discount10_rub') {
        return TariffMods.bundle10;
    }

    if (config.featuresWithParameters.bundle === 'disko1y_discount20_rub') {
        return TariffMods.bundle20;
    }

    return null;
}

// eslint-disable-next-line complexity
function getTariffMod(id: string, featuresTariffMode: TariffMods | null): TariffMods {
    switch (true) {
        case featuresTariffMode === TariffMods.bundle10 && id.includes('disko1y_discount10'):
            return TariffMods.bundle10;
        case featuresTariffMode === TariffMods.bundle20 && id.includes('disko1y_discount20'):
            return TariffMods.bundle20;
        case featuresTariffMode === TariffMods.cloud128 && id.includes('disko1y_cloud128'):
            return TariffMods.cloud128;
        case featuresTariffMode === TariffMods.cloud256 && id.includes('disko1y_cloud256'):
            return TariffMods.cloud256;
        case featuresTariffMode === TariffMods.action: {
            const tariffs = mapTariffsByTariffMode[TariffMods.action];
            if (tariffs.tariffIdPrefix && id.startsWith(tariffs.tariffIdPrefix)) {
                return TariffMods.action;
            }
        }
        // eslint-disable-next-line no-fallthrough
        default:
            return TariffMods.none;
    }
}

function mapTariffWithMod(tariff: Tariff, featuresTariffMode: TariffMods | null): Tariff {
    return {
        ...tariff,
        tariffMod: getTariffMod(tariff.id, featuresTariffMode),
    };
}

export const getTariffsByCurrency = createSelector(
    getTariffState,
    (state: AppState, currency: Currency) => currency,
    (state: AppState, currency: Currency, tariffMode?: TariffMods) => tariffMode,
    (tariffs, currency, tariffMode?: TariffMods): Tariff[] => {
        if (!tariffMode) {
            return (
                mapTariffsByTariffMode[TariffMods.none][currency]?.map((id: string) => get(['list', id], tariffs)).filter(isTariff) ?? []
            );
        }

        const tariffMod = getTariffModFromFeatures();
        const tariffsByMod = mapTariffsByTariffMode[tariffMode] || {};
        const tariffsByModAndCurrency = tariffsByMod[currency] || mapTariffsByTariffMode[TariffMods.none][currency] || [];

        return tariffsByModAndCurrency
            ?.map((id: string) => get(['list', id], tariffs))
            ?.filter(isTariff)
            ?.map((tariff: Tariff): Tariff => mapTariffWithMod(tariff, tariffMod));
    }
);

export const getTariffById = createSelector(
    getTariffState,
    (state: AppState, id: string) => id,
    (tariffs, id): Tariff | undefined => {
        const tariff = tariffs.list[id];

        if (!tariff) {
            return undefined;
        }

        const tariffMod = getTariffModFromFeatures();

        return mapTariffWithMod(tariff, tariffMod);
    }
);

import React, { ReactElement, ReactNode } from 'react';
import classNames from 'clsx';
import styles from './Text.css';
import { noop } from 'src/utils/helpers';

export enum TextSize {
    '11px' = '11px',
    '13px' = '13px',
    '12px' = '12px',
    '14px' = '14px',
    '15px' = '15px',
    '16px' = '16px',
    '17px' = '17px',
    '18px' = '18px',
    '20px' = '20px',
    '24px' = '24px',
    '28px' = '28px',
    '30px' = '30px',
    '32px' = '32px',
    '40px' = '40px',
    '44px' = '44px',
    '48px' = '48px',
    '64px' = '64px',
    '56px' = '56px',
}

export enum Weight {
    'w300' = '300',
    'w400' = 'normal',
    'w500' = '500',
    'w600' = '600',
    'w700' = '700',
    'normal' = 'normal',
}

export enum Style {
    'normal' = 'normal',
    'italic' = 'italic',
}

export enum LineHeight {
    normal = 'normal',
    initial = 'initial',
    inherit = 'inherit',
    '26px' = '26px',
    '30px' = '30px',
    '35px' = '35px',
    '44px' = '44px',
    '52px' = '52px',
    '65px' = '65px',
}

export enum WhiteSpace {
    normal = 'normal',
    nowrap = 'nowrap',
}

export enum VAlign {
    middle = 'middle',
    baseLine = 'baseLine',
}

export enum TextAlign {
    center = 'center',
    left = 'left',
    right = 'right',
}

interface Props {
    size?: TextSize;
    desktopSize?: TextSize;
    tabletSize?: TextSize;
    mediumDesktopSize?: TextSize;
    weight?: Weight;
    style?: Style;
    children: ReactNode;
    lineHeight?: LineHeight;
    onClick?: Function;
    isPointerCursor?: boolean;
    whiteSpace?: WhiteSpace;
    vAlign?: VAlign;
    textAlign?: TextAlign;
}

export const Text = ({
    children,
    lineHeight,
    size = TextSize['13px'],
    desktopSize,
    tabletSize,
    mediumDesktopSize,
    style = Style.normal,
    weight = Weight.w500,
    onClick = noop,
    isPointerCursor = false,
    whiteSpace = WhiteSpace.normal,
    vAlign = VAlign.baseLine,
    textAlign = TextAlign.left,
}: Props): ReactElement => {
    const handleOnClick = (): void => onClick();

    return (
        <span
            className={classNames({
                [styles.root]: true,
                [styles[`root_style_${style}`]]: style,
                [styles.isCursorPointer]: isPointerCursor,
                [styles[`root_weight_${weight}`]]: weight,
                [styles[`root_size_${size}`]]: size,
                [styles[`rootDesktop_size_${desktopSize}`]]: desktopSize,
                [styles[`rootMediumDesktop_size_${mediumDesktopSize}`]]: mediumDesktopSize,
                [styles[`rootTablet_size_${tabletSize}`]]: tabletSize,
                [styles[`root_lineHeight_${lineHeight}`]]: lineHeight,
                [styles[`root_whiteSpace_${whiteSpace}`]]: whiteSpace,
                [styles[`root_vAlign_${vAlign}`]]: vAlign,
                [styles[`root_textAlign_${textAlign}`]]: textAlign,
            })}
            onClick={handleOnClick}
        >
            {children}
        </span>
    );
};

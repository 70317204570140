import React, { createRef, FormEvent, Fragment, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import classNames from 'clsx';
import { defineMessages, FormattedMessage } from 'react-intl';

import styles from './Buy.css';
import { Modal } from 'src/ui/Modal/Modal';
import { isMonthPeriod, isYearPeriod, secondsInADay } from 'src/utils/period';
import { AppState } from 'src/store';
import { getTariffById } from 'src/modules/tariffs/tariffs.selectors';
import { Currency, CURRENCY_SYMBOL, TariffMods } from 'src/modules/tariffs/tariffs.types';
import { Colors, Link } from 'src/ui/Link/Link';
import { Button, ButtonColors } from 'src/ui/Button/Button';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import {
    getBindLink as getBindLinkAction,
    getLink as getLinkAction,
    getProlongLink as getProlongLinkAction,
} from 'src/modules/buy/buy.actions';
import { dmrHasError, getDMRUrl, isDMRLoading } from 'src/modules/buy/buy.selectors';
import { i18n } from 'src/utils/i18n';
import { Checkbox } from 'src/ui/Checkbox/Checkbox';
import { isRussianLanguage } from 'src/modules/language/language.selectors';
import { sendGa } from 'src/utils/ga';
import { Nowrap } from 'src/ui/Nowrap/Nowrap';
import { formatPrice, getMonthlyPrice } from 'src/utils/price.helpers';
import { config } from 'src/constants/config';
import { noop } from 'src/utils/helpers';
import { Loader } from 'src/components/Loader/Loader';
import { isPhone as isPhoneCheck } from 'src/modules/media/media.selectors';
import { QueryActions } from 'src/constants/queryActions';
import { validateEmail } from 'src/utils/email.helpers';

const messages = defineMessages({
    titleYear: `Подписка на {period, plural,
        one {# год}
        few {# года}
        other {# лет}
    }`,
    titleMonth: `Подписка на {period, plural,
        one {# месяц}
        few {# месяца}
        other {# месяцев}
    }`,
    trialTitle: `Пробный период`,
    email: 'Введите  email, на который мы пришлем лицензионный ключ',
    la: 'Я принимаю <a>правила сервиса</a> и <a>условия оферты</a>',
    next: 'Продолжить',
    price: 'К оплате: <span>{price} {currency}</span>',
    pricePeriod: 'месяц',
    priceWarningYear: `С вашего счета будет списано <nowrap>{price} {currency}</nowrap> за <nowrap>{period, plural,
        one {# год}
        few {# года}
        other {# лет}
    }</nowrap> использования <nowrap>Диск-О:</nowrap>`,
    priceWarningMonth: `С вашего счета будет списано <nowrap>{price} {currency}</nowrap> за <nowrap>{period, plural,
        one {# месяц}
        few {# месяца}
        other {# месяцев}
    }</nowrap> использования <nowrap>Диск-О:</nowrap>`,
    actionPriceWarning:
        '{br}Стоимость продления тарифа, начиная со 2-го года, составляет <nowrap>{price} {currency} / год.</nowrap> Продление автоматическое. Подписку можно отменить в любой момент.',
    trialPriceWarning:
        'Привяжите карту для получения бесплатного периода. Стоимость тарифа <nowrap>{price} {currency} в месяц,</nowrap> начиная с {startDate}. Подписка продлевается автоматически, ее можно отменить в любой момент.',
    trialPriceWarningYear:
        'Привяжите карту для получения бесплатного периода. Стоимость тарифа <nowrap>{price} {currency} в год,</nowrap> начиная с {startDate}. Подписка продлевается автоматически, ее можно отменить в любой момент.',
    trialPeriod: `Бесплатный период <nowrap>{trialPeriodDays, plural,
        one {# день}
        few {# дня}
        other {# дней}
    }</nowrap>`,
    errorWrongEmail: 'Неверный адрес',
    error: 'Произошла ошибка',
    errorRequired: 'Укажите адрес',
    subscriptionPurchase: 'Покупка подписки',
    subscriptionBind: 'Смена карты',
    personalOffer: 'Персональное предложение',
    borderTitle: 'Новый год в Диск-О:',
});

const ERRORS = {
    error: i18n.formatMessage(messages.error),
    required: i18n.formatMessage(messages.errorRequired),
    wrongEmail: i18n.formatMessage(messages.errorWrongEmail),
};

const IFRAME_MIN_HEIGHT = 605;

enum DmrFrameEvents {
    LOADED = 'loaded',
    LOADING = 'loading',
    LOAD_ERROR = 'load-error',
    LOAD_ERROR_TIMEOUT = 'load-error-timeout',
    RETRY = 'retry',
    PAYMENT_SUCCESS = 'payment-success',
    PAYMENT_WAITING = 'payment-waiting',
    PAYMENT_FAIL = 'payment-fail',
    CLOSE = 'close',
    RESIZE_FRAME = 'resizeFrame',
    PAGE_LOAD = 'pageLoad',
}

interface MapState {
    period: string;
    currency: string;
    /**  Полная цена, что будет списана */
    price: string;
    /** Цена в месяц */
    priceMonthly?: string;
    /** Полная цена, без скидок. Например, будет списана на 2 период  */
    oldPrice?: string;
    link: string;
    isLoading: boolean;
    hasError: boolean;
    mod?: TariffMods | null;
    trialPeriod: number;
    isPhone: boolean;
}

interface MapDispatch {
    getLink: Function;
    getProlongLink: Function;
    getBindLink: Function;
}

interface Props {
    id?: string;
    alias?: string;
    action?: string;
    onClose: Function;
    onBuySuccess?: Function;
}

interface State {
    error: string;
    agreed: boolean;
    showModal: boolean;
    showModalDmr: boolean;
    iframeHeight: number | undefined;
    isDMRLoaded: boolean;
}

interface ActionParamsOnMessageEvent {
    height?: number;
    eventName?: string;
}

const mapStateToProps = (state: AppState, props: Props): MapState => {
    const { durationStr = '', price = 0, currency, oldPrice, tariffMod, trialPeriod = 0 } = getTariffById(state, props.id ?? '') || {};

    const precision = currency === Currency.RUB ? 0 : 2;

    return {
        period: durationStr,
        price: formatPrice(price, precision),
        oldPrice: formatPrice(oldPrice, precision),
        currency: currency ? CURRENCY_SYMBOL[currency] : '',
        link: getDMRUrl(state),
        hasError: dmrHasError(state),
        isLoading: isDMRLoading(state),
        priceMonthly: getMonthlyPrice(price, durationStr, precision),
        mod: tariffMod,
        trialPeriod: trialPeriod || 0,
        isPhone: isPhoneCheck(state),
    };
};

const mapDispatchToProps = {
    getLink: getLinkAction,
    getProlongLink: getProlongLinkAction,
    getBindLink: getBindLinkAction,
};

export class BuyComponent extends PureComponent<MapState & MapDispatch & Props, State> {
    public readonly state = {
        error: '',
        agreed: false,
        showModal: true,
        showModalDmr: false,
        iframeHeight: IFRAME_MIN_HEIGHT,
        isDMRLoaded: false,
    };

    dmr: Window | null = null;
    frameTimeout: null | number = null;
    frameLoaded = false;
    inputRef = createRef<HTMLInputElement>();

    componentDidMount(): void {
        const { period, price, currency, onClose, alias, action, getProlongLink, getBindLink } = this.props;

        if (!alias && !period && !price && !currency) {
            onClose();
        }

        if (window.addEventListener) {
            window.addEventListener('message', this.onMessage, false);
        } else {
            (window as any).attachEvent('onmessage', this.onMessage, false);
        }

        if (!alias) {
            return;
        }

        if (action === QueryActions.requestProlong) {
            sendGa({
                category: 'prolong',
                action: 'show',
            });
            getProlongLink({ alias });
        } else if (action === QueryActions.requestBind) {
            sendGa({
                category: 'bind',
                action: 'show',
            });
            getBindLink({ alias });
        }

        this.setState({
            showModalDmr: true,
            showModal: false,
        });
    }

    componentWillUnmount(): void {
        window.removeEventListener('message', this.onMessage);
    }

    componentDidUpdate(prevProps: Readonly<MapState & MapDispatch & Props>): void {
        const { id, link, hasError, isLoading, trialPeriod } = this.props;

        if (!link || prevProps.link) {
            return;
        }

        this.initDmr();

        if (hasError) {
            sendGa({
                category: 'payment',
                action: 'dmr',
                label: 'error',
            });
            sendGa({
                category: 'payment',
                action: 'dmr',
                label: `error-${id}`,
            });
            this.setError(ERRORS.error);
        } else {
            if (!isLoading) {
                sendGa({
                    category: 'payment',
                    action: 'dmr',
                    label: 'load',
                });
                sendGa({
                    category: 'payment',
                    action: 'dmr',
                    label: `load-${id}`,
                });
            }
            if (trialPeriod > 0) {
                sendGa({
                    category: 'payment',
                    action: 'dmr_trial_load',
                });
            }
        }
    }

    setError = (error: string) => {
        this.setState({
            error,
        });
    };

    initDmr = (): void => {
        const { id, link } = this.props;

        if (link) {
            sendGa({
                category: 'payment',
                action: 'dmr',
                label: 'open',
            });
            sendGa({
                category: 'payment',
                action: 'dmr',
                label: `open-${id}`,
            });
            this.frameTimeout = window.setTimeout(this.onFrameTimeout, config.dmrTimeout);
        }
    };

    handleDmrFrameEvent = (event: DmrFrameEvents | string, action_params?: ActionParamsOnMessageEvent): void => {
        const { id } = this.props;

        // eslint-disable-next-line sonarjs/max-switch-cases
        switch (event) {
            case DmrFrameEvents.PAGE_LOAD:
                this.setState({
                    isDMRLoaded: true,
                });
                return;
            case DmrFrameEvents.LOADING:
                sendGa({
                    category: 'dmr',
                    action: 'load',
                    label: 'start',
                });
                sendGa({
                    category: 'dmr',
                    action: 'load',
                    label: `start-${id}`,
                });
                return;
            case DmrFrameEvents.LOADED:
                sendGa({
                    category: 'dmr',
                    action: 'load',
                    label: 'finish',
                });
                sendGa({
                    category: 'dmr',
                    action: 'load',
                    label: `finish-${id}`,
                });
                return;
            case DmrFrameEvents.LOAD_ERROR:
                sendGa({
                    category: 'dmr',
                    action: 'load',
                    label: 'error',
                });
                sendGa({
                    category: 'dmr',
                    action: 'load',
                    label: `error-${id}`,
                });
                return;
            case DmrFrameEvents.LOAD_ERROR_TIMEOUT:
                sendGa({
                    category: 'dmr',
                    action: 'load',
                    label: 'error-timeout',
                });
                sendGa({
                    category: 'dmr',
                    action: 'load',
                    label: `error-timeout-${id}`,
                });
                return;
            case DmrFrameEvents.RETRY:
                sendGa({
                    category: 'dmr',
                    action: 'retry',
                });
                sendGa({
                    category: 'dmr',
                    action: `retry-${id}`,
                });
                this.initDmr();
                return;
            case DmrFrameEvents.CLOSE:
                sendGa({
                    category: 'dmr',
                    action: 'close',
                });
                sendGa({
                    category: 'dmr',
                    action: `close-${id}`,
                });
                this.onCloseModalDmr();
                return;
            case DmrFrameEvents.PAYMENT_SUCCESS: {
                const { price, onBuySuccess = noop, trialPeriod } = this.props;

                sendGa({
                    category: 'dmr',
                    action: 'payment',
                    label: 'success',
                });
                sendGa({
                    category: 'payment',
                    action: 'dmr_success',
                    label: id,
                });
                if (trialPeriod > 0) {
                    sendGa({
                        category: 'payment',
                        action: 'dmr_trial_success',
                        label: id,
                    });
                }

                sendGa({
                    category: 'payment',
                    action: 'dmr_modal_success',
                    label: id,
                });

                onBuySuccess(id);
                sendGa({
                    category: 'ab-cloud',
                    action: 'sum',
                    iData: { price: parseInt(price) },
                });
                return;
            }
            case DmrFrameEvents.RESIZE_FRAME:
                if (action_params?.height) {
                    this.setState({
                        iframeHeight: Math.max(action_params.height, IFRAME_MIN_HEIGHT),
                    });
                }
                return;
            case DmrFrameEvents.PAYMENT_WAITING:
                sendGa({
                    category: 'dmr',
                    action: 'payment',
                    label: 'waiting',
                });
                sendGa({
                    category: 'dmr',
                    action: 'payment',
                    label: `waiting-${id}`,
                });
                return;
            case DmrFrameEvents.PAYMENT_FAIL:
                sendGa({
                    category: 'dmr',
                    action: 'payment',
                    label: 'fail',
                });
                sendGa({
                    category: 'dmr',
                    action: 'payment',
                    label: `fail-${id}`,
                });
                return;
            default:
                sendGa({
                    category: 'dmr',
                    action: event,
                });
                sendGa({
                    category: 'dmr',
                    action: event,
                    label: id,
                });
        }
    };

    onFrameTimeout = (): void => {
        this.handleDmrFrameEvent(DmrFrameEvents.LOAD_ERROR_TIMEOUT);
        this.handleDmrFrameEvent(DmrFrameEvents.LOAD_ERROR);
    };

    handleBillingMessages = (action: string, action_params: ActionParamsOnMessageEvent) => {
        if (action === 'paySuccess') {
            this.handleDmrFrameEvent(DmrFrameEvents.PAYMENT_SUCCESS);
        } else if (action === 'payError') {
            this.handleDmrFrameEvent(DmrFrameEvents.PAYMENT_FAIL);
        } else if (action === 'pageLoad') {
            this.handleDmrFrameEvent(DmrFrameEvents.PAGE_LOAD);
        } else if (
            action === 'closeWindow' ||
            action === 'payCancel' ||
            (action === 'fireEvent' && action_params.eventName === 'form_cancel-button-click')
        ) {
            this.handleDmrFrameEvent(DmrFrameEvents.CLOSE);
        } else if (action === 'putPixel') {
            // ignore
        } else {
            this.handleDmrFrameEvent(action, action_params);
        }
    };

    handlePaymentMessages = (action: string) => {
        if (action === 'refreshWindow') {
            this.handleDmrFrameEvent(DmrFrameEvents.RETRY);
        } else if (action === 'closeWindow') {
            this.handleDmrFrameEvent(DmrFrameEvents.CLOSE);
        }
    };

    onMessage = (event: MessageEvent): void => {
        if (!event?.origin.match(/https:\/\/[^/]+\.(devmail|mail)\.ru(:\d+)?$/i)) {
            return;
        }

        let data = (event as any).message || event.data;

        try {
            data = JSON.parse(data);
        } catch (error) {
            data = {};
        }

        const { type, action, action_params } = data;

        if (!type || !action) {
            return;
        }

        console.debug('DMR postMessage', data);

        if (!this.frameLoaded) {
            this.frameLoaded = true;
            this.frameTimeout && window.clearTimeout(this.frameTimeout);

            if (type === 'billing' && action === 'payError') {
                this.handleDmrFrameEvent(DmrFrameEvents.LOAD_ERROR);
            } else {
                this.handleDmrFrameEvent(DmrFrameEvents.LOADED);
            }
        }

        if (type === 'payment') {
            this.handlePaymentMessages(action);
        } else if (type === 'billing') {
            this.handleBillingMessages(action, action_params);
        }
    };

    buy = (email: string): void => {
        const { id, mod, getLink } = this.props;

        this.setState({
            showModalDmr: true,
            showModal: false,
        });

        sendGa({
            category: 'payment',
            action: 'dmr_modal',
            label: 'open',
        });
        sendGa({
            category: 'payment',
            action: 'dmr_modal',
            label: `open-${id}`,
        });

        if (mod) {
            sendGa({
                category: 'payment',
                action: `dmr_modal_${mod}`,
                label: 'open',
            });
            sendGa({
                category: 'payment',
                action: `dmr_modal_${mod}`,
                label: `open-${id}`,
            });
        }

        getLink({ id, email });
    };

    handleOnBuyClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent> | FormEvent): void => {
        e.preventDefault();
        if (this.inputRef.current) {
            const email = this.inputRef.current.value.trim();

            if (!validateEmail(email)) {
                this.setState({
                    error: !email ? ERRORS.required : ERRORS.wrongEmail,
                });
            } else {
                this.buy(email);
            }
        }
    };

    handleOnChange = (): void => {
        this.setState({
            error: '',
        });
    };

    handleOnAgreeClick = (): void => {
        this.setState((prevState) => ({
            agreed: !prevState.agreed,
        }));
    };

    getTitleMessage = () => {
        const { trialPeriod, period } = this.props;

        if (trialPeriod > 0) {
            return messages.trialTitle;
        } else if (isYearPeriod(period)) {
            return messages.titleYear;
        }

        return messages.titleMonth;
    };

    getPriceWarningMessage = () => {
        const { trialPeriod, period } = this.props;

        if (trialPeriod > 0) {
            return isMonthPeriod(period) ? messages.trialPriceWarning : messages.trialPriceWarningYear;
        } else if (isMonthPeriod(period)) {
            return messages.priceWarningMonth;
        }

        return messages.priceWarningYear;
    };

    getLALink = () => {
        return isRussianLanguage() ? 'https://help.mail.ru/legal/terms/cloud/disko/LA' : 'https://disk-o.cloud/legal/disko_termsofuse';
    };

    isBundleMod = (mod: TariffMods | null | undefined): boolean => {
        return mod === TariffMods.bundle10 || mod === TariffMods.bundle20;
    };

    getModalHeader = () => {
        const { period, mod } = this.props;
        const isBundle = this.isBundleMod(mod);
        const isAction = mod === TariffMods.action;

        return (
            <>
                {isAction && (
                    <div className={styles.borderHeader}>
                        <Text size={TextSize['24px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.borderTitle} />
                        </Text>
                    </div>
                )}
                {isBundle && (
                    <div
                        className={classNames({
                            [styles.borderHeader]: true,
                            [styles.personalOffer]: true,
                        })}
                    >
                        <Text size={TextSize['28px']} weight={Weight.w500}>
                            <FormattedMessage {...messages.personalOffer} />
                        </Text>
                    </div>
                )}
                <FormattedMessage
                    {...this.getTitleMessage()}
                    values={{
                        period: parseInt(period),
                    }}
                />
            </>
        );
    };

    getPaymentInfo = () => {
        const { price, currency, priceMonthly, trialPeriod, isPhone } = this.props;

        const trialPeriodDays = trialPeriod > 0 ? trialPeriod / secondsInADay : 0;

        return (
            <div className={styles.sum}>
                <Text size={TextSize['17px']} weight={isPhone ? Weight.w700 : Weight.w500}>
                    {trialPeriod > 0 ? (
                        <FormattedMessage
                            {...messages.trialPeriod}
                            values={{
                                trialPeriodDays,
                                nowrap: Nowrap,
                            }}
                        />
                    ) : (
                        <Fragment>
                            <FormattedMessage
                                {...messages.price}
                                values={{
                                    price: priceMonthly ? priceMonthly : price,
                                    currency,
                                    span: (msg: string): ReactElement => (
                                        <Text size={TextSize['24px']} weight={Weight.w700}>
                                            <span className={styles.price}>{msg}</span>
                                        </Text>
                                    ),
                                }}
                            />
                            {!!priceMonthly && (
                                <span className={styles.pricePeriod}>
                                    <Text size={TextSize['17px']} weight={Weight.w500}>
                                        / {i18n.formatMessage(messages.pricePeriod)} *
                                    </Text>
                                </span>
                            )}
                        </Fragment>
                    )}
                </Text>
            </div>
        );
    };

    renderModal = () => {
        const { period, price, currency, priceMonthly, oldPrice, mod, trialPeriod, isPhone, onClose, isLoading } = this.props;

        const isAction = this.isBundleMod(mod);
        const trialPeriodDays = trialPeriod > 0 ? trialPeriod / secondsInADay : 0;
        const currDate = new Date();
        currDate.setUTCDate(currDate.getUTCDate() + trialPeriodDays);
        const trialStartDate = trialPeriodDays > 0 ? currDate.toLocaleDateString() : 0;

        return (
            <Modal mod={mod && mod.toString()} onClose={onClose} header={this.getModalHeader()}>
                <form className={styles.root} onSubmit={this.handleOnBuyClick}>
                    <label htmlFor="email-order">
                        <Text size={TextSize['13px']} weight={Weight.w700}>
                            <FormattedMessage {...messages.email} />
                        </Text>
                    </label>
                    <div className={styles.inputWrap}>
                        <input
                            className={classNames({
                                [styles.input]: true,
                                [styles.input_error]: this.state.error,
                            })}
                            type="email"
                            id="email-order"
                            name="email-order"
                            ref={this.inputRef}
                            onChange={this.handleOnChange}
                        />
                        {this.state.error && (
                            <div className={styles.error}>
                                <Text size={TextSize['15px']} weight={Weight.w500}>
                                    {this.state.error}
                                </Text>
                            </div>
                        )}
                    </div>
                    <div className={styles.agree}>
                        <div className={styles.checkbox}>
                            <Checkbox checked={this.state.agreed} onChange={this.handleOnAgreeClick} />
                        </div>
                        <Text size={TextSize['17px']} weight={Weight.w500} onClick={this.handleOnAgreeClick}>
                            <FormattedMessage
                                {...messages.la}
                                values={{
                                    a: (msg: string): ReactElement => (
                                        <Link color={Colors.primary} target="_blank" href={this.getLALink()}>
                                            {msg}
                                        </Link>
                                    ),
                                }}
                            />
                        </Text>
                    </div>
                    {isPhone && this.getPaymentInfo()}
                    <div className={styles.footer}>
                        <Button color={ButtonColors.PRIMARY} onClick={this.handleOnBuyClick} disabled={!this.state.agreed || isLoading}>
                            <Text size={TextSize['20px']}>
                                <FormattedMessage {...messages.next} />
                            </Text>
                        </Button>
                        {!isPhone && this.getPaymentInfo()}
                    </div>
                    {!!priceMonthly && (
                        <div className={styles.priceWarning}>
                            <sup>*</sup>
                            <Text size={TextSize['13px']}>
                                <FormattedMessage
                                    {...this.getPriceWarningMessage()}
                                    values={{
                                        price,
                                        period: parseInt(period),
                                        nowrap: Nowrap,
                                        startDate: trialStartDate,
                                        currency,
                                    }}
                                />
                            </Text>
                            {isAction && !!oldPrice && (
                                <Text size={TextSize['13px']}>
                                    <FormattedMessage
                                        {...messages.actionPriceWarning}
                                        values={{
                                            price: oldPrice,
                                            nowrap: Nowrap,
                                            br: <br />,
                                            currency,
                                        }}
                                    />
                                </Text>
                            )}
                        </div>
                    )}
                </form>
            </Modal>
        );
    };

    renderModalDmr = () => {
        const { action, isLoading, hasError, link } = this.props;
        const { isDMRLoaded, iframeHeight } = this.state;

        let headerMessage = messages.subscriptionPurchase;
        if (action === QueryActions.requestBind) {
            headerMessage = messages.subscriptionBind;
        }

        const height = `${iframeHeight}px`;

        return (
            <Modal
                header={
                    <Text size={TextSize['24px']} weight={Weight.w700}>
                        <FormattedMessage {...headerMessage} />
                    </Text>
                }
                onClose={this.onCloseModalDmr}
            >
                <div
                    style={{ height }}
                    className={classNames({
                        [styles.isHide]: isDMRLoaded || (hasError && !isLoading),
                    })}
                >
                    <Loader fullSize />
                </div>
                {Boolean(!isDMRLoaded && hasError && !isLoading) && (
                    <div style={{ height }} className={styles.errorMessage}>
                        {ERRORS.error}
                    </div>
                )}
                <iframe
                    style={{ height }}
                    className={classNames({
                        [styles.iframe]: true,
                        [styles.isHide]: !isDMRLoaded,
                    })}
                    src={link}
                    title="iframeDmr"
                />
            </Modal>
        );
    };

    onCloseModalDmr = () => {
        const { action, onClose } = this.props;

        this.setState({
            isDMRLoaded: false,
            showModalDmr: false,
            showModal: !action,
        });

        if (action) {
            onClose();
        }
    };

    render(): ReactElement {
        return (
            <div className={styles.root}>
                {this.state.showModal && this.renderModal()}
                {this.state.showModalDmr && this.renderModalDmr()}
            </div>
        );
    }
}

export const Buy = connect(mapStateToProps, mapDispatchToProps)(BuyComponent);

import { API } from 'src/modules/api/api';

export interface GetProlongRequest {
    alias: string;
    language: string;
}

export interface GetProlongResponse {
    url: string;
}

export const getProlong = (params: GetProlongRequest): Promise<GetProlongResponse> =>
    new API().makeRequest({
        method: 'post',
        url: 'serial/prolong',
        body: params,
    });

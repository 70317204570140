import React, { ReactNode } from 'react';
import { useIntersectionObserver } from 'src/hooks/useIntersectionObserver';

interface Props {
    children: ReactNode;
    onIntersecting: () => void;
}

export const Observer = ({ children, onIntersecting }: Props) => {
    const ref = useIntersectionObserver(onIntersecting);

    return <div ref={ref}>{children}</div>;
};

import React, { ReactElement, useCallback, useEffect } from 'react';
import styles from './Home.css';
import { Hero } from '../Hero/Hero';
import { Services } from 'src/ui/Services/Services';
import { Features } from 'src/ui/Features/Features';
import { Reviews } from 'src/ui/Reviews/Reviews';
import { HowItWorks } from 'src/ui/HotItWorks/HowItWorks';
import { Tariffs } from 'src/ui/Tariffs/Tariffs';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile as isMobileSelector } from 'src/modules/media/media.selectors';
import { Faq } from 'src/components/Faq/Faq';
import { GetLinkViaEmail } from 'src/components/GetLinkViaEmail/GetLinkViaEmail';
import { sendGa } from 'src/utils/ga';
import { defineMessages } from 'react-intl';
import { FormattedMessage } from 'src/utils/i18n';
import { isRussianLanguage } from 'src/modules/language/language.selectors';
import videoPoster from 'img/video-poster.png';
import videoPosterEn from 'img/video-poster_en.png';
import { Link } from '../Link/Link';
import { isMacOS } from 'src/utils/ua-parser';
import { showDownloadPromo } from 'src/modules/downloadPromo/downloadPromo.actions';
import { getDownloadUrlGetter } from 'src/modules/links/links.selectors';

const messages = defineMessages({
    howItWorks: 'Как это работает',
    howItWorks1: '<a>Скачайте Диск-О:</a> на свой компьютер',
    howItWorks2: 'Подключите к приложению ваши облачные хранилища',
    howItWorks3: 'Загружайте файлы в облака через Диск-О: быстрее, чем через браузер',
});

const howItWorks3 =
    'Загружайте файлы через Диск-О: и\u00A0переносите их\u00A0вручную между любыми подключёнными облаками совершенно бесплатно. Или перенесите все файлы сразу в\u00A0Облако Mail.ru!';

export const Home = () => {
    const isMobile = useSelector(isMobileSelector);
    const getDownloadUrl = useSelector(getDownloadUrlGetter);

    const dispatch = useDispatch();

    const isRu = isRussianLanguage();

    useEffect(() => {
        sendGa({
            category: 'event',
            action: 'show',
            label: 'mainAboutBlock',
        });
    }, []);

    const onDownloadClick = useCallback((): void => {
        window.open(getDownloadUrl(), isMacOS ? '_blank' : '_self');
    }, []);

    const handleClick = useCallback(() => {
        onDownloadClick();

        if (!isMacOS) {
            dispatch(showDownloadPromo());
        }
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.hero}>
                <Hero />
            </div>
            <div className={styles.services}>
                <Services />
            </div>
            {isMobile && (
                <div className={styles.getLinkViaEmail}>
                    <GetLinkViaEmail />
                </div>
            )}
            <div className={styles.features}>
                <Features presentationCount={isRu ? 2 : 0} isRu={isRu} />
            </div>
            {!isMobile && (
                <div className={styles.howItWorks}>
                    <HowItWorks
                        secondBg={true}
                        title={<FormattedMessage {...messages.howItWorks} />}
                        steps={[
                            {
                                text: (
                                    <FormattedMessage
                                        {...messages.howItWorks1}
                                        values={{
                                            a: (msg: string): ReactElement => (
                                                <Link key="download" onClick={handleClick}>
                                                    {msg}
                                                </Link>
                                            ),
                                        }}
                                    />
                                ),
                                step: 1,
                            },
                            {
                                text: <FormattedMessage {...messages.howItWorks2} />,
                                step: 2,
                            },
                            {
                                text: isRu ? howItWorks3 : <FormattedMessage {...messages.howItWorks3} />,
                                step: 3,
                            },
                        ]}
                        poster={isRu ? videoPoster : videoPosterEn}
                        videoUrl={`https://www.youtube.com/embed/${isRu ? 'WkkQVe6-hK4' : 'DRdmrBRD4og'}?controls=0`}
                        showDownload
                        newPosterMod
                        wideVideoPreview={!isRu}
                    />
                </div>
            )}
            <div className={styles.tariffs}>
                <Tariffs />
            </div>
            <div className={styles.faq}>
                <Faq />
            </div>
            {isRu && (
                <div className={styles.reviews}>
                    <Reviews />
                </div>
            )}
        </div>
    );
};

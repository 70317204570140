import React, { ReactElement } from 'react';
import { defineMessages } from 'react-intl';
import { FormattedMessage } from 'src/utils/i18n';
import styles from './Tabs.css';
import { TabTitle } from 'src/ui/HelpPage/Tabs/TabTitle';
import { TabName } from 'src/ui/HelpPage/Tabs/TabName';
import { TabText } from 'src/ui/HelpPage/Tabs/TabText';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { Link } from 'src/ui/Link/Link';

const messages = defineMessages({
    text1:
        '<nowrap><a>Диск-О:</a></nowrap>{nbsp}{mdash} программа, которая позволяет работать с{nbsp}вашими облаками в{nbsp}одном месте{nbsp}{mdash} на{nbsp}компьютере. При этом файлы не{nbsp}занимают места на{nbsp}устройстве, потому что хранятся только на{nbsp}ваших облаках. Программа работает с{nbsp}Облаком Mail.ru, Яндекс. Диском, Google Drive, Dropbox и{nbsp}другими хранилищами.',
    name1: 'Превращает облака в{nbsp}жесткие диски',
    text2:
        'Когда вы{nbsp}подключаете облако с{nbsp}помощью <nowrap>Диск-О:</nowrap>, на{nbsp}вашем компьютере оно выглядит как обычный жесткий диск. Это позволяет работать с{nbsp}файлами в{nbsp}привычном интерфейсе ОС{nbsp}без задержек интернета. При отключении с{nbsp}файлами ничего не{nbsp}произойдет{nbsp}{mdash} это все равно, что выдернуть флешку.',
    name2: 'Файлы синхронизируются',
    text3:
        'Вы{nbsp}загружаете файл на{nbsp}облако в{nbsp}браузере, и{nbsp}через пару секунд он{nbsp}отображается на{nbsp}компьютере. И{nbsp}наоборот: перекидываете файлы с{nbsp}рабочего стола на{nbsp}подключенный диск{nbsp}{mdash} и{nbsp}уже видите их{nbsp}в{nbsp}браузере. Точно так{nbsp}же с{nbsp}удалением и{nbsp}созданием папок: любые действия синхронизируются между облаком на{nbsp}компьютере и{nbsp}в{nbsp}браузере.',
    name3: 'Одно приложение для разных облаков',
    text4:
        '<nowrap>Диск-О:</nowrap> поддерживает облака популярных хранилищ: Облако Mail.ru, Яндекс. Диск, Dropbox, Google Drive, Box. Соберите все облака на{nbsp}компьютере с{nbsp}помощью <nowrap>Диск-О:</nowrap>, чтобы не{nbsp}переключаться между ними в{nbsp}браузере.',
    name4: 'Работает с{nbsp}файлами в{nbsp}любых программах',
    text5:
        'Создавать и{nbsp}редактировать файлы онлайн не{nbsp}всегда удобно. C{nbsp}помощью <nowrap>Диск-О:</nowrap> вы{nbsp}можете открывать и{nbsp}создавать файлы на{nbsp}компьютере, минуя путь: загрузить из{nbsp}Облака в{nbsp}браузере на{nbsp}ПК, отредактировать, загрузить назад в{nbsp}облако. Вы{nbsp}просто открываете и{nbsp}редактируете файлы, а{nbsp}<nowrap>Диск-О:</nowrap> берет на{nbsp}себя все остальное. Это позволяет удобно работать с{nbsp}Word, PowerPoint, Photoshop, AutoCad и{nbsp}другими, при этом держа свои файлы в{nbsp}надежном облаке.',
});

export const About = ({ title }: { title: string }): ReactElement => (
    <div className={styles.tab}>
        <TabTitle>{title}</TabTitle>
        <TabText>
            <FormattedMessage
                {...messages.text1}
                values={{
                    // eslint-disable-next-line react/display-name
                    a: (msg: string) => <Link href={`/${getCurrentLanguage()}`}>{msg}</Link>,
                }}
            />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name1} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text2} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name2} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text3} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name3} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text4} />
        </TabText>
        <TabName>
            <FormattedMessage {...messages.name4} />
        </TabName>
        <TabText>
            <FormattedMessage {...messages.text5} />
        </TabText>
    </div>
);

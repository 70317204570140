import xray from 'src/utils/xray';
import { Params } from '@mail/xray';
import { isSSR } from 'src/utils/ua-parser';
import { getClientId } from 'src/utils/jigurda';

function iReplacer(val: string): string {
    return val.toString().replace(/[^a-zA-Z0-9-]/gi, '-');
}

export interface GaData {
    category?: string;
    action?: string;
    label?: string;
    /** Example: {'ext':1, 'time': 1000} */
    iData?: { [key: string]: number } | null;
    sendImmediately?: boolean;
}

const sendGa = ({ category = '', action = '', label = '', iData = null, sendImmediately = false }: GaData): void => {
    if (isSSR) {
        return;
    }

    let name = iReplacer(category);

    if (action) {
        name += '_' + iReplacer(action);
    }

    if (label) {
        name += '_' + iReplacer(label);
    }

    let params: Params | undefined;

    if (iData) {
        params = {
            i: iData,
        } as Params;
    }

    // t - длина не должна превышать 64 символа
    if (sendImmediately) {
        xray.sendImmediately(name, params, true);
    } else {
        xray.send(name, params, true);
    }
};

export const sendDwh = async ({
    category,
    action = '',
    label = '',
    sendImmediately = false,
    dwhData = null,
}: {
    category: string;
    action?: string;
    label?: string;
    sendImmediately?: boolean;
    dwhData?: any;
}) => {
    const name = iReplacer(category);

    const i: string[] = [];

    if (action) {
        i.push(`${iReplacer(action)}`);
    }

    if (label) {
        i.push(`${iReplacer(label)}`);
    }

    const params: any = {};

    if (i.length) {
        params.i = i.join('_');
    }

    if (dwhData) {
        params.dwh = dwhData;
        params.dwh.client_id = await getClientId();
    }

    if (sendImmediately) {
        xray.sendImmediately(name, params);
    } else {
        xray.send(name, params);
    }
};

export { sendGa };

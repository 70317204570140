import React, { PureComponent, ReactElement } from 'react';

import styles from './Cookie.css';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { AvailableLanguages } from 'src/modules/language/language.types';
import { Colors, Link } from 'src/ui/Link/Link';
import { Text, TextSize } from 'src/ui/Text/Text';
import { Button, ButtonColors, ButtonSize } from 'src/ui/Button/Button';

let isAccepted: boolean;

try {
    isAccepted = !!localStorage.getItem('cookie-accepted');
} catch (error) {
    isAccepted = false;
}

interface State {
    show: boolean;
}

export class Cookie extends PureComponent<{}, State> {
    public readonly state = {
        show: !isAccepted,
    };

    handleOnAcceptClick = (): void => {
        this.setState({
            show: false,
        });
        try {
            localStorage.setItem('cookie-accepted', 'true');
        } catch (error) {}
    };
    render(): ReactElement | null {
        if (getCurrentLanguage() === AvailableLanguages.ru || !this.state.show) {
            return null;
        }

        return (
            <div className={styles.root}>
                <Text size={TextSize['17px']}>
                    By continuing to browse, you consent to our use of cookies.{' '}
                    <Link href="https://legal.my.com/us/general/cookie/" target="_blank" underline color={Colors.white}>
                        You can read our Cookies Police here.
                    </Link>
                </Text>
                <div className={styles.button}>
                    <Button size={ButtonSize.XS} color={ButtonColors.WHITE} onClick={this.handleOnAcceptClick}>
                        <Text size={TextSize['17px']}>Got it</Text>
                    </Button>
                </div>
            </div>
        );
    }
}

import React, { ReactElement, ReactNode } from 'react';
import { createIntl, createIntlCache, MessageDescriptor, FormattedMessage as FormattedMessageIntl } from 'react-intl';
import en from 'locales/en.json';
import de from 'locales/de.json';
import fr from 'locales/fr.json';
import hi from 'locales/hi.json';
import tr from 'locales/tr.json';
import zh from 'locales/zh.json';
import es from 'locales/es.json';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { NBSP, MDASH, RAQUO, LAQUO } from 'src/constants/typography';
import { Nowrap } from 'src/ui/Nowrap/Nowrap';
import { Props } from 'react-intl/dist/components/message';

const messages: { [key: string]: Record<string, string> } = {
    en,
    de,
    fr,
    hi,
    tr,
    zh,
    es,
};

const locale = getCurrentLanguage();

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

export const i18n = createIntl(
    {
        locale,
        defaultLocale: 'ru',
        messages: messages[locale],
    },
    cache
);

// TODO: переписать на нормальный компонент для использвания внутри react. Сейчас есть проблемы с рендеригом, из-за оотсувия key, при использовании в values html tag
export const formatMessage = (msg: MessageDescriptor, values?: {}): string | ReactNode =>
    i18n.formatMessage(msg, {
        nbsp: NBSP,
        mdash: MDASH,
        raquo: RAQUO,
        laquo: LAQUO,
        nowrap: Nowrap,
        ...values,
    });

export const FormattedMessage = (props: Props): ReactElement => (
    <FormattedMessageIntl
        {...props}
        values={{
            nbsp: NBSP,
            mdash: MDASH,
            raquo: RAQUO,
            laquo: LAQUO,
            // eslint-disable-next-line react/display-name
            nowrap: (msg: string): ReactElement => <span style={{ whiteSpace: 'nowrap' }}>{msg}</span>,
            ...props.values,
        }}
    />
);
FormattedMessage.defaultProps = {
    values: {},
};

import React from 'react';
import { defineMessages } from 'react-intl';
import { FormattedMessage, i18n } from 'src/utils/i18n';
import foldersIcon from 'img/icons/folders.svg';
import updatesIcon from 'img/icons/updates.svg';
import defenseIcon from 'img/icons/defense.svg';
import copyIcon from 'img/icons/copyFlash.svg';
import deviceIcon from 'img/icons/device.svg';
import recoveryIcon from 'img/icons/recovery.svg';
import { Features } from 'src/ui/Features/Features';
import { isRussianLanguage } from 'src/modules/language/language.selectors';

const messages = defineMessages({
    title: 'Возможности <nowrap>Диск-О:</nowrap>',
    description:
        'Работайте с облаками как с папками в удобном интерфейсе и быстрее, чем в браузере. Возможности приложения постоянно расширяются. Следите за обновлениями!',
    folderTitle: 'Выбор папок и облаков',
    folderText:
        'Вы сами решаете, какие файлы защитить и куда их сохранить. Диск-О: не будет заполнять место в ваших облаках резервными копиями, которые вам не нужны.',
    syncTitle: 'Регулярное обновление',
    syncText: 'Все папки, у которых есть резервные копии, синхронизируются. Это значит, что в облаках всегда будут их актуальные версии.',
    deviceTitle: 'Доступ с любого устройства',
    deviceText:
        'Вы сможете просматривать файлы не только с компьютера. Резервные копии хранятся в облаках, поэтому у вас будет доступ к ним с любого устройства.',
    recoveryTitle: 'Восстановление данных',
    recoveryText:
        'Восстановление файлов и папок из облака на компьютер занимает всего несколько минут и позволяет вернуть папки именно в те места, где они хранились раньше.',
    defenseTitle: 'Защита резервных копий',
    defenseText: 'Все резервные копии защищены по протоколу SSL — никто не получит доступ к файлам без вашего ведома.',
    flashTitle: 'Резервные копии с флешки',
    flashText: 'Данные с накопителя не потеряются – они будут храниться в облаке и обновляться при каждом повторном подключении флешки.',
});

const features = [
    {
        name: i18n.formatMessage(messages.folderTitle),
        text: i18n.formatMessage(messages.folderText),
        logo: foldersIcon,
        size: {
            width: 36,
            height: 32,
        },
    },
    {
        name: i18n.formatMessage(messages.deviceTitle),
        text: i18n.formatMessage(messages.deviceText),
        logo: deviceIcon,
        size: {
            width: 31,
            height: 38,
        },
    },
    {
        name: i18n.formatMessage(messages.syncTitle),
        text: i18n.formatMessage(messages.syncText),
        logo: updatesIcon,
        size: {
            width: 39,
            height: 37,
        },
    },
    {
        name: i18n.formatMessage(messages.recoveryTitle),
        text: i18n.formatMessage(messages.recoveryText),
        logo: recoveryIcon,
        size: {
            width: 35,
            height: 27,
        },
    },
    {
        name: i18n.formatMessage(messages.flashTitle),
        text: i18n.formatMessage(messages.flashText),
        logo: copyIcon,
        size: {
            width: 36,
            height: 36,
        },
    },
    {
        name: i18n.formatMessage(messages.defenseTitle),
        text: i18n.formatMessage(messages.defenseText),
        logo: defenseIcon,
        size: {
            width: 35,
            height: 29,
        },
    },
];

export const BackupFeatures = () => {
    return (
        <Features
            features={features}
            description={<FormattedMessage {...messages.description} />}
            title={<FormattedMessage {...messages.title} />}
            isRu={isRussianLanguage()}
            mod="backups"
        />
    );
};

import React, { PureComponent, ReactElement, ReactNode } from 'react';
import classNames from 'clsx';

import styles from 'src/ui/Accordion/AccordionItem.css';
import { LineHeight, Text, TextSize, Weight } from 'src/ui/Text/Text';

interface AccordionItemState {
    collapsed: boolean;
}

export interface AccordionItemProps {
    title: ReactNode;
    content: ReactNode;
    isCollapsed?: boolean;
    darkBlock?: boolean;
    index?: number;
    // Если передан обработчик toggle, то состояние открыт-закрыт хранится снаружи
    onToggle?: Function;
}

export class AccordionItem extends PureComponent<AccordionItemProps, AccordionItemState> {
    state = {
        collapsed: this.props.isCollapsed || false,
    };

    private handleOnToggle = (): void => {
        const { onToggle, index } = this.props;
        if (onToggle) {
            onToggle(index);
            return;
        }
        this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
    };

    render(): ReactElement {
        const { title, content, isCollapsed, onToggle, darkBlock } = this.props;
        let { collapsed } = this.state;
        if (onToggle) {
            collapsed = isCollapsed || false;
        }

        return (
            <div
                className={classNames({ [styles.root]: true, [styles.rootCollapsed]: collapsed, [styles.rootDark]: darkBlock })}
                onClick={this.handleOnToggle}
            >
                <div className={classNames({ [styles.title]: true, [styles.titleCollapsed]: collapsed })}>
                    <Text size={TextSize['20px']} weight={Weight.w500} isPointerCursor={collapsed}>
                        {title}
                    </Text>
                    <div
                        className={classNames({ [styles.close]: true, [styles.closeCollapsed]: collapsed })}
                        onClick={this.handleOnToggle}
                    />
                </div>
                <div
                    className={classNames({
                        [styles.content]: true,
                        [styles.contentCollapsed]: collapsed,
                    })}
                >
                    {collapsed ? null : (
                        <Text size={TextSize['20px']} weight={Weight.w500} lineHeight={LineHeight['30px']}>
                            {content}
                        </Text>
                    )}
                </div>
            </div>
        );
    }
}

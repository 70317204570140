import React, { PureComponent, ReactElement, ReactNode, ReactNodeArray } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import styles from './Carousel.css';
import { TariffMods } from 'src/modules/tariffs/tariffs.types';
import classNames from 'clsx';

interface Props {
    dots?: boolean;
    speed?: number;
    variableWidth?: boolean;
    infinite?: boolean;
    slidesToScroll?: number;
    slidesToShow?: number;
    arrows?: boolean;
    maxWidth?: string;
    children: ReactNode | ReactNodeArray;
    tariffMod?: TariffMods | null;
    initialSlide: number;
}

export class Carousel extends PureComponent<Props> {
    static defaultProps = {
        dots: true,
        speed: 500,
        variableWidth: false,
        infinite: false,
        arrows: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        maxWidth: 'none',
        initialSlide: 0,
    };

    render(): ReactElement {
        const {
            children,
            dots,
            speed,
            variableWidth,
            maxWidth,
            infinite,
            arrows,
            slidesToScroll,
            slidesToShow,
            tariffMod,
            initialSlide,
        } = this.props;
        return (
            <div className={styles.root}>
                <Slider
                    dots={dots}
                    speed={speed}
                    variableWidth={variableWidth}
                    infinite={infinite}
                    arrows={arrows}
                    slidesToScroll={slidesToScroll}
                    slidesToShow={slidesToShow}
                    initialSlide={initialSlide}
                >
                    {React.Children.map(children, (child) => (
                        <div className={classNames({ [styles.itemWrap]: true, [styles[`itemWrap_${tariffMod}`]]: Boolean(tariffMod) })}>
                            <div className={styles.item} style={{ maxWidth }}>
                                {child}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}

import { API } from 'src/modules/api/api';

interface SubmitBusinessRequestData {
    email: string;
    licenses: number;
}

export const sumbitBusinessRequest = (params: SubmitBusinessRequestData): Promise<void> => {
    return new API().makeRequest({
        method: 'post',
        url: 'disk-o-license',
        formUrlEncodedData: true,
        body: {
            email_from: params.email,
            licenses: params.licenses,
        },
    });
};

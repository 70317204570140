import React, { Fragment, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'src/store';
import { getUnbindError, getUnbindId, isSuccessUnbind } from 'src/modules/unbind/unbind.selectors';
import { Modal } from 'src/ui/Modal/Modal';
import { Text, TextSize } from 'src/ui/Text/Text';
import { defineMessages, FormattedMessage } from 'react-intl';
import styles from './Unbind.css';
import { Button, ButtonColors } from 'src/ui/Button/Button';
import { Colors, Link } from 'src/ui/Link/Link';
import { resetError, resetSuccess, unbind } from 'src/modules/unbind/unbind.actions';
import { getCurrentLanguage } from 'src/modules/language/language.selectors';
import { AvailableLanguages } from 'src/modules/language/language.types';

interface MapState {
    id: string;
    error: string;
    success: boolean;
}

interface MapDispatch {
    unbind: Function;
    resetError: Function;
    resetSuccess: Function;
}

const mapStateToProps = (state: AppState): MapState => ({
    id: getUnbindId(state),
    error: getUnbindError(state),
    success: isSuccessUnbind(state),
});

const mapDispatchToProps = {
    unbind,
    resetError,
    resetSuccess,
};

const messages = defineMessages({
    unbindTitle: 'Вы уверены, что хотите отменить подписку?',
    unbindText: 'При отмене подписки все расширенные функции Диск-О: станут недоступны, в том числе и доступ к подключенным дискам.',
    cancelUnbind: 'Остаться с Диск-О:',
    unbind: 'Отменить подписку',
    errorTitle: 'Ошибка',
    errorTextNoSerial: 'Серийный номер не найден',
    errorText: 'Попробуйте снова или свяжитесь со <a>службой поддержки</a>',
    errorButton: 'Ок',
    successTitle: 'Ваша подписка отменена',
    successText: 'Помогите нам стать лучше! Расскажите что вам не понравилось в Диск-О:',
    close: 'Закрыть',
    feedback: 'Рассказать',
});

interface State {
    showUnbind: boolean;
}

export class UnbindComponent extends PureComponent<MapState & MapDispatch, State> {
    public readonly state = {
        showUnbind: Boolean(this.props.id),
    };

    componentDidUpdate(prevProps: Readonly<MapState & MapDispatch>): void {
        if (!this.props.id && prevProps.id) {
            this.closeUnbind();
        }
    }

    closeUnbind = (): void => {
        this.setState({
            showUnbind: false,
        });
    };

    handleOnUnbindClick = (): void => {
        this.props.unbind(this.props.id);
    };

    closeErrorModal = (): void => {
        this.props.resetError();
    };

    closeSuccessModal = (): void => {
        this.props.resetSuccess();
    };

    renderModal = ({
        title,
        text,
        actions,
        onClose,
    }: {
        title: ReactElement;
        text: ReactElement;
        actions: ReactElement[];
        onClose: Function;
    }): ReactElement => {
        return (
            <Modal onClose={onClose} header={title}>
                <div className={styles.root}>
                    <div className={styles.text}>
                        <Text size={TextSize['17px']}>{text}</Text>
                    </div>
                    <div className={styles.footer}>{actions}</div>
                </div>
            </Modal>
        );
    };

    render(): ReactElement {
        const { error, success } = this.props;

        return (
            <Fragment>
                {this.state.showUnbind &&
                    this.renderModal({
                        title: <FormattedMessage {...messages.unbindTitle} />,
                        text: <FormattedMessage {...messages.unbindText} />,
                        onClose: this.closeUnbind,
                        actions: [
                            <div className={styles.button} key="cancelUnbind">
                                <Button color={ButtonColors.PRIMARY} onClick={this.closeUnbind}>
                                    <Text size={TextSize['20px']}>
                                        <FormattedMessage {...messages.cancelUnbind} />
                                    </Text>
                                </Button>
                            </div>,
                            <Link color={Colors.primary} onClick={this.handleOnUnbindClick} key="unbind">
                                <Text size={TextSize['20px']}>
                                    <FormattedMessage {...messages.unbind} />
                                </Text>
                            </Link>,
                        ],
                    })}
                {!!error &&
                    this.renderModal({
                        title: <FormattedMessage {...messages.errorTitle} />,
                        text: (
                            <FormattedMessage
                                {...(error.toUpperCase() === 'NOT_FOUND' ? messages.errorTextNoSerial : messages.errorText)}
                                values={{
                                    a: (msg: string): ReactElement => (
                                        <Link
                                            color={Colors.primary}
                                            target="_blank"
                                            href={
                                                getCurrentLanguage() === AvailableLanguages.ru
                                                    ? 'https://help.mail.ru/cloud_web/disko/form'
                                                    : '/en/faq/'
                                            }
                                        >
                                            {msg}
                                        </Link>
                                    ),
                                }}
                            />
                        ),
                        onClose: this.closeErrorModal,
                        actions: [
                            <Button color={ButtonColors.PRIMARY} onClick={this.closeErrorModal} key="errorButton">
                                <Text size={TextSize['20px']}>
                                    <FormattedMessage {...messages.errorButton} />
                                </Text>
                            </Button>,
                        ],
                    })}
                {success &&
                    this.renderModal({
                        title: <FormattedMessage {...messages.successTitle} />,
                        text: <FormattedMessage {...messages.successText} />,
                        onClose: this.closeSuccessModal,
                        actions: [
                            <div className={styles.button} key="feedback">
                                <Button
                                    color={ButtonColors.PRIMARY}
                                    target="_blank"
                                    href={
                                        getCurrentLanguage() === AvailableLanguages.ru
                                            ? 'https://help.mail.ru/cloud_web/disko/form'
                                            : '/en/faq/'
                                    }
                                >
                                    <Text size={TextSize['20px']}>
                                        <FormattedMessage {...messages.feedback} />
                                    </Text>
                                </Button>
                            </div>,
                            <Link color={Colors.primary} onClick={this.closeSuccessModal} key="close">
                                <Text size={TextSize['20px']}>
                                    <FormattedMessage {...messages.close} />
                                </Text>
                            </Link>,
                        ],
                    })}
            </Fragment>
        );
    }
}

export const Unbind = connect(mapStateToProps, mapDispatchToProps)(UnbindComponent);

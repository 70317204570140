/* eslint-disable max-lines */
import React, { Fragment, PureComponent, ReactElement, ReactNode } from 'react';
import { parse } from 'qs';
import classNames from 'clsx';

import styles from './Tariffs.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Container } from 'src/ui/Container/Container';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { Colors, Link } from 'src/ui/Link/Link';
import { loadTariffs as loadTariffsAction } from 'src/modules/tariffs/tariffs.actions';
import { connect } from 'react-redux';
import { Tariff } from 'src/ui/Tariff/Tariff';
import { Tariff as TariffInterface, TariffMods, Currency } from 'src/modules/tariffs/tariffs.types';
import { AppState } from 'src/store';
import { getCurrencyByLanguage, isRussianLanguage } from 'src/modules/language/language.selectors';
import { getTariffModFromFeatures, getTariffsByCurrency } from 'src/modules/tariffs/tariffs.selectors';
import { Buy } from 'src/ui/Buy/Buy';
import { QueryActions } from 'src/constants/queryActions';
import { sendDwh, sendGa } from 'src/utils/ga';
import { BuyBusiness } from 'src/ui/BuyBusiness/BuyBusiness';
import { TabMenu } from 'src/ui/TabMenu/TabMenu';
import { TariffCorp } from 'src/ui/Tariff/TariffCorp';
import { LicenseKeyDialog } from '../LicenseKeyDialog/LicenseKeyDialog';
import { getTariffBuyToken } from 'src/modules/buy/buy.selectors';
import { getLicense } from 'src/modules/api/getLicense';
import { pingApi } from 'src/utils/ping';
import { showDownloadPromo } from 'src/modules/downloadPromo/downloadPromo.actions';
import { isMacOS } from 'src/utils/ua-parser';
import { config } from 'src/constants/config';
import { secondsInADay } from 'src/utils/period';
import { isBundle } from 'src/utils/isBundle';
import { getClientId } from 'src/utils/jigurda';
import { getQueryIsWebview, isExactMatchPage } from 'src/utils/route.helpers';
import { isMobile as isMobileSelector, isPhone as isPhoneSelector } from 'src/modules/media/media.selectors';
import { Carousel } from 'src/ui/Carousel/Carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TExpAAB, UnifiedTariff } from 'src/ui/Tariffs/Tariffs.helper';
import { Observer } from 'src/ui/Tariffs/Observer';
import { getDownloadUrlGetter } from 'src/modules/links/links.selectors';

const messages = defineMessages({
    title: 'Тарифы',
    description:
        'Диск-О: можно подключить к Облаку Mail.ru бесплатно (только 1 диск), возможность подключения сторонних облачных хранилищ является частью подписки',
    trialDescription:
        'Для Облака Mail.ru (только 1 аккаунт) в Диск-О: нет ограничений по количеству загрузок, но в другие сервисы можно добавлять не более 10 файлов в сутки. Снятие этого лимита является частью подписки',
    trialDescriptionBackups:
        'Вы можете делать резервные копии только в Облако Mail.ru (1 аккаунт), снятие этого ограничения является частью подписки.',
    collapse: 'Свернуть сравнение',
    expand: 'Сравнить тарифы',
    individual: 'Для физических лиц',
    business: 'Для юридических лиц',
});

interface State {
    isCollapsed: boolean;
    buyId: string;
    alias?: string;
    actionAlias?: string;
    license: string;
    countDevices: number;
    showYearTrial: boolean;
    exp: TExpAAB;
}

const isWebview = getQueryIsWebview();

const gaCategoryGetLicense = 'get-license';

const mapDispatchToProps = {
    loadTariffs: loadTariffsAction,
    showDownloadPromo,
};

interface MapState {
    tariffs: TariffInterface[];
    currency: Currency;
    tariffMod?: TariffMods | null;
    token?: string;
    trialTariff?: TariffInterface | null;
    yearTrialTariff?: TariffInterface | null;
    isMobile: boolean;
    isPhone: boolean;
    isMainPage: boolean;
    isBackupPage: boolean;
    getDownloadUrl: ReturnType<typeof getDownloadUrlGetter>;
}

interface MapDispatch {
    loadTariffs: Function;
    showDownloadPromo: Function;
}

interface Props extends MapState, MapDispatch {
    description?: ReactNode;
}

const mapStateToProps = (state: AppState): MapState => {
    const isMainPage = isExactMatchPage('main');
    const isBackupPage = isExactMatchPage('backups');
    const currency = getCurrencyByLanguage();
    const tariffMod = getTariffModFromFeatures();
    const token = getTariffBuyToken(state);
    const tariffs = getTariffsByCurrency(state, currency, tariffMod);
    let trialTariff = null;
    let yearTrialTariff = null;

    if (config.features.trialTariff) {
        trialTariff = getTariffsByCurrency(state, currency, TariffMods.trials).shift();
        yearTrialTariff = getTariffsByCurrency(state, currency, TariffMods.trials1mYear).shift();
    }

    const getDownloadUrl = getDownloadUrlGetter(state);

    return {
        currency,
        tariffs,
        tariffMod,
        token,
        trialTariff,
        yearTrialTariff,
        isMobile: isMobileSelector(state),
        isPhone: isPhoneSelector(state),
        isMainPage,
        isBackupPage,
        getDownloadUrl,
    };
};

class TariffsComponent extends PureComponent<Props, State> {
    public readonly state = {
        isCollapsed: true,
        buyId: '',
        alias: '',
        actionAlias: '',
        license: '',
        countDevices: 0,
        showYearTrial: false,
        exp: null,
    };

    public static defaultProps = {
        description: <FormattedMessage {...messages.description} />,
    };

    componentDidMount(): void {
        const { loadTariffs, isMobile } = this.props;
        const { action, tariffId, alias } = parse(window.location.search, { ignoreQueryPrefix: true });

        if (isMobile) {
            sendGa({
                category: 'tariff_mobile',
                action: 'show',
            });
        }

        loadTariffs().then((): void => {
            if (action === QueryActions.requestPayment && !!tariffId) {
                sendGa({
                    category: 'request-payment',
                    action: 'openlink',
                });
                this.setState(
                    {
                        buyId: tariffId,
                    },
                    (): void =>
                        sendGa({
                            category: 'request-payment',
                            action: 'showmodal',
                        })
                );
            } else if (action === QueryActions.requestBind && !!alias) {
                sendGa({
                    category: 'request-bind',
                    action: 'openlink',
                });
                this.setState(
                    {
                        buyId: '',
                        alias,
                        actionAlias: action,
                    },
                    (): void =>
                        sendGa({
                            category: 'request-bind',
                            action: 'showmodal',
                        })
                );
            } else if (action === QueryActions.requestProlong && !!alias) {
                sendGa({
                    category: 'request-prolong',
                    action: 'openlink',
                });
                this.setState(
                    {
                        buyId: '',
                        alias,
                        actionAlias: action,
                    },
                    (): void =>
                        sendGa({
                            category: 'request-prolong',
                            action: 'showmodal',
                        })
                );
            }
        });
    }

    handleOnTrialClick = (): void => {
        window.open(this.props.getDownloadUrl(), isMacOS ? '_blank' : '_self');

        if (!isMacOS) {
            this.props.showDownloadPromo();
        }
    };

    handleOnBuyClick = async (id: string): Promise<void> => {
        const { isMobile } = this.props;
        const { exp } = this.state;

        this.setState({
            buyId: id,
        });

        sendGa({
            category: 'tariff_ab',
            action: 'click',
            label: id,
        });

        sendDwh({
            category: 'tariff',
            label: 'click',
            sendImmediately: true,
            dwhData: {
                group: exp,
                name_tariff: id,
            },
        });

        if (isMobile) {
            sendGa({
                category: 'tariff_mobile',
                action: 'click',
                label: id,
            });
        }

        if (id === UnifiedTariff.id) {
            const clientId = await getClientId();

            setTimeout(() => {
                window.location.href = `https://cloud.mail.ru/subscriptions?productId=${UnifiedTariff.id}&action=request-payment&from=disko&clientId=${clientId}&group=${exp}`;
            }, 500);
        }
    };

    handleAliasSuccess = () => {
        //TODO:
    };

    handleBuySuccess = (id: string): void => {
        const { token, isMobile } = this.props;
        const { exp } = this.state;

        sendGa({
            category: 'tariff_ab',
            action: 'success',
            label: id,
        });

        if (isMobile) {
            sendGa({
                category: 'tariff_mobile',
                action: 'success',
                label: id,
            });
        }

        sendDwh({
            category: 'tariff',
            label: 'buy',
            dwhData: {
                group: exp,
                name_tariff: id,
            },
        });

        // TODO:
        if (id && token) {
            pingApi(
                () => getLicense({ token, plan: id }),
                ({ serial }: { serial?: string } = {}): boolean => {
                    return !!serial;
                }
            )
                .then(({ serial }): void => {
                    if (serial) {
                        sendGa({
                            category: gaCategoryGetLicense,
                            action: 'ok',
                        });
                        this.setState({
                            license: serial,
                        });
                    } else {
                        sendGa({
                            category: gaCategoryGetLicense,
                            action: 'empty',
                        });
                    }
                })
                .catch((): void => {
                    sendGa({
                        category: gaCategoryGetLicense,
                        action: 'error',
                    });
                });
        }
    };

    handleOnBusinessClick = (count: number): void => {
        this.setState({
            countDevices: count,
        });
    };

    handleOnDiffClick = (): void => {
        this.setState((prevState) => ({
            isCollapsed: !prevState.isCollapsed,
        }));
    };

    handleCloseModal = (): void => {
        this.setState({
            buyId: '',
            alias: '',
        });
    };

    handleCloseLicense = (): void => {
        this.setState({
            license: '',
        });
    };

    handleCloseBuyBusinessModal = (): void => {
        this.setState({
            countDevices: 0,
        });
    };

    renderCarousel = (hideDots = false): ReactElement => {
        const settings = {
            dots: !hideDots,
            speed: 500,
            variableWidth: hideDots,
            infinite: false,
            arrows: false,
            slidesToScroll: 1,
            slidesToShow: hideDots ? 2 : 1,
            initialSlide: 1,
        };

        return (
            <div className={styles.carousel}>
                <Carousel maxWidth="320px" {...settings} tariffMod={this.props.tariffMod}>
                    {this.getTariffs()}
                </Carousel>
            </div>
        );
    };

    getTariffs = (): ReactElement[] => {
        const { currency, trialTariff, yearTrialTariff, tariffMod, isMobile, tariffs } = this.props;
        const { isCollapsed, showYearTrial } = this.state;

        const trial = showYearTrial && yearTrialTariff ? yearTrialTariff : trialTariff;

        let renderTariff = tariffs.map((tariff: TariffInterface, index) => (
            <div
                key={tariff.id}
                className={classNames({
                    [styles.tariff]: true,
                    [styles[`tariff_${tariff.tariffMod}`]]: !!tariff.tariffMod,
                })}
            >
                <Tariff
                    price={tariff.price}
                    oldPrice={tariff.oldPrice}
                    currency={tariff.currency}
                    id={tariff.id}
                    period={tariff.durationStr}
                    isPrimary={index === 0}
                    onBuy={this.handleOnBuyClick}
                    isCompact={isCollapsed}
                    isUnifiedYear={tariff.id === UnifiedTariff.id}
                    mod={tariff.tariffMod}
                    cloudGift={tariff.cloudGift}
                    isMobile={isMobile}
                />
            </div>
        ));

        if (tariffs.length > 0) {
            const id = trial?.id || 'trial';

            const trialTariffItem = (
                <div
                    key={id}
                    className={classNames({
                        [styles.tariff]: true,
                        [styles[`tariff_${tariffMod}`]]: !!tariffMod,
                    })}
                >
                    <Tariff
                        price={trial ? trial.price : 0}
                        currency={currency}
                        id={id}
                        period={`${trial ? trial?.trialPeriod / secondsInADay : 7}d`}
                        onBuy={trial ? this.handleOnBuyClick : this.handleOnTrialClick}
                        isTrial
                        isCompact={isCollapsed}
                        trialRenewablePeriod={trial?.durationStr}
                        mod={tariffMod}
                        isMobile={isMobile}
                    />
                </div>
            );

            renderTariff = [trialTariffItem, ...renderTariff];
        }

        return renderTariff;
    };

    onIntersecting = async (): Promise<void> => {
        const { exp } = this.state;

        sendDwh({
            category: 'tariff',
            label: 'view',
            dwhData: {
                group: exp,
            },
        });
    };

    renderBuyModal = ({ buyId, alias, actionAlias }: { buyId?: string; alias?: string; actionAlias?: string }) => {
        if (buyId) {
            return <Buy id={buyId} onClose={this.handleCloseModal} onBuySuccess={this.handleBuySuccess} />;
        } else if (alias) {
            return <Buy alias={alias} action={actionAlias} onClose={this.handleCloseModal} onBuySuccess={this.handleAliasSuccess} />;
        }

        return null;
    };

    render(): ReactElement {
        const { currency, tariffs, tariffMod, isMobile, isPhone, isMainPage, isBackupPage } = this.props;
        const { buyId, alias, actionAlias, isCollapsed, countDevices, license } = this.state;
        const isRu = isRussianLanguage();

        let trialDescription: JSX.Element | string = isBackupPage ? (
            <FormattedMessage {...messages.trialDescriptionBackups} />
        ) : (
            <FormattedMessage {...messages.trialDescription} />
        );

        if (isRu) {
            if (isBackupPage) {
                trialDescription =
                    'Вы\u00A0можете делать резервные копии только в\u00A0Облако Mail.ru (1\u00A0аккаунт). Для снятия этого лимита подключите к\u00A0Диск-О: Облако Mail.ru или оформите подписку.';
            } else {
                trialDescription =
                    'Для Облака Mail.ru (только 1\u00A0аккаунт) в\u00A0Диск-О: нет ограничений по\u00A0количеству загрузок, но\u00A0в\u00A0другие сервисы можно добавлять не\u00A0более 10\u00A0файлов в\u00A0сутки. Снятие этого лимита является частью подписки.';
            }
        }

        const IndividualTariffs = (
            <Fragment>
                <div
                    className={classNames({
                        [styles.tariffs]: true,
                        [styles[`tariffs_${tariffMod}`]]: !!tariffMod,
                        [styles.tariffs_bundle]: isBundle(tariffMod),
                        [styles.tariffs_webview]: isWebview,
                        [styles.tariffs_action]: Boolean(tariffMod),
                    })}
                >
                    {this.getTariffs()}
                </div>
                <div className={styles.diff}>
                    <Link color={Colors.primary} onClick={this.handleOnDiffClick}>
                        <Text size={TextSize['20px']}>
                            <FormattedMessage {...(isCollapsed ? messages.expand : messages.collapse)} />
                        </Text>
                    </Link>
                </div>
            </Fragment>
        );

        const CaruselTariffs = <div>{this.renderCarousel(!isPhone)}</div>;

        return (
            <Observer onIntersecting={this.onIntersecting}>
                <div id="tariffs" className={styles.root}>
                    <div id="payment" />
                    {!license && this.renderBuyModal({ buyId, alias, actionAlias })}
                    {!!license && <LicenseKeyDialog onClose={this.handleCloseLicense} license={license} />}
                    {!!countDevices && <BuyBusiness onClose={this.handleCloseBuyBusinessModal} countDevices={countDevices} />}
                    <Container>
                        {!isWebview && (
                            <div className={styles.texts}>
                                <div className={styles.title}>
                                    <Text size={isMobile ? TextSize['28px'] : TextSize['44px']} weight={Weight.w700}>
                                        <FormattedMessage {...messages.title} />
                                    </Text>
                                </div>
                                <div className={styles.description}>
                                    <Text size={TextSize['20px']} weight={Weight.w500}>
                                        {trialDescription}
                                    </Text>
                                </div>
                            </div>
                        )}
                        <div
                            className={classNames({
                                [styles.tariffsTabMenuWrapper]: !isWebview,
                                [styles.tariffsTabMenuWrapperWebview]: isWebview,
                                [styles.tariffsTabMenuWrapperPage]: isMainPage,
                            })}
                        >
                            <TabMenu
                                isVertical={isPhone}
                                items={[
                                    {
                                        id: 'individual',
                                        title: <FormattedMessage {...messages.individual} />,
                                        component: isMobile ? CaruselTariffs : IndividualTariffs,
                                    },
                                    {
                                        id: 'corp',
                                        title: <FormattedMessage {...messages.business} />,
                                        component: (
                                            <div className={styles.corpTariff}>
                                                <TariffCorp
                                                    price={tariffs.length > 0 ? tariffs[0].oldPrice || tariffs[0].price : 0}
                                                    currency={currency}
                                                    onBuy={this.handleOnBusinessClick}
                                                />
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </Container>
                </div>
            </Observer>
        );
    }
}

export const Tariffs = connect(mapStateToProps, mapDispatchToProps)(TariffsComponent);

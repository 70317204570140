import { jigurda } from 'src/utils/jigurda';
import { Currency, Tariff } from 'src/modules/tariffs/tariffs.types';
import { isRussianLanguage } from 'src/modules/language/language.selectors';

const jigurdaPercentQuarter = 25;
const jigurdaPercentHalf = 50;
const jigurdaUnifiedTariff = 'unified-ab';

export type TExpAAB = 'a1' | 'a2' | 'b' | null;

export const UnifiedTariff = {
    cloudGift: undefined,
    currency: 'RUB' as Currency,
    duration: 2678400,
    durationStr: '1m',
    enabled: true,
    id: 'W128G1m_DiskoA',
    oldPrice: undefined,
    price: 199,
    trialPeriod: 0,
};

const getJigurdaAAB = async (): Promise<TExpAAB> => {
    const isEnabledQuarter = await jigurda(jigurdaUnifiedTariff, jigurdaPercentQuarter);
    const isEnabledHalf = await jigurda(jigurdaUnifiedTariff, jigurdaPercentHalf);

    if (!isRussianLanguage()) {
        return null;
    }

    if (isEnabledQuarter) {
        return 'a1';
    }

    if (isEnabledHalf) {
        return 'a2';
    }

    return 'b';
};

export const getAbTariffs = async (tariffs: Tariff[]): Promise<{ tariffsAB: Tariff[]; exp: TExpAAB }> => {
    const exp = await getJigurdaAAB();
    let tariffsAB = tariffs;

    if (exp === 'b') {
        tariffsAB = tariffs.filter((tariff) => tariff.id !== 'disko3m_v2');

        tariffsAB.push(UnifiedTariff);
    }

    return { tariffsAB, exp };
};

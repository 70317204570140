export enum AvailableLanguages {
    en = 'en',
    es = 'es',
    ru = 'ru',
    de = 'de',
    tr = 'tr',
    hi = 'hi',
    zh = 'zh',
    fr = 'fr',
}

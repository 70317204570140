import React, { ReactElement, ReactNode } from 'react';

import styles from './Tabs.css';
import { Text, TextSize } from 'src/ui/Text/Text';

export const TabText = ({ children }: { children: ReactNode }): ReactElement => (
    <div className={styles.textBlock}>
        <Text size={TextSize['20px']}>{children}</Text>
    </div>
);

import React, { FunctionComponent } from 'react';
import styles from './Tariff.css';
import { TariffMods } from 'src/modules/tariffs/tariffs.types';
import classNames from 'clsx';
import { ReactComponent as MarkIcon } from 'img/icons/mark.svg';

interface Props {
    mod?: TariffMods | null;
}

export const TariffFeature: FunctionComponent<Props> = ({ mod, children }) => (
    <div
        className={classNames({
            [styles.feature]: true,
            [styles[`feature_${mod}`]]: !!mod,
        })}
    >
        <div
            className={classNames({
                [styles.mark]: true,
                [styles[`mark_${mod}`]]: !!mod,
            })}
        >
            <MarkIcon />
        </div>
        {children}
    </div>
);

export const setHash = (hash: string): void => {
    if (!hash) {
        history.replaceState({}, document.title, '.' + window.location.search);
        return;
    }
    window.location.hash = hash;
};

export const getHash = (): string => window.location.hash && window.location.hash.replace('#', '');

export const setQueryString = (query: string): void => {
    window.history.pushState(null, '', window.location.pathname + (query ? `?+${query}` : ''));
};

export const getQueryString = (): string => window.location.search || '';

export const goToPath = (path: string): void => {
    window.location.assign(path);
};

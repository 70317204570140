import {
    UnbindActionTypes,
    UnbindFailureAction,
    UnbindRequestAction,
    UnbindResetError,
    UnbindResetSuccess,
    UnbindShowModalAction,
    UnbindSuccessAction,
} from 'src/modules/unbind/unbind.types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'src/store';
import { Action } from 'redux';
import { unlinkPerform } from 'src/modules/api/unlinkPerform';

const request = (): UnbindRequestAction => ({
    type: UnbindActionTypes.REQUEST,
});

const failure = (error: string): UnbindFailureAction => ({
    type: UnbindActionTypes.FAILURE,
    error,
});

const success = (): UnbindSuccessAction => ({
    type: UnbindActionTypes.SUCCESS,
});

export const resetError = (): UnbindResetError => ({
    type: UnbindActionTypes.RESET_ERROR,
});

export const resetSuccess = (): UnbindResetSuccess => ({
    type: UnbindActionTypes.RESET_SUCCESS,
});

export const showUnbindModal = (id: string): UnbindShowModalAction => ({
    type: UnbindActionTypes.SHOW_MODAL,
    id,
});

export const unbind = (id: string): ThunkAction<void, AppState, null, Action<string>> => (dispatch) => {
    dispatch(request());

    unlinkPerform(id)
        .then(() => {
            dispatch(success());
            window.history.pushState({}, 'Home', '/');
        })
        .catch((error) => dispatch(failure(error.error || 'system')));
};

import { defineMessages } from 'react-intl';
import { AvailableLanguages } from 'src/modules/language/language.types';

export const messages = defineMessages({
    backupsToClouds: 'Резервные копии в любые облака',
    unlimUpload: 'Снятие ограничений на загрузку файлов',
    uploadFiles: 'Загрузка файлов размером до 32 ГБ',
    fileHistory: '60 дней истории изменений файлов',
    devices: `{devicesCount, plural,
        one {# устройство}
        few {# устройства}
        other {# устройств}
    }`,
    support: 'Круглосуточная поддержка',
    ssl: 'Защита данных по SSL',
    titleYear: `Полная версия на{nbsp}{period, plural,
        one {#{nbsp}год}
        few {#{nbsp}года}
        other {#{nbsp}лет}
    }`,
    titleMonth: `Полная версия на{nbsp}{period, plural,
        one {#{nbsp}месяц}
        few {#{nbsp}месяца}
        other {#{nbsp}месяцев}
    }`,
    titleUnifiedYear: `Диск-О: + 128 ГБ <nowrap>в Облаке</nowrap>`,
    cloudTitle: 'Полная версия +{nbsp}Облако на {space} ГБ',
    trialTitleDay: `Пробная версия на{nbsp}{period, plural,
        one {#{nbsp}день}
        few {#{nbsp}дня}
        other {#{nbsp}дней}
    }`,
    trialTitleMonth: `Пробная версия на{nbsp}{period, plural,
        one {#{nbsp}месяц}
        few {#{nbsp}месяца}
        other {#{nbsp}месяцев}
    }`,
    label: 'Выгодно',
    trialPeriodDay: `{period, plural,
        one {# день}
        few {# дня}
        other {# дней}
    } пробный период`,
    trialPeriodMonth: `{period, plural,
        one {# месяц}
        few {# месяца}
        other {# месяцев} 
    } пробный период`,
    paymentPeriodYear: `Оплата {count, plural, 
        one {# раз}
        few {# раза}
        other {# раз}
    } в {period, plural,
        =1 {год}
        one {# год}
        few {# года}
        other {# лет}
    }`,
    paymentPeriodMonth: `Оплата {count, plural, 
        one {# раз}
        few {# раза}
        other {# раз}
    } в {period, plural,
        =1 {месяц}
        one {# месяц}
        few {# месяца}
        other {# месяцев}
    }`,
    trialPrice: '{price} {currency}',
    price: '{price} {currency} <span>/ месяц</span>',
    priceDescriptionYear: `С вашего счёта будет списано <nowrap>{price} рублей</nowrap> за <nowrap>{period, plural,
        one {# год}
        few {# года}
        other {# лет}
    }</nowrap> использования <nowrap>Диск-О:</nowrap>`,
    priceDescriptionMonth: `С вашего счёта будет списано <nowrap>{price} рублей</nowrap> за <nowrap>{period, plural,
        one {# месяц}
        few {# месяца}
        other {# месяцев}
    }</nowrap> использования <nowrap>Диск-О:</nowrap>`,
    priceDescriptionUnified: `С вашего счета будет списано 199 рублей за 1 месяц использования Облака и Диск-О:`,
    trialDescription: `По окончании пробного периода с вашего счёта будет списано <nowrap>{price} рублей</nowrap> за <nowrap>{period, plural,
        one {# месяц}
        few {# месяца}
        other {# месяцев}
    }</nowrap> использования <nowrap>Диск-О:</nowrap>`,
    trialDescriptionYear: `По окончании пробного периода с вашего счёта будет списано <nowrap>{price} рублей</nowrap> за <nowrap>{period, plural,
        one {# год}
        few {# года}
        other {# лет}
    }</nowrap> использования <nowrap>Диск-О:</nowrap>`,
    priceDescriptionCloud: `С вашего счёта будет списано <nowrap>{price} рублей</nowrap> за <nowrap>{period, plural,
        one {# год}
        few {# года}
        other {# лет}
    }</nowrap> использования <nowrap>Диск-О:</nowrap> и Облака`,
    buttonText: 'Купить',
    buttonTextTrial: 'Попробовать',
    cloudTariffTitle: '{space} ГБ',
    personalDiscount: 'Ваша персональная скидка',
    actionText: 'Подписка{br}за полцены!',
});

export const MESSAGES_BY_LANG = {
    [AvailableLanguages.ru]: {
        actionText: 'Летнее предложение',
        labelDiscount: 'Скидка 50%',
    },
    [AvailableLanguages.en]: {
        actionText: 'Summer promo',
        labelDiscount: 'Discount 50%',
    },
};

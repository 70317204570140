import React, { createRef, FormEvent, PureComponent, ReactElement } from 'react';
import styles from './BuyBusiness.css';
import { Modal } from 'src/ui/Modal/Modal';
import { Text, TextSize, Weight } from 'src/ui/Text/Text';
import { defineMessages, FormattedMessage } from 'react-intl';
import { sendGa } from 'src/utils/ga';
import classNames from 'clsx';
import { Button, ButtonColors } from 'src/ui/Button/Button';
import { sumbitBusinessRequest } from 'src/modules/api/sumbitBusinessRequest';
import { validateEmail } from 'src/utils/email.helpers';
import { i18n } from 'src/utils/i18n';

const messages = defineMessages({
    title: `Покупка корпоративной подписки`,
    emailText: 'Пожалуйста, оставьте ваш email-адрес и мы оперативно свяжемся с вами, чтобы обсудить все детали.',
    send: 'Отправить',

    errorWrongEmail: 'Неверный адрес',
    error: 'Произошла ошибка',
    errorRequired: 'Укажите адрес',
});

const ERRORS = {
    error: i18n.formatMessage(messages.error),
    required: i18n.formatMessage(messages.errorRequired),
    wrongEmail: i18n.formatMessage(messages.errorWrongEmail),
};

interface Props {
    countDevices: number;
    onClose: Function;
}

interface State {
    error: string;
}

const businessRequest = 'business-request';

export class BuyBusiness extends PureComponent<Props, State> {
    public static defaultProps = {
        countDevices: 10,
    };

    public readonly state = {
        error: '',
    };

    inputRef = createRef<HTMLInputElement>();

    componentDidMount(): void {
        sendGa({
            category: businessRequest,
            action: 'show',
            label: 'ok',
        });
    }

    private handleOnBuyClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent> | FormEvent): void => {
        e.preventDefault();
        const { countDevices } = this.props;

        sendGa({
            category: businessRequest,
            action: 'click',
            label: 'ok',
        });

        if (!this.inputRef.current) {
            return;
        }
        const email = this.inputRef.current.value.trim();

        if (!email) {
            this.setState({
                error: ERRORS.required,
            });
            return;
        }
        if (!validateEmail(email)) {
            this.setState({
                error: ERRORS.wrongEmail,
            });
            return;
        }
        sumbitBusinessRequest({
            email,
            licenses: countDevices,
        })
            .then(() => {
                sendGa({
                    category: businessRequest,
                    action: 'send',
                    label: 'ok',
                });
                this.props.onClose();
            })
            .catch(() => {
                this.setState({ error: ERRORS.error });
                sendGa({
                    category: businessRequest,
                    action: 'send',
                    label: 'error',
                });
            });
    };

    private handleOnChange = (): void => {
        this.setState({
            error: '',
        });
    };

    public render(): ReactElement | null {
        const { error = '' } = this.state;

        return (
            <Modal onClose={this.props.onClose} mod="tiny" header={<FormattedMessage {...messages.title} />}>
                <form className={styles.root} onSubmit={this.handleOnBuyClick}>
                    <label htmlFor="email-order">
                        <Text size={TextSize['15px']} weight={Weight.w700}>
                            <FormattedMessage {...messages.emailText} />
                        </Text>
                    </label>
                    <div className={styles.inputWrap}>
                        <input
                            className={classNames({
                                [styles.input]: true,
                                [styles.input_error]: error,
                            })}
                            type="email"
                            id="email-order"
                            name="email-order"
                            ref={this.inputRef}
                            onChange={this.handleOnChange}
                        />
                        {error && (
                            <div className={styles.error}>
                                <Text size={TextSize['15px']} weight={Weight.w500}>
                                    {error}
                                </Text>
                            </div>
                        )}
                    </div>
                    <div className={styles.footer}>
                        <Button color={ButtonColors.PRIMARY} onClick={this.handleOnBuyClick}>
                            <Text size={TextSize['20px']}>
                                <FormattedMessage {...messages.send} />
                            </Text>
                        </Button>
                    </div>
                </form>
            </Modal>
        );
    }
}

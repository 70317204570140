import React, { useMemo } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { FormattedMessage } from 'src/utils/i18n';
import { LineHeight, Text, TextAlign, TextSize, Weight } from 'src/ui/Text/Text';
import styles from './BackupsHowToRecover.css';
import { Download } from '../Download/Download';

const messages = defineMessages({
    step1:
        'Нажмите «У меня уже есть резервные копии» при первом запуске Диск-О: или выберите пункт «Восстановить данные из копии», если уже пользуетесь программой.',
    step2: 'Укажите облачный сервис, в котором хранится резервная копия, и авторизуйтесь в нем.',
    step3: 'Отметьте те папки, которые надо восстановить, и нажмите «Настроить», чтобы выбрать, куда их сохранить на компьютере.',
});

export const BackupsHowToRecover = ({ title }: { title?: MessageDescriptor }) => {
    const steps = useMemo(
        () => [
            {
                text: <FormattedMessage {...messages.step1} />,
                step: 1,
            },
            {
                text: <FormattedMessage {...messages.step2} />,
                step: 2,
            },
            {
                text: <FormattedMessage {...messages.step3} />,
                step: 3,
            },
        ],
        []
    );

    return (
        <div className={styles.container}>
            {title && (
                <Text desktopSize={TextSize['44px']} size={TextSize['32px']} weight={Weight.w700} textAlign={TextAlign.center}>
                    <FormattedMessage {...title} />
                </Text>
            )}
            <div className={styles.content}>
                {steps.map((step) => (
                    <div key={step.step} className={styles.card}>
                        <div className={styles.marker}>
                            <Text desktopSize={TextSize['24px']} size={TextSize['24px']} weight={Weight.w700}>
                                {step.step}
                            </Text>
                        </div>

                        <div className={styles.text}>
                            <Text
                                size={TextSize['17px']}
                                tabletSize={TextSize['20px']}
                                weight={Weight.w500}
                                lineHeight={LineHeight['30px']}
                            >
                                {step.text}
                            </Text>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.download}>
                <Download />
            </div>
        </div>
    );
};

import { RE_LANGS } from 'src/router';
import { AvailableLanguages } from 'src/modules/language/language.types';
import { matchPath } from 'react-router-dom';
import { Currency } from 'src/modules/tariffs/tariffs.types';

export const getCurrentLanguage = (): AvailableLanguages => {
    const routeMatch = matchPath(window.location.pathname, {
        path: `/:lang${RE_LANGS}`,
    }) as { params: { lang?: AvailableLanguages } };

    const routerLang: AvailableLanguages = routeMatch?.params?.lang || AvailableLanguages.ru;

    if (routerLang) {
        return AvailableLanguages[routerLang];
    }
    return AvailableLanguages.ru;
};

export const isRussianLanguage = (language: AvailableLanguages = getCurrentLanguage()): boolean => {
    return language === AvailableLanguages.ru;
};

const currenciesByCountry = {
    [AvailableLanguages.ru]: Currency.RUB,
    [AvailableLanguages.en]: Currency.USD,
    [AvailableLanguages.es]: Currency.EUR,
    [AvailableLanguages.de]: Currency.EUR,
    [AvailableLanguages.tr]: Currency.USD,
    [AvailableLanguages.hi]: Currency.USD,
    [AvailableLanguages.zh]: Currency.USD,
    [AvailableLanguages.fr]: Currency.EUR,
};

export const getCurrencyByLanguage = (language: AvailableLanguages = getCurrentLanguage()): Currency =>
    currenciesByCountry[language] || Currency.RUB;
